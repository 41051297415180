import OwlCarousel from "react-owl-carousel";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CustomButton from "../Common/CustomButton/CustomButton";

const testimoSlider = {
	autoplay: false,
	loop: true,
	dots: false,
	nav: true,
	responsiveClass: true,
	responsive: {
		0: {
			items: 1.2,
			margin: 16,
		},
		768: {
			items: 1,
			margin: 24,
		},
	},
};

export const FromThePoeple = (props: { data: any[] }) => {

	const navigate = useNavigate();

	return (
		<div className="testimonial-wrap">
			<div className="custom-container">
				<div className="testmo-sections">
					<h6 className="caps-on hide-767">In The Home</h6>
					<OwlCarousel className="owl-theme" {...testimoSlider}>
						{
							props.data.map((data, index) => (
								<div className="testimo-slides" key={data.elementId}>
									<div className="testmo-left">
										<h6 className="caps-on show-768">In The Home</h6>
										<p dangerouslySetInnerHTML={{ __html: data.description }}></p>
										<p dangerouslySetInnerHTML={{ __html: data.details.customerName }}></p>
									</div>
									<div className="testmo-right">
										<Link to={"/" + data.urlKey}>
											<img src={data.image} alt={data.title} title={data.title}></img>
											<p className="ts caps-on">{data.title}</p>
										</Link>
									</div>
								</div>
							))
						}
					</OwlCarousel>
					<div className="action-bar">
						<CustomButton onClick={() => navigate("/" + props.data[0].details.component_url)}>{"See More"}</CustomButton>
					</div>
				</div>
			</div>
		</div>
	)
}