import { ERROR, SUCCESS } from "../../../Constants/Labels";
import { CART_ID } from "../../../Constants/LocalStorage";
import {
  addCoupan,
  addProductToWishlist,
  addProductToWishlistItem,
  addSimpleProductToCartItem,
  addToCart,
  createEmptCart,
  deleteCart,
  getCartDetail,
  getWishlistItems,
  mergerCart,
  removeCoupan,
  removeFromWishlist,
  updateCart,
} from "../../../Services/graphQL.service";
import {
  getSessionItem,
  removeSessionItem,
  setSessionItem,
  showToast,
} from "../../../Utility/Utilities";
import {
  ADD_COUPAN_FAILED,
  ADD_PRODUCT_TO_CART_FAILED,
  ADD_PRODUCT_TO_CART_SUCCESS,
  ADD_TO_WISHLIST_ERROR,
  ADD_TO_WISHLIST_SUCCESS,
  CREATE_EMPTY_CART_ERROR,
  CREATE_EMPTY_CART_SUCCESS,
  DELETE_CART_ERROR,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  DELETE_WISHLIST_ERROR,
  GET_CART_PAGE_ERROR,
  GET_CART_PAGE_REQUEST,
  GET_CART_PAGE_SUCCESS,
  GET_WISHLIST_ERROR,
  GET_WISHLIST_SUCCESS,
  REMOVE_COUPAN_FAILED,
  UPDATE_CART_ERROR,
  UPDATE_CART_REQUEST,
} from "./CartPageTypes";

export const getCartAction = () => {
  return async (dispatch: any) => {
    dispatch({ type: GET_CART_PAGE_REQUEST });
    try {
      const cartIdStorage = getSessionItem(CART_ID);
      if (cartIdStorage) {
        const { data: response } = await getCartDetail(cartIdStorage);
        const { data: cartDetail } = response;
        if (cartDetail && cartDetail.cart) {
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: cartDetail.cart,
            },
          });
        } else {
          if (response.errors[0].message === `The cart isn't active.`) {
            removeSessionItem(CART_ID)
            dispatch(createEmptCart())
          } else {
            if (response.errors[0].message.indexOf("Could not find a cart with ID") === -1) {
              showToast(ERROR, response.errors[0].message);
              dispatch({
                type: GET_CART_PAGE_ERROR,
                payload: {
                  type: GET_CART_PAGE_ERROR,
                  message: response.errors[0].message,
                },
              });
            } else {
              removeSessionItem(CART_ID)
              dispatch(createEmptCart())
            }
          }
        }
      }
    } catch (error) {
      dispatch({
        type: GET_CART_PAGE_ERROR,
        message: error,
      });
    }
  };
};

export const updateCartItem = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({ type: UPDATE_CART_REQUEST });
    try {
      payload.cartId = getSessionItem(CART_ID);
      if (payload.cartId) {
        const { data: response } = await updateCart(payload);
        const { data: cartDetail } = response;
        if (
          cartDetail &&
          cartDetail.updateCartItems &&
          cartDetail.updateCartItems.cart
        ) {
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: cartDetail.updateCartItems.cart,
            },
          });
        } else {
          showToast(ERROR, response.errors[0].message);
          dispatch({
            type: GET_CART_PAGE_ERROR,
            payload: {
              type: GET_CART_PAGE_ERROR,
              message: response.errors[0].message,
              data: payload,
            },
          });
        }
      } else {
        showToast(ERROR, "Something went wrong");
        dispatch({
          type: GET_CART_PAGE_ERROR,
          payload: {
            type: GET_CART_PAGE_ERROR,
            data: payload,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_CART_ERROR,
        payload: { type: UPDATE_CART_REQUEST, message: error },
      });
    }
  };
};

export const createEmptyCartAction = () => {
  return async (dispatch: any) => {
    try {
      const cartId = getSessionItem(CART_ID);
      if (!cartId) {
        const { data: response } = await createEmptCart();
        const { customerCart: cartResponse } = response.data;
        if (cartResponse) {
          dispatch({
            type: CREATE_EMPTY_CART_SUCCESS,
            payload: { type: CREATE_EMPTY_CART_SUCCESS },
          });
          const token = getSessionItem(CART_ID);
          if (token) {
            try {
              await mergerCart({
                sourceCartId: token,
                destinationCartId: cartResponse.id,
              });
            } catch (error) {
              showToast(ERROR, "Unable to merge cart");
            }
          }
          if (cartResponse && cartResponse.id) {
            setSessionItem(CART_ID, cartResponse.id);
          }
        } else if (response.data.createEmptyCart) {
          dispatch({
            type: CREATE_EMPTY_CART_SUCCESS,
            payload: { type: CREATE_EMPTY_CART_SUCCESS },
          });
          if (response && response.data.createEmptyCart) {
            setSessionItem(CART_ID, response.data.createEmptyCart);
          }
        } else {
          showToast(ERROR, response.errors[0].message);
        }
      } else {
        dispatch(getCartAction());
      }
    } catch (error) {
      dispatch({
        type: CREATE_EMPTY_CART_ERROR,
        payload: { type: CREATE_EMPTY_CART_ERROR, message: error },
      });
      showToast(ERROR, error);
    }
  };
};

export const deleteCartItem = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({ type: DELETE_CART_REQUEST });
    try {
      payload.cartId = getSessionItem(CART_ID);
      const { data: response } = await deleteCart(payload);
      if (response.errors) {
        dispatch({
          type: DELETE_CART_ERROR,
          payload: response.errors[0].message,
        });
        showToast(ERROR, response.errors[0].message);
      } else {
        dispatch({
          type: DELETE_CART_SUCCESS,
          payload: { type: DELETE_CART_SUCCESS, data: payload.cartItemId },
        });
      }
    } catch (error) {
      dispatch({
        type: DELETE_CART_ERROR,
        payload: error,
      });
      showToast(ERROR, error);
    }
  };
};

export const getWishlist = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getWishlistItems(payload);
      if (response) {
        if (response.errors) {
          dispatch({
            type: GET_WISHLIST_ERROR,
            payload: {
              type: GET_WISHLIST_ERROR,
              message: response.errors[0].message,
            },
          });
          showToast(ERROR, response.errors[0].message);
        } else {
          dispatch({
            type: GET_WISHLIST_SUCCESS,
            payload: {
              type: GET_WISHLIST_SUCCESS,
              data: response.data.customer?.wishlists[0],
            },
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_WISHLIST_ERROR,
        payload: error,
      });
      showToast(ERROR, error);
    }
  };
};

export const removeFromWishlistItems = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await removeFromWishlist(payload);
      if (response.errors) {
        dispatch({
          type: DELETE_WISHLIST_ERROR,
          payload: {
            type: DELETE_WISHLIST_ERROR,
            message: response.errors[0].message,
          },
        });
        showToast(ERROR, response.errors[0].message);
      } else {
        dispatch({
          type: GET_WISHLIST_SUCCESS,
          payload: {
            type: GET_WISHLIST_SUCCESS,
            data: {
              items_v2: {
                items: response.data.removeProductsFromWishlist.wishlist.items,
              },
              items_count:
                response.data.removeProductsFromWishlist.wishlist.items_count,
            },
          },
        });
        showToast(SUCCESS, "Wishlist removed successfully");
      }
    } catch (error) {
      dispatch({
        type: DELETE_WISHLIST_ERROR,
        payload: {
          type: DELETE_WISHLIST_ERROR,
          message: error,
        },
      });
      showToast(ERROR, error);
    }
  };
};

export const MoveProductToWishlist = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      const response = await addProductToWishlist(payload);
      if (response && response.length > 0) {
        const { data: firstResponse } = response[0];
        const { data: secondResponse } = response[1];
        const { data: fR } = firstResponse;
        const { data: sR } = secondResponse;
        if (
          (fR &&
            fR.addProductsToWishlist &&
            (fR.addProductsToWishlist.errors ||
              fR.addProductsToWishlist.user_errors.length > 0)) ||
          (sR &&
            sR.removeCoupanFromCart &&
            (sR.removeItemFromCart.errors ||
              sR.removeItemFromCart.user_errors.length > 0))
        ) {
          let message = "";
          if (sR.removeItemFromCart.errors) {
            message = fR.addProductsToWishlist.errors
              ? fR.addProductsToWishlist.errors[0].message
              : sR.removeItemFromCart.errors
                ? sR.removeItemFromCart.errors[0].message
                : "Something went wrong";
            // REMOVE FROM WISHLIST
            const wishlistId =
              fR.addProductsToWishlist.addProductToWishlist.wishlist.id;
            const wishlistItemId =
              fR.addProductsToWishlist.addProductToWishlist.wishlist.items_v2
                .items[0].id;
            await removeFromWishlist({ wishlistId, wishlistItemId });
          } else if (sR.removeItemFromCart.user_errors.length > 0) {
            message = fR.addProductsToWishlist.errors
              ? firstResponse.user_errors[0].message
              : sR.removeItemFromCart.user_errors
                ? sR.removeItemFromCart.user_errors[0].message
                : "Something went wrong";
            const wishlistId =
              fR.addProductsToWishlist.addProductToWishlist.wishlist.id;
            const wishlistItemId =
              fR.addProductsToWishlist.addProductToWishlist.wishlist.items_v2
                .items[0].id;
            await removeFromWishlist({ wishlistId, wishlistItemId });
          }
          showToast(ERROR, message);
          dispatch({
            type: ADD_TO_WISHLIST_ERROR,
            payload: { type: ADD_TO_WISHLIST_ERROR, message: message },
          });
        } else {
          showToast(SUCCESS, "Moved to wishlist successfully");
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: sR.removeItemFromCart.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: ADD_TO_WISHLIST_ERROR,
        payload: { type: ADD_TO_WISHLIST_ERROR, message: error },
      });
    }
  };
};

export const removeCoupanFromCart = () => {
  return async (dispatch: any) => {
    try {
      const cartId = getSessionItem(CART_ID);
      const { data: response } = await removeCoupan(cartId);
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: REMOVE_COUPAN_FAILED,
          payload: {
            type: REMOVE_COUPAN_FAILED,
            message: response.errors[0].message,
          },
        });
      } else {
        showToast(SUCCESS, "Removed coupan from cart successfully");
        if (response.data) {
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.removeCouponFromCart?.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: REMOVE_COUPAN_FAILED,
        payload: { type: REMOVE_COUPAN_FAILED, message: error },
      });
    }
  };
};

export const applyCoupanToCart = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      const { data: response } = await addCoupan(payload);
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: ADD_COUPAN_FAILED,
          payload: {
            type: ADD_COUPAN_FAILED,
            message: response.errors[0].message,
          },
        });
      } else {
        showToast(SUCCESS, "The coupan applied to cart successfully");
        if (response.data) {
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.applyCouponToCart?.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: ADD_COUPAN_FAILED,
        payload: { type: ADD_COUPAN_FAILED, message: error },
      });
    }
  };
};

export const addToWishlist = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await addProductToWishlistItem(payload);
      if (response.errors || response.user_errors) {
        const error =
          response.errors[0].message || response.user_errors[0].message;
        showToast(ERROR, error);
      } else {
        showToast(SUCCESS, "The product added to wishlist successfully");
        dispatch({
          type: ADD_TO_WISHLIST_SUCCESS,
          payload: {
            type: ADD_TO_WISHLIST_SUCCESS,
            data: response.data?.addProductsToWishlist?.wishlist,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

export const addProductToCartItem = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      if (!payload.cartId) {
        const { data: cartResponseCart } = await createEmptCart()
        const { customerCart: cartResponse } = cartResponseCart.data;
        payload.cartId = cartResponse
      }
      let data = null;
      let response = null;
      if (payload.parent_sku && payload.child_sku) {
        const { data: res } = await addToCart(payload);
        response = res;
        if (res && !res.errors) {
          data = response?.data?.addConfigurableProductsToCart?.cart
        } else if (res.errors) {
          dispatch({
            type: ADD_PRODUCT_TO_CART_FAILED,
            payload: {
              type: ADD_PRODUCT_TO_CART_FAILED
            }
          })
        }
      }
      if (payload.simple_sku) {
        const { data: res } = await addSimpleProductToCartItem({ ...payload, sku: payload.simple_sku, })
        response = res;
        if (res && !res.errors) {
          data = response?.data?.addSimpleProductsToCart?.cart
        } else if (res.errors) {
          dispatch({
            type: ADD_PRODUCT_TO_CART_FAILED,
            payload: {
              type: ADD_PRODUCT_TO_CART_FAILED
            }
          })
        }
      }
      if (payload.sku) {
        const { data: res } = await addSimpleProductToCartItem(payload)
        response = res;
        if (res && !res.errors) {
          data = response?.data?.addSimpleProductsToCart?.cart
        } else if (res.errors) {
          dispatch({
            type: ADD_PRODUCT_TO_CART_FAILED,
            payload: {
              type: ADD_PRODUCT_TO_CART_FAILED
            }
          })
        }
      }
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: ADD_PRODUCT_TO_CART_FAILED,
          payload: {
            type: ADD_PRODUCT_TO_CART_FAILED
          }
        })
      } else {
        showToast(SUCCESS, "Product added to cart successfully");
        dispatch({
          type: ADD_PRODUCT_TO_CART_SUCCESS, payload: {
            type: ADD_PRODUCT_TO_CART_SUCCESS
          }
        })
        if (response.data) {
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data
            },
          });
        }
      }
    } catch (error: any) {
      showToast(ERROR, error?.message);
      dispatch({
        type: ADD_PRODUCT_TO_CART_FAILED,
        payload: {
          type: ADD_PRODUCT_TO_CART_FAILED
        }
      })
    }
  };
};

