import { Accordion } from "react-bootstrap";

const MobileProductCMSContent = (props: {
  setFreesampleModalShow: any;
  dimensions: string;
  description: string;
  whyus: string;
  delivery: string;
}) => {
  return (
    <div className="custom-container">
      <div className="product-accordion">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h6>DESCRIPTION</h6>
            </Accordion.Header>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: props.description }} />
              {/* <p className="bm">
                This modern, stylish and functional sofa will be the perfect
                addition to any living space in your home. Coco is designed suit
                the space you have, with a no fuss simplistic design helping to
                create a wonderful relaxing environment.
              </p>
              <p className="bm">
                This sofa is shown here in our Burnt Orange fabric.
              </p>
              <p className="bm">
                Please note that as our sofas are made to order, there may be
                slight variations in colour from different batches and fabric
                samples.
              </p>
              <p className="bm">
                <Link
                  to={""}
                  onClick={() => props.setFreesampleModalShow(true)}
                >
                  Click here
                </Link>{" "}
                to order your fabric samples.
              </p> */}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h6>DIMENSIONS</h6>
            </Accordion.Header>
            <Accordion.Body>
              <div
                className="dimensions-box"
                dangerouslySetInnerHTML={{ __html: props.dimensions }}
              >
                {/* <img src={dimensions} alt={""} />
                <div className="dimensions-txt">
                  <p className="bm">All measurements in centimetres.</p>
                  <p className="bm">
                    2 Seater - 1 Base Cushion - A: 104D x B: 202 x C: 85
                  </p>
                  <p className="bm">
                    {" "}
                    2.5 Seater - 1 Base Cushion - A: 104D x B: 222 x C: 85
                  </p>
                  <p className="bm">
                    3 Seater - 1 Base Cushion - A: 104D x B: 242 x C: 85
                  </p>
                  <p className="bm">
                    <Link to={CONTACTUS}>Click here</Link> to create a bespoke
                    build
                  </p>
                </div> */}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h6>DELIVERY</h6>
            </Accordion.Header>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: props.delivery }} />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <h6>WHY US</h6>
            </Accordion.Header>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: props.whyus }} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default MobileProductCMSContent;
