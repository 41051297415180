/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import InputGroups from "../Common/InputGroups/InputGroups";
import ArrowRight from "../../Assets/Images/arrow-right.svg";
import { useLocation } from "react-router-dom";
import {
  CHECKOUT,
  MY_ADDRESS,
  MY_ORDERS_PAGE,
  MY_WISHLIST,
  NEWSLETTER,
  ORDER_SUCCESS,
} from "../../Constants/Route";
import { useDispatch, useSelector } from "react-redux";
import { GET_CMS_BLOCK_SUCCESS } from "../../Redux/Pages/CMSBlock/CMSBlockTypes";
import { submitGuestNewsletter } from "../../Redux/General/GeneralAction";
import validate from "../../Utility/Validation";
import { GUEST_NEWS_LETTER_SUCCESS } from "../../Redux/General/GeneralType";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";

const Footer = () => {
  const [windowSize, setWindowSize] = useState(1366);

  const INITIAL_DATA: any = {
    email: {
      value: null,
      validation: ["email", "required"],
      errors: [null, "Please enter valid email.", "Email is required."],
    },
  };

  const [form, setForm] = useState<any>(INITIAL_DATA);
  const [error, setError] = useState({
    email: null,
  });

  const [isFormValidated, setIsFormValidated] = useState(false);

  const response = useSelector((state: any) => state.guestNewsLetterResponse);

  useEffect(() => {
    if (response && response.type === GUEST_NEWS_LETTER_SUCCESS) {
      setForm(INITIAL_DATA);
    }
  }, [response]);

  useEffect(() => {
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const urls = [MY_WISHLIST, MY_ADDRESS, NEWSLETTER, MY_ORDERS_PAGE];
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState(String);
  const navigate: any = useLocation();

  useEffect(() => {
    setCurrentLocation(navigate.pathname);
  }, [navigate]);

  const [data, setData] = useState<any>({});

  const footerResponse = useSelector((state: any) => state.cmsBlockResponse);

  const [freesampleModalShow, setfreesampleModalShow] = useState(false);

  useEffect(() => {
    if (footerResponse && footerResponse.type === GET_CMS_BLOCK_SUCCESS) {
      const footer_link = footerResponse.data?.items?.find(
        (d: { identifier: string }) => d.identifier === "footer_footer-links"
      );
      const footer_needhelp = footerResponse.data?.items?.find(
        (d: { identifier: string }) => d.identifier === "need-help-footer"
      );
      setData({ footer_link, footer_needhelp });
      setTimeout(() => {
        document.getElementById("fspopup")?.addEventListener("click", (e) => {
          setfreesampleModalShow(true);
        });
      }, 100);
    }
  }, [footerResponse]);

  const submit = () => {
    if (validate(form).isValidated) {
      dispatch(submitGuestNewsletter(form.email.value));
    } else {
      const mesage = { ...validate(form)?.errors };
      setError(mesage);
      setIsFormValidated(true);
    }
  };

  const setFormData = (field: string, value: string) => {
    let details = Object.assign({}, form);
    details[field].value = value;
    setForm({ ...details });
  };

  const [currentLocation1, setCurrentLocation1] = useState(String);
  const navigate1: any = useLocation();

  useEffect(() => {
    setCurrentLocation1(navigate1.pathname);
  }, [navigate1]);

  const urls2 = [CHECKOUT, ORDER_SUCCESS];

  return (
    <>
      {urls.includes(currentLocation) && windowSize < 992 ? (
        ""
      ) : (
        <>
          <div
            className={
              urls2.includes(currentLocation1)
                ? "footer-main in-checkout"
                : "footer-main"
            }
          >
            <div className="footer-top">
              <div className="custom-container">
                <div className="footer-top-wrap">
                  <div
                    className="help-section"
                    dangerouslySetInnerHTML={{
                      __html: data.footer_needhelp?.content,
                    }}
                  ></div>
                  <div className="newsletter-section">
                    <p className="ts help-title caps-on">{"Subscribe"}</p>
                    <p className="bm desc-text">
                      {
                        "Subscribe to receive offers and updates on our products and services."
                      }
                    </p>
                    <div className="input-action">
                      <InputGroups
                        placeholder="Email Address"
                        className="footer-newsletter"
                        id="email"
                        type="email"
                        onChange={(e: any) =>
                          setFormData("email", e.target.value)
                        }
                        error={
                          isFormValidated && error["email"]
                            ? error["email"]
                            : null
                        }
                      />
                      <button className="submit-action" onClick={submit}>
                        <img src={ArrowRight} alt="Newsletter Email" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="footer-secondary"
              dangerouslySetInnerHTML={{ __html: data.footer_link?.content }}
            ></div>
          </div>
          <FreeSamplesPopup
            show={freesampleModalShow}
            onHide={() => setfreesampleModalShow(false)}
          />
        </>
      )}
    </>
  );
};

export default Footer;
