import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  GET_CART_PAGE_SUCCESS,
} from "../../../Redux/Pages/CartPage/CartPageTypes";
import { removeCoupanFromCart } from "../../../Redux/Pages/CartPage/CartPageActions";
import cartright from "../../../Assets/Images/cart-right.svg";
import cartClose from "../../../Assets/Images/close.svg";
import ApplyCouponPopup from "../ApplyCouponPopup/ApplyCouponPopup";

const OrderSummary = () => {

  const dispatch = useDispatch();

  const [data, setData] = useState<any>({});

  const cartResponse = useSelector((state: any) => state.getCartResponse);

  useEffect(() => {
    if (cartResponse && cartResponse.type === GET_CART_PAGE_SUCCESS) {
      setData(cartResponse.data);
    }
  }, [cartResponse]);

  const removeCoupan = () => {
    dispatch(removeCoupanFromCart());
  };

  const [applyCouponshow, setApplyCouponShow] = useState(false);

  return (
    <>
      <div className="total-table">
        <div className="sub-col total">
          <p className="stitle bm">Subtotal</p>
          <p className="svalue bm">
            £{data.prices?.subtotal_excluding_tax?.value}
          </p>
        </div>
        {data.prices &&
          data.prices.discounts &&
          data.prices?.discounts.length > 0
          ? data.prices.discounts.map((d: any) => (
            <div key={d.label} className="sub-col delivery">
              <p className="stitle bm">Discounts ({d.label})</p>
              <p className="svalue bm">£{d.amount.value}</p>
            </div>
          ))
          : null}
        {data.shipping_addresses &&
          data.shipping_addresses.length > 0 &&
          (data.shipping_addresses[0].selected_shipping_method
            ?.carrier_title ||
            data.shipping_addresses[0]?.available_shipping_methods[0]) ? (
          <div
            key={
              data.shipping_addresses[0]?.selected_shipping_method
                ?.carrier_title
            }
            className="sub-col delivery"
          >
            <p className="stitle bm">
              Delivery (
              {data.shipping_addresses[0]?.selected_shipping_method
                ?.carrier_title ||
                data.shipping_addresses[0]
                  ?.available_shipping_methods[0]?.carrier_title}
              )
            </p>
            <p className="svalue bm">
              £
              {data.shipping_addresses[0]?.selected_shipping_method
                ?.carrier_title
                ? data.shipping_addresses[0]?.selected_shipping_method
                  ?.amount?.value
                : data.shipping_addresses[0]?.available_shipping_methods
                  .length > 0
                  ? data.shipping_addresses[0]
                    ?.available_shipping_methods[0].amount.value
                  : 0}
            </p>
          </div>
        ) : null}
        {data.prices &&
          data.prices.applied_taxes &&
          data.prices.applied_taxes.length > 0
          ? data.prices.applied_taxes.map((d: any) => (
            <div key={d.label} className="sub-col delivery">
              <p className="stitle bm">Tax ({d.label})</p>
              <p className="svalue bm">£{d.amount.value}</p>
            </div>
          ))
          : null}
      </div>
      <div className="discount-box">
        {data && data.applied_coupons ? (
          <div className="extra-ip-groups added" onClick={removeCoupan}>
            <input
              type={"text"}
              readOnly
              value={data?.applied_coupons[0]?.code || null}
              placeholder={"Discount code"}
            />
            <button className="dis-btn">
              <img src={cartClose} alt="Apply Coupon Code" />
            </button>
          </div>
        ) : (
          <div
            className="extra-ip-groups"
            onClick={() => setApplyCouponShow(true)}
          >
            <input
              type={"text"}
              readOnly
              value={""}
              placeholder={"Discount code"}
            />
            <button className="dis-btn">
              <img src={cartright} alt="Apply Coupon Code" />
            </button>
          </div>
        )}
      </div>
      <div className="order-total">
        <div className="sub-col maintotal">
          <p className="stitle tm">Order Total</p>
          <p className="svalue tm">
            £{data?.prices?.grand_total?.value}
          </p>
        </div>
      </div>
      <ApplyCouponPopup
          show={applyCouponshow}
          onHide={() => setApplyCouponShow(false)}
        />
    </>
  )
}

export default OrderSummary;