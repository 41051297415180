export const GET_CART_PAGE_REQUEST = "GET_CART_PAGE_REQUEST"
export const GET_CART_PAGE_SUCCESS = "GET_CART_PAGE_SUCCESS"
export const GET_CART_PAGE_ERROR = "GET_CART_PAGE_ERROR"
export const GET_CART_PAGE_CLOSE = "GET_CART_PAGE_CLOSE"

export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_ERROR = "UPDATE_CART_ERROR"

export const CREATE_EMPTY_CART_REQUEST = "CREATE_EMPTY_CART_REQUEST";
export const CREATE_EMPTY_CART_SUCCESS = "CREATE_EMPTY_CART_SUCCESS";
export const CREATE_EMPTY_CART_ERROR = "CREATE_EMPTY_CART_ERROR"

export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST"
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS"
export const DELETE_CART_ERROR = "DELETE_CART_ERROR"

export const REMOVE_COUPAN_REQUEST = "REMOVE_COUPAN_REQUEST"
export const REMOVE_COUPAN_SUCCESS = "REMOVE_COUPAN_SUCCESS"
export const REMOVE_COUPAN_FAILED = "REMOVE_COUPAN_FAILED"

export const ADD_COUPAN_REQUEST = "ADD_COUPAN_REQUEST"
export const ADD_COUPAN_SUCCESS = "ADD_COUPAN_SUCCESS"
export const ADD_COUPAN_FAILED = "ADD_COUPAN_FAILED"

export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST"
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS"
export const ADD_TO_WISHLIST_ERROR = "ADD_TO_WISHLIST_ERROR"

export const REMOVE_FROM_WISHLIST_REQUEST = "REMOVE_FROM_WISHLIST_REQUEST"
export const REMOVE_FROM_WISHLIST_SUCCESS = "REMOVE_FROM_WISHLIST_SUCCESS"
export const REMOVE_FROM_WISHLIST_ERROR = "REMOVE_FROM_WISHLIST_ERROR"

export const GET_WISHLIST_REQUEST = "GET_WISHLIST_REQUEST"
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS"
export const GET_WISHLIST_ERROR = "GET_WISHLIST_ERROR"

export const DELETE_WISHLIST_REQUEST = "DELETE_WISHLIST_REQUEST"
export const DELETE_WISHLIST_SUCCESS = "DELETE_WISHLIST_SUCCESS"
export const DELETE_WISHLIST_ERROR = "DELETE_WISHLIST_ERROR"

export const ADD_PRODUCT_TO_CART_SUCCESS = "ADD_PRODUCT_TO_CART_SUCCESS"
export const ADD_PRODUCT_TO_CART_FAILED = "ADD_PRODUCT_TO_CART_FAILED"