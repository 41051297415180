/* eslint-disable react-hooks/exhaustive-deps */
import Sidebar from "../Sidebar/Sidebar";
import { Link } from "react-router-dom";
import { MY_DETAIL, ORDER_DETAILS } from "../../../Constants/Route";
import { useEffect, useState } from "react";
import MyOrdersSkl from "./MyOrdersSkl";
import { useDispatch, useSelector } from "react-redux";
import { myOrders } from "../../../Redux/General/GeneralAction";
import { MY_ORDERS_SUCCESS } from "../../../Redux/General/GeneralType";
import moment from "moment";
import PaginatedItems from "../../Common/PaginatedItems/PaginatedItems";

const MyOrders = () => {
  const [loading, setloading] = useState(true);

  const [orderData, setorderData] = useState<any>();

  const [pagination, setPagination] = useState<{
    page: number;
    size: number;
  }>({
    page: 1,
    size: 6,
  });

  const dispatch = useDispatch();

  const response = useSelector((state: any) => state.myOrdersResponse);

  useEffect(() => {
    dispatch(myOrders(pagination));
  }, []);

  useEffect(() => {
    if (response && response.type === MY_ORDERS_SUCCESS) {
      setorderData(response?.data?.customer);
      setloading(false);
    }
  }, [response]);

  const setCurrentItems = (payload: { nextPage: number }) => {
    setPagination({
      ...pagination,
      page: payload.nextPage,
    });
  };

  useEffect(() => {
    dispatch(myOrders(pagination));
  }, [pagination]);

  return (
    <>
      {loading ? (
        <MyOrdersSkl />
      ) : (
        <div className="account-container">
          <div className="ll hide-991">
            <Link to={MY_DETAIL} className="acc-backbtn ll">
              My Account
            </Link>
          </div>
          <div className="account-wrap">
            <Sidebar></Sidebar>
            <div className="acc-rightwrap">
              <div className="orderlist-wrap">
                <h6 className="caps-on">My Orders</h6>
                <ul className="order-items">
                  {orderData?.orders?.items.map((data: any, index: any) => (
                    <li className="item" key={index}>
                      <div className="order-top">
                        <div className="o-status">
                          <p className="ll os">{data.status}</p>
                          <p className="ls osline l-text show-768">
                            {data.status}
                          </p>
                        </div>
                        <div className="o-total show-768">
                          <p className="ls ot l-text">Order Total</p>
                          <p className="ll ototal">
                            £{data?.total?.grand_total?.value}
                          </p>
                        </div>
                        <div className="o-item show-768">
                          <p className="ls oi l-text">Item(s)</p>
                          <p className="ll oitem">{data?.items.length}</p>
                        </div>
                        <div className="o-id">
                          <p className="lm oid">Order #{data.increment_id}</p>
                          <p className="ls oid-date l-text">
                            Placed On{" "}
                            {moment(data.order_date).format("D MMM, YYYY")}
                          </p>
                        </div>
                      </div>
                      {data.items.map((data1: any, index1: any) => (
                        <div className="inner-itemwrap" key={index1}>
                          <div className="side-img">
                            <img
                              src={data1?.thumbnail?.thumbnail?.url}
                              alt={data1?.thumbnail?.thumbnail?.label}
                            />
                          </div>
                          <div className="side-details">
                            <Link
                              className="ll"
                              to={""}
                              title={data1.product_name}
                            >
                              {data1.product_name}
                            </Link>
                            <div className="o-attr">
                              <span className="bs">
                                £{data1.product_sale_price.value}
                              </span>
                              {data1.selected_options.map(
                                (data2: any, index2: any) => (
                                  <span className="bs" key={index2 + index1}>
                                    {data2.value}
                                  </span>
                                )
                              )}
                              <span className="bs">
                                Qty {data1.quantity_ordered}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="detail-action">
                        <Link
                          to={ORDER_DETAILS + data.increment_id}
                          title="View Details"
                          className="bm act-detail"
                        >
                          View Details
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <PaginatedItems
                itemsPerPage={pagination.size}
                totalCount={orderData?.orders?.total_count}
                onItemChange={setCurrentItems}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyOrders;
