/* eslint-disable no-useless-escape */
const validate = (schema: any) => {

    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let numberRegex = /^\d+$/;
    let digitRex = /\d/;
    let lowerCaseRex = /[a-z]/;
    let upperCaseRex = /[A-Z]/;
    let specialCharacterRex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let data = Object.assign({}, schema)
    let isValidated = true

    let obj: any = {}
    for (const d in data) {
        if (data[d].validation) {
            for (let i = 0; i < data[d].validation.length; i++) {
                if (data[d].validation[i] === "required" && (data[d].value === null || data[d].value === "")) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                    isValidated = false
                }
                else if (data[d].validation[i] === "email" && !emailRegex.test(data[d].value)) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"

                    isValidated = false
                }
                else if (data[d].validation[i] === "number" && !numberRegex.test(data[d].value)) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                    isValidated = false
                }
                else if (data[d].validation[i] === "digit" && !digitRex.test(data[d].value)) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                    isValidated = false
                }
                else if (data[d].validation[i] === "lower" && (data[d].value && !(lowerCaseRex.test(data[d].value)))) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                    isValidated = false
                } else if (data[d].validation[i] === "upper" && (data[d].value && !(upperCaseRex.test(data[d].value)))) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                    isValidated = false
                }
                else if (data[d].validation[i] === "special" && (data[d].value && !(specialCharacterRex.test(data[d].value)))) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                    isValidated = false
                }
                else if (data[d].validation[i].includes(":")) {
                    let type = data[d].validation[i].split(":")[0]
                    let range = data[d].validation[i].split(":")[1]
                    if (type === "min" && data[d].value?.length < range) {
                        obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                        isValidated = false
                    }
                    else if (type === "max" && data[d].value?.length > range) {
                        obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                        isValidated = false
                    }
                    else if (type === "samepass" && data[d].value !== data[range].value) {
                        obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                        isValidated = false
                    }
                    else if (type === "len" && Number(data[d].value?.length) !== Number(range)) {
                        obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                        isValidated = false
                    }
                }
            }
        }
    }
    return { "errors": obj, "isValidated": isValidated }
}
export default validate;