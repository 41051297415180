import { API_TOKEN } from "../Constants/Config";
import { KEY_USER_TOKEN } from "../Constants/Labels";
import { store } from "../Redux/Store";
import { toast } from 'react-toastify';
import { GUEST_EMAIL } from "../Constants/LocalStorage";

export function isMobile(callback = null) {
    const mobileWidth = 767;
    // On window resize event
    if (typeof callback !== 'undefined') {
        window.addEventListener('resize', () => {
            callback(window.outerWidth <= mobileWidth);
        });
    }
    return window.outerWidth <= mobileWidth;
}

export const Headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${API_TOKEN}`
}


export function setSessionItem(key, value) {
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
}

export function clearSession(redirectTo) {
    const { dispatch } = store;
    localStorage.clear()
    // Update auth state
    dispatch({
        type: 'SAVE_AUTH',
        payload: false
    });
    // Clear customer cart
    dispatch({
        type: 'PURGE_CART'
    });
    // redirect
    if (typeof redirectTo !== 'undefined')
        window.location = redirectTo;
}

export function getSessionItem(key) {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;;
}

export function removeSessionItem(key) {
    return localStorage.removeItem(key);;
}

export function isAuth() {
    const user = getSessionItem(KEY_USER_TOKEN);
    return user && typeof user !== 'undefined' ? user : false;
}

export function isGuestEmail() {
    const email = getSessionItem(GUEST_EMAIL);
    return email && typeof email !== 'undefined' ? email : false;
}

export function showToast(type, messge) {
    toast[type](messge, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    window.scrollTo(0, 0)
}