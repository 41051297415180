export const CHECKOUT_ADDRESS_REQUEST = "CHECKOUT_ADDRESS_REQUEST"
export const CHECKOUT_ADDRESS_SUCCESS = "CHECKOUT_ADDRESS_SUCCESS"
export const CHECKOUT_ADDRESS_FAILED = "CHECKOUT_ADDRESS_FAILED"

export const CHECKOUT_GET_DELIVERY_METHOD_REQUEST = "CHECKOUT_GET_DELIVERY_METHOD_REQUEST"
export const CHECKOUT_GET_DELIVERY_METHOD_SUCCESS = "CHECKOUT_GET_DELIVERY_METHOD_SUCCESS"
export const CHECKOUT_GET_DELIVERY_METHOD_FAILED = "CHECKOUT_GET_DELIVERY_METHOD_FAILED"

export const CHECKOUT_DELIVERY_METHOD_REQUEST = "CHECKOUT_DELIVERY_METHOD_REQUEST"
export const CHECKOUT_DELIVERY_METHOD_SUCCESS = "CHECKOUT_DELIVERY_METHOD_SUCCESS"
export const CHECKOUT_DELIVERY_METHOD_FAILED = "CHECKOUT_DELIVERY_METHOD_FAILED"

export const GET_BRAINTREETOKEN_REQUEST = "GET_BRAINTREETOKEN_REQUEST"
export const GET_BRAINTREETOKEN_SUCCESS = "GET_BRAINTREETOKEN_SUCCESS"
export const GET_BRAINTREETOKEN_FAILED = "GET_BRAINTREETOKEN_FAILED"

export const ORDER_PLACED_SUCCESS = "ORDER_PLACED_SUCCESS"
export const ORDER_PLACED_FAILED = "ORDER_PLACED_FAILED"

export const CHECK_EMAIL_AVAILABLE_SUCCESS = "CHECK_EMAIL_AVAILABLE_SUCCESS"
export const CHECK_EMAIL_AVAILABLE_FAILED = "CHECK_EMAIL_AVAILABLE_FAILED"