import { GRAPHQL_HOMEPAGE_API_FAILED, GRAPHQL_HOMEPAGE_API_REQUEST, GRAPHQL_HOMEPAGE_API_SUCCESS } from "./HomepageTypes";

export function homepageData(state = {}, action: any) {
    switch (action.type) {
        case GRAPHQL_HOMEPAGE_API_REQUEST:
            return { ...action.payload };
        case GRAPHQL_HOMEPAGE_API_SUCCESS:
            return { ...action.payload };
        case GRAPHQL_HOMEPAGE_API_FAILED:
            return { ...action.payload };
        default:
            return state;
    }
}