/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCMSPageAction } from "../../Redux/Pages/CMSPage/CMSPageAction";
import {
  GET_CMS_PAGE_FAILED,
  GET_CMS_PAGE_SUCCESS,
} from "../../Redux/Pages/CMSPage/CMSPageTypes";
import CMSPageSkl from "./CMSPageSkl";

export const CMSPages = (props: { page: string }) => {
  const dispatch = useDispatch();

  const dataResponse = useSelector((state: any) => state.cmsResponse);

  const [loading, setloading] = useState(true);

  const [data, setData] = useState<any>({});

  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(getCMSPageAction(props.page));
  }, []);

  useEffect(() => {
    if (dataResponse) {
      if (dataResponse.type === GET_CMS_PAGE_SUCCESS) {
        setData(dataResponse.data);
        setloading(false);
      } else if (dataResponse.type === GET_CMS_PAGE_FAILED) {
        setError(dataResponse.data);
      }
    }
  }, [dataResponse]);

  return (
    <>
      {error ? <p className="text-danger">{error}</p> : null}
      {loading ? (
        <CMSPageSkl />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
      )}
    </>
  );
};
