import { ERROR, SUCCESS } from "../../Constants/Labels";
import { WEBSITE_ID } from "../../Constants/LocalStorage";
import {
  getCategoryNameByUrl,
  getCustomFormDetail,
  guestNewsletter,
  searchCategory,
  storeConfig,
  submitContactUs,
} from "../../Services/graphQL.service";
import {
  myOrdersDetails,
  myOrdersItems,
  myOrdersThumbImg,
} from "../../Services/User.service";
import { setSessionItem, showToast } from "../../Utility/Utilities";
import {
  URL_RESOLVER_FAILED,
  URL_RESOLVER_REQUEST,
  URL_RESOLVER_SUCCESS,
  CONTACT_US_FORM_FAILED,
  CONTACT_US_FORM_REQUEST,
  CONTACT_US_FORM_SUCCESS,
  STORE_CONFIG_REQUEST,
  STORE_CONFIG_SUCCESS,
  STORE_CONFIG_FAILED,
  GUEST_NEWS_LETTER_SUCCESS,
  SEARCH_FUNCTIONALITY_SUCCESS,
  SEARCH_FUNCTIONALITY_ERROR,
  MY_ORDERS_FAILED,
  MY_ORDERS_SUCCESS,
  MY_ORDERS_DETAILS_SUCCESS,
  MY_ORDERS_DETAILS_FAILED,
  GET_CUSTOM_FORM_SUCCESS,
} from "./GeneralType";

export const findCategoryByUrl = (url: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: URL_RESOLVER_REQUEST,
        payload: { type: URL_RESOLVER_REQUEST },
      });
      const { data: response } = await getCategoryNameByUrl(url);
      const data = response?.data?.urlResolver;
      if (data) {
        dispatch({
          type: URL_RESOLVER_SUCCESS,
          payload: {
            type: URL_RESOLVER_SUCCESS,
            data,
          },
        });
      } else {
        dispatch({
          type: URL_RESOLVER_FAILED,
          payload: {
            type: URL_RESOLVER_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error) {
      console.error(
        "SOMETHING WENT WRONG WHILE FETCHING HOMEPAGE DATA ",
        error
      );
      dispatch({
        type: URL_RESOLVER_FAILED,
        payload: { type: URL_RESOLVER_FAILED, message: error },
      });
    }
  };
};

export const SubmitContactUsForm = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CONTACT_US_FORM_REQUEST,
        payload: { type: CONTACT_US_FORM_REQUEST },
      });
      const { data: response } = await submitContactUs(payload);
      const data = response?.data?.contactusFormSubmit;
      if (data) {
        dispatch({
          type: CONTACT_US_FORM_SUCCESS,
          payload: {
            type: CONTACT_US_FORM_SUCCESS,
            data,
          },
        });
      } else {
        dispatch({
          type: CONTACT_US_FORM_FAILED,
          payload: {
            type: CONTACT_US_FORM_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error) {
      console.error("SOMETHING WENT WRONG. PLEASE TRY AGAIN.", error);
      dispatch({
        type: CONTACT_US_FORM_FAILED,
        payload: { type: CONTACT_US_FORM_FAILED, message: error },
      });
    }
  };
};

export const storeConfigData = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: STORE_CONFIG_REQUEST,
        payload: { type: STORE_CONFIG_REQUEST },
      });
      const { data: response } = await storeConfig();
      const data = response?.data;
      if (data) {
        setSessionItem(WEBSITE_ID, data.storeConfig.store_code);
        dispatch({
          type: STORE_CONFIG_SUCCESS,
          payload: {
            type: STORE_CONFIG_SUCCESS,
            data,
          },
        });
      } else {
        dispatch({
          type: STORE_CONFIG_FAILED,
          payload: {
            type: STORE_CONFIG_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error) {
      console.error("SOMETHING WENT WRONG. PLEASE TRY AGAIN.", error);
      dispatch({
        type: STORE_CONFIG_FAILED,
        payload: { type: STORE_CONFIG_FAILED, message: error },
      });
    }
  };
};

export const submitGuestNewsletter = (email: string) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await guestNewsletter(email);
      if (response && response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        showToast(SUCCESS, "Newsletter subscribed successfully");
        dispatch({
          type: GUEST_NEWS_LETTER_SUCCESS,
          payload: {
            type: GUEST_NEWS_LETTER_SUCCESS,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};

export const searchByCategory = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await searchCategory(payload);
      if (response && response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: SEARCH_FUNCTIONALITY_ERROR,
          payload: { type: SEARCH_FUNCTIONALITY_ERROR },
        });
      } else {
        dispatch({
          type: SEARCH_FUNCTIONALITY_SUCCESS,
          payload: {
            type: SEARCH_FUNCTIONALITY_SUCCESS,
            data: response.data?.products,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: SEARCH_FUNCTIONALITY_ERROR,
        payload: { type: SEARCH_FUNCTIONALITY_ERROR },
      });
    }
  };
};

export const myOrders = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await myOrdersItems(payload);
      const data = response?.data;
      if (data) {
        let pNames: any[] = [];
        data?.customer?.orders?.items
          ?.map((s: { items: any[] }) =>
            s.items.map((i: { product_url_key: any }) => i.product_url_key)
          )
          .forEach((s: any[]) => {
            pNames = [...pNames, ...s];
          });
        const names = pNames
          .map((p) => {
            return '"' + p + '"';
          })
          .join();
        const { data: responseImg } = await myOrdersThumbImg(names);
        data?.customer?.orders?.items?.forEach((element: any) => {
          element.items?.forEach((item: any) => {
            item.thumbnail = responseImg?.data?.products?.items?.find(
              (item1: { url_key: any }) =>
                item1.url_key === item.product_url_key
            );
          });
        });
        dispatch({
          type: MY_ORDERS_SUCCESS,
          payload: {
            type: MY_ORDERS_SUCCESS,
            data,
          },
        });
      } else {
        dispatch({
          type: MY_ORDERS_FAILED,
          payload: {
            type: MY_ORDERS_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error) {
      console.error("SOMETHING WENT WRONG. PLEASE TRY AGAIN.", error);
      dispatch({
        type: MY_ORDERS_FAILED,
        payload: { type: MY_ORDERS_FAILED, message: error },
      });
    }
  };
};

export const getOrderDetail = (orderid: any) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await myOrdersDetails(orderid);
      const data = response?.data;
      if (data) {
        let pNames: any[] = [];
        data?.customer?.orders?.items
          ?.map((s: { items: any[] }) =>
            s.items.map((i: { product_url_key: any }) => i.product_url_key)
          )
          .forEach((s: any[]) => {
            pNames = [...pNames, ...s];
          });
        const names = pNames
          .map((p) => {
            return '"' + p + '"';
          })
          .join();
        const { data: responseImg } = await myOrdersThumbImg(names);
        data?.customer?.orders?.items?.forEach((element: any) => {
          element.items?.forEach((item: any) => {
            item.thumbnail = responseImg?.data?.products?.items?.find(
              (item1: { url_key: any }) =>
                item1.url_key === item.product_url_key
            );
          });
        });
        dispatch({
          type: MY_ORDERS_DETAILS_SUCCESS,
          payload: {
            type: MY_ORDERS_DETAILS_SUCCESS,
            data: data?.customer?.orders,
          },
        });
      } else {
        dispatch({
          type: MY_ORDERS_DETAILS_FAILED,
          payload: {
            type: MY_ORDERS_DETAILS_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error) {
      console.error("SOMETHING WENT WRONG. PLEASE TRY AGAIN.", error);
      dispatch({
        type: MY_ORDERS_DETAILS_FAILED,
        payload: { type: MY_ORDERS_DETAILS_FAILED, message: error },
      });
    }
  };
};

export const getCustomForm = (payload: string) => {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getCustomFormDetail(payload);
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
      } else {
        dispatch({
          type: GET_CUSTOM_FORM_SUCCESS,
          payload: {
            type: GET_CUSTOM_FORM_SUCCESS,
            data: response.data?.CustomForm,
          },
        });
      }
    } catch (error) {
      showToast(ERROR, error);
    }
  };
};
