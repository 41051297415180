export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_LONG = "LOGIN_LONG";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const CHANGE_ACCOUNT_REQUEST = "CHANGE_ACCOUNT_REQUEST";
export const CHANGE_ACCOUNT_SUCCESS = "CHANGE_ACCOUNT_SUCCESS";
export const CHANGE_ACCOUNT_FAILED = "CHANGE_ACCOUNT_FAILED";

export const CHECK_AUTHORIZATION_REQUEST = "CHECK_AUTHORIZATION_REQUEST";
export const CHECK_AUTHORIZATION_SUCCESS = "CHECK_AUTHORIZATION_SUCCESS";
export const CHECK_AUTHORIZATION_FAILD = "CHECK_AUTHORIZATION_FAILD";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const GET_USER_DETAIL_REQUEST = "GET_USER_DETAIL_REQUEST";
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const GET_USER_DETAIL_FAILED = "GET_USER_DETAIL_FAILED";

export const UPDATE_NEWS_LETTER_REQUEST = "UPDATE_NEWS_LETTER_REQUEST";
export const UPDATE_NEWS_LETTER_SUCCESS = "UPDATE_NEWS_LETTER_SUCCESS";
export const UPDATE_NEWS_LETTER_FAILED = "UPDATE_NEWS_LETTER_FAILED";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const GET_ADDRESS_REQUEST = "GET_ADDRESS_REQUEST";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILED = "GET_ADDRESS_FAILED";

export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILED = "DELETE_ADDRESS_FAILED";

export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILED = "UPDATE_ADDRESS_FAILED";

export const CREATE_ADDRESS_REQUEST = "CREATE_ADDRESS_REQUEST";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_FAILED = "CREATE_ADDRESS_FAILED";

export const UN_AUTH = "UN_AUTH";
