/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Tab, Tabs } from "react-bootstrap";
import sample1 from "../../Assets/Images/sample1.png";
import sample2 from "../../Assets/Images/sample2.png";
import sample3 from "../../Assets/Images/sample3.png";
import sample4 from "../../Assets/Images/sample4.png";
import sample5 from "../../Assets/Images/sample5.png";
import sample6 from "../../Assets/Images/sample6.png";
import sample7 from "../../Assets/Images/sample7.png";
import sample8 from "../../Assets/Images/sample8.png";
import samplebig from "../../Assets/Images/sample-big.png";
import CustomButton from "../Common/CustomButton/CustomButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomForm } from "../../Redux/General/GeneralAction";
import { GET_CUSTOM_FORM_SUCCESS } from "../../Redux/General/GeneralType";
import { ToastContainer } from "react-toastify";
import { WARNING } from "../../Constants/Labels";
import { showToast } from "../../Utility/Utilities";

const FreeSamplesPopup = (props: any) => {
  const dispatch = useDispatch();
  const formResponse = useSelector((state: any) => state.getCustomFormResponse);
  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);
  const [colorPallets, setColorPallets] = useState([]);
  const [selectedColors, setSelectedColors] = useState<any[]>([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.show) {
      dispatch(getCustomForm("free_sample_request"));
    }
  }, [props]);

  useEffect(() => {
    if (formResponse && formResponse.type === GET_CUSTOM_FORM_SUCCESS) {
      const dataR = unescape(formResponse.data.form_json);
      setData(JSON.parse(dataR));
      const labels = JSON.parse(dataR)[0][0]?.values.map((v: any) =>
        v.label.toLowerCase()
      );
      const dataD = labels.map((l: string) => {
        const d = JSON.parse(dataR)[0]?.find((dd: any) => dd.name === l);
        return { label: l, values: d?.values, description: d?.label };
      });
      setColorPallets(dataD);
    }
  }, [formResponse]);

  useEffect(() => {
    if (selectedColors.length > 8) {
      showToast(WARNING, "You have reached the maximum (8) of free samples");
    }
  }, [selectedColors]);

  const getColorCode = (c: any) => {
    if (c.image === "") {
      return null;
    }
    return c.image.includes("#") ? (
      <span style={{ background: `${c.image}` }} />
    ) : (
      <img
        src={
          storeConResponse?.data?.storeConfig?.base_media_url +
          "attribute/swatch" +
          c?.image
        }
        alt={c.label}
      />
    );
  };

  return (
    <>
      <Modal {...props} centered className="freesample-popup">
        <Modal.Header closeButton>
          <Modal.Title className="tm">Order Free Fabric Sample</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer />
          <div className="sample-tab-main">
            <Tabs defaultActiveKey="Velvet" className="sample-tabs">
              {colorPallets && colorPallets.length > 0
                ? colorPallets.map((c: any, index: number) => {
                    return (
                      <Tab
                        key={c.label}
                        eventKey={c.label + "Index " + index}
                        title={c.label}
                        className="ll"
                      >
                        <div className="sample-left">
                          <div className="sample-wrap">
                            <p className="ll box-title">{c.label}</p>
                            <p className="bm">{c.description}</p>
                            <div className="sample-main">
                              <div className="sample-checkbox">
                                {c.values && c.values.length > 0
                                  ? c.values.map((cc: any, index: number) => {
                                      return (
                                        <div
                                          key={cc.value}
                                          onClick={() => {
                                            setSelectedColors([
                                              ...selectedColors,
                                              cc,
                                            ]);
                                          }}
                                          className={`samp-tick ${
                                            selectedColors.find(
                                              (s: any) => s.value === cc.value
                                            ) && selectedColors.length <= 8
                                              ? "selected"
                                              : null
                                          }`}
                                        >
                                          <input
                                            disabled={selectedColors.length > 8}
                                            key={cc.label + index}
                                            type="checkbox"
                                            name={cc.label + index}
                                            id={cc.label + index}
                                            value={cc.value}
                                          />
                                          <label key={cc.label + " 1" + index}>
                                            {getColorCode(cc)}
                                          </label>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    );
                  })
                : null}
            </Tabs>
            <div className="sample-right">
              <img src={samplebig} alt={"sample-big"} />
              <p className="bm">Beaufort - Sterling Silver</p>
            </div>
          </div>
          <div className="sample-left left-bottom">
            <div className="sample-wrap">
              <p className="tm">Your Samples</p>
              <div className="sample-main last-samp">
                <div className="sample-checkbox">
                  <div className="samp-tick">
                    <input
                      type="checkbox"
                      name="sample1"
                      id="sample1"
                      value="sample1"
                    />
                    <label>
                      <img src={sample1} alt={"sample-1"} />
                    </label>
                  </div>
                  <div className="samp-tick">
                    <input
                      type="checkbox"
                      name="sample2"
                      id="sample2"
                      value="sample2"
                    />
                    <label>
                      <img src={sample2} alt={"sample-2"} />
                    </label>
                  </div>
                  <div className="samp-tick">
                    <input
                      type="checkbox"
                      name="sample3"
                      id="sample3"
                      value="sample3"
                    />
                    <label>
                      <img src={sample3} alt={"sample-3"} />
                    </label>
                  </div>
                  <div className="samp-tick">
                    <input
                      type="checkbox"
                      name="sample4"
                      id="sample4"
                      value="sample4"
                    />
                    <label>
                      <img src={sample4} alt={"sample-4"} />
                    </label>
                  </div>
                  <div className="samp-tick">
                    <input
                      type="checkbox"
                      name="sample5"
                      id="sample5"
                      value="sample5"
                    />
                    <label>
                      <img src={sample5} alt={"sample-5"} />
                    </label>
                  </div>
                  <div className="samp-tick">
                    <input
                      type="checkbox"
                      name="sample6"
                      id="sample6"
                      value="sample6"
                    />
                    <label>
                      <img src={sample6} alt={"sample-6"} />
                    </label>
                  </div>
                  <div className="samp-tick">
                    <input
                      type="checkbox"
                      name="sample7"
                      id="sample7"
                      value="sample7"
                    />
                    <label>
                      <img src={sample7} alt={"sample-7"} />
                    </label>
                  </div>
                  <div className="samp-tick">
                    <input
                      type="checkbox"
                      name="sample8"
                      id="sample8"
                      value="sample8"
                    />
                    <label>
                      <img src={sample8} alt={"sample-8"} />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-bar">
              <CustomButton bg={"fill"}>Next</CustomButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FreeSamplesPopup;
