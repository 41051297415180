/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCheckoutDeliveryMethod } from "../../../Redux/Pages/Checkout/CheckoutAction";
import {
  CHECKOUT_ADDRESS_SUCCESS,
  CHECKOUT_DELIVERY_METHOD_SUCCESS,
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
} from "../../../Redux/Pages/Checkout/CheckoutTypes";

const MobCkDeliveryMethod = ({
  showPaymentMethod,
  mobileState,
  setMobileState,
}: any) => {
  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);
  const dispatch = useDispatch();

  const [deliveryMethodData, setDeliveryMethodData] = useState<any>({
    available_shipping_methods: [],
    selected_shipping_method: null,
  });

  const [selectedDeliveryMethod, setDeliveryMethod] = useState(0);
  useEffect(() => {
    if (mobileState && mobileState.step === 2) {
      if (mobileState.isClicked) {
        setMobileState({
          ...mobileState,
          isClicked: false,
          loading: true,
        });
        dispatch(
          setCheckoutDeliveryMethod(
            deliveryMethodData?.available_shipping_methods[
              selectedDeliveryMethod
            ]
          )
        );
      }
    }
  }, [mobileState]);

  useEffect(() => {
    if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_GET_DELIVERY_METHOD_SUCCESS
    ) {
      setDeliveryMethodData(
        checkoutResponse?.data?.cart?.shipping_addresses[0]
      );
      const index =
        checkoutResponse?.data?.cart?.shipping_addresses[0]?.available_shipping_methods?.findIndex(
          (a: any) =>
            a.carrier_code ===
            checkoutResponse?.data?.cart?.shipping_addresses[0]
              ?.selected_shipping_method?.carrier_code
        );
      setDeliveryMethod(index > -1 ? index : 0);
    } else if (
      checkoutResponse &&
      (checkoutResponse.type === CHECKOUT_DELIVERY_METHOD_SUCCESS ||
        checkoutResponse.type === CHECKOUT_ADDRESS_SUCCESS)
    ) {
      setDeliveryMethodData(checkoutResponse?.data?.shipping_addresses[0]);
    }
  }, [checkoutResponse]);

  const [appliedMethod, setAppliedMethod] = useState(false);

  useEffect(() => {
    setAppliedMethod(
      deliveryMethodData?.selected_shipping_method !== null &&
        deliveryMethodData?.selected_shipping_method !== undefined
    );
  }, [deliveryMethodData?.selected_shipping_method]);

  return (
    <div className="ckout-inner">
      <div
        className={
          appliedMethod ? "ckout-top-box s-delivmethod" : "ckout-top-box"
        }
      >
        <p className="tm">Delivery Method</p>
      </div>
      <div className="delivery-box">
        {deliveryMethodData?.available_shipping_methods?.map(
          (data: any, index1: any) => (
            <>
              <div key={data.carrier_code + index1} className="delivery-method">
                <div className="rd" key={index1}>
                  <input
                    type="radio"
                    name="delivery-selected"
                    id={data.carrier_code}
                    onChange={() => setDeliveryMethod(index1)}
                    defaultChecked={index1 === selectedDeliveryMethod}
                  />
                  <label className="bm" htmlFor={data.carrier_code}>
                    £{data?.amount?.value}
                  </label>
                </div>
                <span className="bm">{data.carrier_title}</span>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default MobCkDeliveryMethod;
