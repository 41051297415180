import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { IRelatedProducts } from "../../Interfaces/ProductDetail.interface";

const RelatedProducts = (props: { data: IRelatedProducts[] }) => {
  const relatedProductSlider = {
    autoplay: true,
    loop: true,
    dots: false,
    nav: false,
    responsiveClass: true,
    autoplayTimeout: 5000,
    responsive: {
      0: {
        items: 1.2,
        margin: 16,
      },
      768: {
        items: 3,
        margin: 24,
      },
    },
  };
  return (
    <div className="related-product">
      <div className="custom-container">
        <h6 className="caps-on sec-title">YOU MAY ALSO LIKE</h6>
      </div>
      <div className="product-slider">
        <div className="custom-container">
          <OwlCarousel className="owl-theme" {...relatedProductSlider}>
            {props.data.map((data: IRelatedProducts) => {
              return (
                <div key={data.name} className="list-item">
                  <Link
                    to={"/" + data.url_key + data.url_suffix}
                    title={data.name}
                  >
                    <img src={data.image?.url} alt={data.name} />
                  </Link>
                  <div className="product-details">
                    <Link
                      to={"/" + data.url_key + data.url_suffix}
                      className="pdt-name bm"
                      title={data.name}
                    >
                      {data.name}
                    </Link>
                    <div className="price-wrapper">
                      {data.price_range.minimum_price.final_price.value !==
                      data.price_range.minimum_price.regular_price.value ? (
                        <>
                          <p className="special-price">
                            £{data.price_range.minimum_price.final_price.value}
                          </p>
                          <p className="product-price">
                            £
                            {data.price_range.minimum_price.regular_price.value}
                          </p>
                        </>
                      ) : (
                        <p className="normal-price">
                          £{data.price_range.minimum_price.final_price.value}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default RelatedProducts;
