import { ERROR } from "../../../Constants/Labels";
import { getCMSBlock, getProductDetail, getProductList } from "../../../Services/graphQL.service";
import { showToast } from "../../../Utility/Utilities";
import { GET_PRODUCT_CMS_BLOCK_SUCCESS, GET_PRODUCT_DETAIL_FAILED, GET_PRODUCT_DETAIL_SUCCESS, GET_PRODUCT_FABRIC_INFORMATION_SUCCESS, GET_PRODUCT_LIST_FAILED, GET_PRODUCT_LIST_REQUEST, GET_PRODUCT_LIST_SUCCESS } from "./ProductTypes";

export function getProductListData(payload: any) {
    return (async (dispatch: any) => {
        try {
            dispatch({
                type: GET_PRODUCT_LIST_REQUEST,
                payload: { type: GET_PRODUCT_LIST_REQUEST },
            });
            const { data: response } = await getProductList(payload);
            const graphQLResponse = response.data;
            if (graphQLResponse) {
                dispatch({
                    type: GET_PRODUCT_LIST_SUCCESS,
                    payload: {
                        type: GET_PRODUCT_LIST_SUCCESS,
                        data: graphQLResponse
                    },
                })
            } else {
                dispatch({
                    type: GET_PRODUCT_LIST_FAILED,
                    payload: { type: GET_PRODUCT_LIST_FAILED, message: response.errors[0].message },
                });
            }
        } catch (error) {
            console.error("SOMETHING WENT WRONG WHILE FETCHING HOMEPAGE DATA ", error);
            dispatch({
                type: GET_PRODUCT_LIST_FAILED,
                payload: { type: GET_PRODUCT_LIST_FAILED, message: error },
            });
        }
    });
}

export const getProductDetailByName = (name: string) => {
    return (async (dispatch: any) => {
        try {
            const { data: response } = await getProductDetail(name);
            if (response && response.errors) {
                showToast(ERROR, response.errors[0].message)
                dispatch({
                    type: GET_PRODUCT_DETAIL_FAILED, payload: {
                        type: GET_PRODUCT_DETAIL_FAILED
                    }
                })
            } else {
                console.log("response.data.products ", response.data.products)
                dispatch({
                    type: GET_PRODUCT_DETAIL_SUCCESS,
                    payload: {
                        type: GET_PRODUCT_DETAIL_SUCCESS,
                        data: response.data.products?.items.length > 0 ? response.data.products?.items[0] : []
                    }
                })
            }
        } catch (error) {
            showToast(ERROR, error)
            dispatch({
                type: GET_PRODUCT_DETAIL_FAILED, payload: {
                    type: GET_PRODUCT_DETAIL_FAILED
                }
            })
        }
    })
}


export const getCMSContent = (name: string) => {
    return (async (dispatch: any) => {
        try {
            const { data: response } = await getCMSBlock(name)
            if (response.errors) {
                showToast(ERROR, response.errors[0].message)
            } else {
                dispatch({
                    type: GET_PRODUCT_CMS_BLOCK_SUCCESS,
                    payload: {
                        type: GET_PRODUCT_CMS_BLOCK_SUCCESS,
                        data: response.data?.cmsBlocks?.items[0]?.content
                    }
                })
            }
        } catch (error) {
            showToast(ERROR, error)
        }
    })
}

export const getProductDetailFabricInformation = () => {
    return (async (dispatch: any) => {
        try {
            const { data: response } = await getCMSBlock(`"product-detail-fabric-info-popup"`)
            if (response.errors) {
                showToast(ERROR, response.errors[0].message)
            } else {
                dispatch({
                    type: GET_PRODUCT_FABRIC_INFORMATION_SUCCESS,
                    payload: {
                        type: GET_PRODUCT_FABRIC_INFORMATION_SUCCESS,
                        data: response.data?.cmsBlocks?.items[0]?.content
                    }
                })
            }
        } catch (error) {
            showToast(ERROR, error)
        }
    })
}