const InputGroups = (props: any) => {
  return (
    <div
      className={
        props.classname ? `input-groups ${props.classname}` : "input-groups"
      }
    >
      <label className="lm">{props.label}</label>
      <input
        type={props.type ? props.type : "text"}
        onBlur={props.onBlur}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        value={props.value}
        name={props.name}
        id={props.id}
        readOnly={props.readOnly}
        className={props.error ? "error" : ""}
        onKeyDown={props.onKeyDown}
      />
      {props.error ? <span className="error">{props.error}</span> : null}
    </div>
  );
};

export default InputGroups;
