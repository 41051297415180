import { Link } from "react-router-dom";

const SellingPoints = (props: { data: any[] }) => {
  return (
    <div className="selling-point-wrap">
      <div className="custom-container">
        <div className="point-list">
          {
            props.data.map((data) => {
              return <div key={data.elementId} className="sub-point fabric">
                <Link to={""} className="point-links" title={data.title}>
                  <img src={data.image} alt={data.title}></img>
                  <span className="tm">{data.title}</span>
                </Link>
              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}

export default SellingPoints;