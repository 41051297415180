import { combineReducers } from "redux";
import { homepageData } from "./Pages/homepage/HomepageReducer";
import {
  urlResolver,
  contactUsSubmit,
  storeConfigInfo,
  guestNewsLetterResponse,
  searchCategoryResponse,
  myOrdersResponse,
  getCustomFormResponse,
} from "./General/GeneralReducer";
import {
  registerResponse,
  loginResponse,
  userResponse,
  changeAccount,
  updateNewsLetter,
  forgotPassword,
  resetPassword,
  addressResponse,
  unAuthResponse,
} from "./Auth/AuthReducer";
import { cmsResponse } from "./Pages/CMSPage/CMSPageReducer";
import {
  productList,
  productDetailResponse,
} from "./Pages/Products/ProductReducer";
import {
  createCartResponse,
  getCartResponse,
  deleteCartItemResponse,
  wishlistResponse,
  addProductToCart,
} from "./Pages/CartPage/CartPageReduer";
import { cmsBlockResponse } from "./Pages/CMSBlock/CMSBlockReducer";
import { ordersSuccessResponse } from "./Pages/Orders/OrdersReducer";
import { categoryListResponse } from "./Pages/CategoryList/CategoryListReducer";
import { checkoutResponse } from "./Pages/Checkout/CheckoutReducer";

export default combineReducers({
  homepageData,
  urlResolver,
  registerResponse,
  loginResponse,
  userResponse,
  resetPassword,
  changeAccount,
  updateNewsLetter,
  forgotPassword,
  cmsResponse,
  productList,
  contactUsSubmit,
  getCartResponse,
  createCartResponse,
  cmsBlockResponse,
  deleteCartItemResponse,
  storeConfigInfo,
  wishlistResponse,
  ordersSuccessResponse,
  categoryListResponse,
  addressResponse,
  guestNewsLetterResponse,
  searchCategoryResponse,
  productDetailResponse,
  myOrdersResponse,
  checkoutResponse,
  addProductToCart,
  // blogListResponse,
  unAuthResponse,
  getCustomFormResponse,
});
