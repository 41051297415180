/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { STORE_CONFIG_SUCCESS } from "../../Redux/General/GeneralType";
import { getCategoryListAction } from "../../Redux/Pages/CategoryList/CategoryListAction";
import { Buffer } from "buffer";
import { useLocation } from "react-router";
import { CHECKOUT, ORDER_SUCCESS } from "../../Constants/Route";

const MainNavigation = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState<any>({});

  const headerResponse = useSelector(
    (state: any) => state?.categoryListResponse?.data?.categories
  );

  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);

  // const [storeConfigdata, setStoreConfigData] = useState<any>({});

  useEffect(() => {
    if (storeConResponse && storeConResponse.type === STORE_CONFIG_SUCCESS) {
      let str = storeConResponse?.data?.storeConfig?.root_category_uid;
      if (str) {
        const obj = Object.assign({}, storeConResponse?.data?.storeConfig);
        obj.root_category_uid = Buffer.from(str, "base64").toString();
        // setStoreConfigData(obj);
      }
    }
  }, [storeConResponse]);

  useEffect(() => {
    // dispatch(getCategoryListAction(storeConfigdata?.root_category_uid))
    dispatch(getCategoryListAction("2"));
  }, []);

  useEffect(() => {
    if (headerResponse) {
      setData(headerResponse);
    }
  }, [headerResponse]);

  const [activeMenu, setactiveMenu] = useState<any>({});

  const [showHideMenu, setshowHideMenu] = useState(false);

  function menuActiveCall(menuNo: any) {
    setactiveMenu(menuNo);
    setshowHideMenu(true);
  }

  function menuRemoveActiveCall(menuNo: any) {
    setactiveMenu(false);
  }

  const [currentLocation, setCurrentLocation] = useState(String);
  const navigate: any = useLocation();

  useEffect(() => {
    setCurrentLocation(navigate.pathname);
  }, [navigate]);

  const urls = [CHECKOUT, ORDER_SUCCESS];

  return (
    <div className={urls.includes(currentLocation) ? "show-768 web-menu in-checkout" : "show-768 web-menu" }>
      <div className="custom-container">
        <ul className="menu-items">
          {data?.items?.map((info: any, indexno: number) => (
            <>
              {info.include_in_menu === 1 && (
                <div
                  className={
                    showHideMenu && activeMenu === indexno
                      ? "item hovered"
                      : "item"
                  }
                  key={indexno + "a"}
                  onMouseOver={() => menuActiveCall(indexno)}
                  onMouseLeave={() => setshowHideMenu(false)}
                >
                  <li className="dnav-item">
                    <Link
                      to={info.url_key + info.url_suffix}
                      className="ll"
                      onClick={() => setshowHideMenu(false)}
                    >
                      {info.name}
                    </Link>
                  </li>
                  {showHideMenu ? (
                    <div
                      className={
                        activeMenu === indexno
                          ? "content-section active"
                          : "content-section"
                      }
                      onMouseLeave={() => menuRemoveActiveCall(indexno)}
                    >
                      <div className="custom-container">
                        <Row>
                          <Col
                            xl={{ span: 3, offset: 1 }}
                            lg={{ span: 3, offset: 1 }}
                            md={{ span: 4, offset: 1 }}
                          >
                            <div
                              className="left-image"
                              dangerouslySetInnerHTML={{
                                __html: info.cms_block.content,
                              }}
                            ></div>
                          </Col>
                          <Col xl={8} lg={8} md={7} className="links-details">
                            <ul className="right-content">
                              {info?.children
                                .slice(0, 7)
                                .map((info1: any, indexno1: any) => (
                                  <li className="submenu-items ll" key={indexno1}>
                                    <Link
                                      className="ll"
                                      onClick={() => setshowHideMenu(false)}
                                      to={
                                        "/" +
                                        info1.url_path +
                                        info.url_suffix
                                      }
                                    >
                                      {info1.name}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                            {info?.children.length > 7 ? (
                              <ul className="right-content">
                                {info?.children
                                  .slice(7, 14)
                                  .map((info1: any, indexno1: any) => (
                                    <li className="submenu-items ll" key={indexno1}>
                                      <Link
                                        className="ll"
                                        onClick={() => setshowHideMenu(false)}
                                        to={
                                          "/" +
                                          info1.url_path +
                                          info.url_suffix
                                        }
                                      >
                                        {info1.name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MainNavigation;
