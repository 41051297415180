import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";

const spotProductSlider = {
  autoplay: true,
  loop: true,
  dots: false,
  nav: true,
  responsiveClass: true,
  autoplayTimeout: 5000,
  responsive: {
    0: {
      items: 1.2,
      margin: 16,
    },
    768: {
      items: 3,
      margin: 24,
    },
  },
};

export const ProductSpotlight = (props: { data: any[] }) => {
  return (
    <div className="product-spotlight-wrap">
      <div className="custom-container">
        <h6 className="section-title caps-on">Product Spotlight</h6>
        <div className="product-slider">
          <OwlCarousel className="owl-theme" {...spotProductSlider}>
            {props.data.map((data, index) => (
              <div key={index} className="list-item">
                <Link to={"/" + data.urlKey} title={data.title}>
                  <img src={data.image} alt={data.title} />
                </Link>
                <div className="product-details">
                  <Link
                    to={"/" + data.urlKey}
                    className="pdt-name bm"
                    title={data.title}
                  >
                    {data.title}
                  </Link>
                  <div className="price-wrapper">
                    {data.details.specialPrice ? (
                      <>
                        <p className="special-price">
                          {data.details.specialPrice}
                        </p>
                        <p className="product-price">{data.details.price}</p>
                      </>
                    ) : (
                      <p className="normal-price">{data.details.price}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};
