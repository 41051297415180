import { useSelector } from "react-redux";

const ProductColorbox = (props: {
  value_index: number;
  thumbnail: string;
  label: string;
  isChecked: boolean;
  onClicked: any;
}) => {
  const onSelectBox = (e: any) => {
    props.onClicked({
      isChecked: e.target.checked,
      label: props.label,
      value_index: props.value_index,
      type: "color",
    });
  };

  const getColorCode = (c: any) => {
    if (c?.thumbnail === "") {
      return null;
    }
    return c?.image?.includes("#") ? (
      <span style={{ background: `${c.image}` }} />
    ) : (
      <img
        src={c?.thumbnail}
        alt={c.label}
      />
    );
  };

  return (
    <div className={`samp-tick  ${props.isChecked ? "selected" : ""}`}>
      <input
        onClick={onSelectBox}
        type="checkbox"
        name={props.label}
        id={props.label}
        value={props.label}
      />
      <label htmlFor={props.label}>
        {getColorCode(props)}
      </label>
    </div>
  );
};

export default ProductColorbox;
