import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPassword from "./Components/Authentication/ForgotPassword";
import ResetPassword from "./Components/Authentication/ResetPassword";
import Login from "./Components/Authentication/Login";
import Signup from "./Components/Authentication/Signup";
import Footer from "./Components/Footer/Footer";
import Headers from "./Components/Headers/Headers";
import Home from "./Components/Home/Home";
import {
  BLOG_LISTING,
  CART_PAGE,
  CHECKOUT,
  CONTACT_US,
  DYNAMIC_ROUTE,
  DYNAMIC_ROUTE2,
  FORGOT_PWD,
  HOME,
  LOGIN,
  MY_ADDRESS,
  MY_DETAIL,
  MY_ORDERS_PAGE,
  MY_WISHLIST,
  NEWSLETTER,
  ORDER_DETAILS,
  ORDER_SUCCESS,
  RESET_PWD,
  SEARCH_CATEGORY,
  SIGN_UP,
} from "./Constants/Route";
import Main from "./Main";
import CartPage from "./Components/CartPage/CartPage";
import MyAddress from "./Components/MyAccount/MyAddress/MyAddress";
import MyDetails from "./Components/MyAccount/MyDetails/MyDetails";
import MyOrders from "./Components/MyAccount/MyOrders/MyOrders";
import Newsletter from "./Components/MyAccount/Newsletter/Newsletter";
import OrderDetail from "./Components/MyAccount/OrderDetail/OrderDetail";
import Wishlist from "./Components/MyAccount/Wishlist/Wishlist";
import BlogListing from "./Components/Blogs/BlogListing";
import ContactUs from "./Components/CMSPages/ContactUs";
import ProtectedRoute from "./ProtectedRoutes";
import OrderSuccess from "./Components/Checkout/OrderSuccess/OrderSuccess";
import Checkout from "./Components/Checkout/Checkout";
import { ToastContainer } from "react-toastify";
import SearchCategory from "./Components/SearchCategory/SearchCategory";
import Checkoutskl from "./Components/Checkout/Checkoutskl";

const Routesconfig = () => {
  return (
    <BrowserRouter>
      <Headers />
      <ToastContainer />
      <Routes>
        <Route path={HOME} element={<Home />} />
        <Route path={DYNAMIC_ROUTE} element={<Main />} />
        <Route path={DYNAMIC_ROUTE2} element={<Main />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={SIGN_UP} element={<Signup />} />
        <Route path={FORGOT_PWD} element={<ForgotPassword />} />
        <Route path={RESET_PWD} element={<ResetPassword />} />
        <Route path={CART_PAGE} element={<CartPage />} />

        {/* Protected routes */}
        <Route
          path={MY_ORDERS_PAGE}
          element={
            <ProtectedRoute>
              <MyOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path={MY_WISHLIST}
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        />
        <Route
          path={MY_DETAIL}
          element={
            <ProtectedRoute>
              <MyDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={MY_ADDRESS}
          element={
            <ProtectedRoute>
              <MyAddress />
            </ProtectedRoute>
          }
        />
        <Route
          path={NEWSLETTER}
          element={
            <ProtectedRoute>
              <Newsletter />
            </ProtectedRoute>
          }
        />
        <Route
          path={ORDER_DETAILS + ":orderId"}
          element={
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          }
        />
        <Route path={BLOG_LISTING} element={<BlogListing />} />
        <Route path={CONTACT_US} element={<ContactUs />} />
        <Route path={ORDER_SUCCESS} element={<OrderSuccess />} />
        <Route path={CHECKOUT} element={<Checkout />} />
        <Route path={SEARCH_CATEGORY} element={<SearchCategory />} />
        <Route path={"checkoutskl"} element={<Checkoutskl />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Routesconfig;
