export const HOME = "/";
export const CART_PAGE = "/cart";
export const SIGN_UP = "/customer/account/create";
export const LOGIN = "/customer/account/login";
export const FORGOT_PWD = "/customer/account/forgotpassword";
export const RESET_PWD = "/customer/account/createPassword";
export const TERMS = "/terms";
export const PRIVACY_POLICY = "/privacy-policy-cookie-restriction-mode";

export const MY_ORDERS_PAGE = "/sales/order/history";
export const MY_WISHLIST = "/wishlist";
export const MY_DETAIL = "/customer/account/edit";
export const MY_ADDRESS = "/customer/address/new/";
export const NEWSLETTER = "/newsletter/manage/";
export const ORDER_DETAILS = "/sales/order/view/order_id/";

export const CONTACT_US = "/contact";
export const ABOUT_US = "/about-us";
export const CONSTRUCTION = "/construction";
export const TESTIMONIALS = "/testimonials";
export const MEASURING_GUIDE = "/measuring-guide";
export const BLOG_LISTING = "/blog.html";
export const BLOG_DETAIL = "/blog/blog-name.html";

export const ERROR_PAGE = "/404";
export const NODATA = "/nodata";
export const SOMETHING_WRONG = "/something-wrong";
export const FINANCE = "/finance";

export const DYNAMIC_ROUTE = "/:route1";
export const DYNAMIC_ROUTE2 = "/:route1/:route2";
export const FAQS = "/faqs";

export const ORDER_SUCCESS = "/checkout/onepage/success/";
export const CHECKOUT = "/checkout";

export const SEARCH_CATEGORY = "/catalogsearch/result/";
export const CHECKOUTSKl = "/CheckoutSkl";
