import { Link } from "react-router-dom";

const ProductListView = ({
  name,
  url_key,
  url_suffix,
  image,
  price_range,
}: any) => {
  return (
    <div key={name} className="product-list-item">
      <Link to={"/" + url_key + url_suffix}>
        <img src={image.url} alt={image.label} title={image.label} />
        <div className="product-list-item-txt">
          <span className="bm">{name}</span>
        </div>
      </Link>
      {price_range && (
        <div className="price-wrapper">
          {price_range.minimum_price.final_price.value ===
          price_range.minimum_price.regular_price.value ? (
            <p className="normal-price">
              £{price_range.minimum_price.final_price.value}
            </p>
          ) : (
            <>
              <p className="special-price">
                £{price_range.minimum_price.final_price.value}
              </p>
              <p className="product-price">
                £{price_range.minimum_price.regular_price.value}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductListView;
