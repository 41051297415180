/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate } from "react-router-dom";
import {
  ERROR,
  LABEL_ALREADY_HAVE_AN_ACCOUNT,
  LABEL_BY_CREATE_YOUR,
  LABEL_CREATE_ACCOUNT,
  LABEL_FILL_INFO_BELOW,
  LABEL_LOGIN,
  LABEL_PRIVACY_POLICY,
  LABEL_SIGNUP_NEWSLETTER,
  LABEL_TERMS_AND_CONDITION,
} from "../../Constants/Labels";
import CheckboxGroups from "../Common/CheckboxGroups/CheckboxGroups";
import CustomButton from "../Common/CustomButton/CustomButton";
import InputGroups from "../Common/InputGroups/InputGroups";
import PasswordGroups from "../Common/PasswordGroups/PasswordGroups";
import { useEffect, useState } from "react";
import validate from "../../Utility/Validation";
import { useDispatch, useSelector } from "react-redux";
import { onRegistration } from "../../Redux/Auth/AuthAction";
import { HOME, LOGIN, PRIVACY_POLICY, TERMS } from "../../Constants/Route";
import { REGISTER_FAILED, REGISTER_SUCCESS } from "../../Redux/Auth/AuthType";
import { isAuth, showToast } from "../../Utility/Utilities";

const Signup = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const signUpResponse = useSelector((state: any) => state?.registerResponse);

  const INITIAL_SIGN_UP_DATA: any = {
    firstName: {
      value: null,
      validation: ["firstName", "required"],
      errors: [null, "First name is required."],
    },
    lastName: {
      value: null,
      validation: ["lastName", "required"],
      errors: [null, "Last name is required."],
    },
    email: {
      value: null,
      validation: ["email", "required"],
      errors: [null, "Please enter valid email.", "Email is required."],
    },
    password: {
      value: null,
      validation: [
        "password",
        "required",
        "min:8",
        "lower",
        "upper",
        "digit",
        "special",
      ],
      errors: [
        null,
        "Password is required.",
        "Should have minimum 8 character",
        "Should have lowercase",
        "Should have uppercase",
        "Should have numbers",
        "Should have special characters",
      ],
    },
    isSubcribed: { value: false, validation: ["isSubcribed"], errors: [] },
  };

  const navigate = useNavigate();

  if (isAuth()) {
    navigate(HOME);
  }

  const [signupData, setSignUpData] = useState<any>(INITIAL_SIGN_UP_DATA);
  const [error, setError] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
  });

  const [isFormValidated, setIsFormValidated] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    if (validate(signupData).isValidated === true) {
      const payload: any = {};
      Object.keys(signupData).forEach((k) => {
        payload[k] = signupData[k].value;
      });
      dispatch(onRegistration(payload));
    } else {
      const mesage = { ...validate(signupData)?.errors };
      showToast(ERROR, mesage);
      setTimeout(() => setLoading(false), 3000);
      setError(mesage);
      setLoading(false);
      setIsFormValidated(true);
    }
  };

  const setData = (field: string, value: string) => {
    let details = Object.assign({}, signupData);
    details[field].value = value;
    setSignUpData({ ...details });
  };

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000)
  //   if (signupData && (
  //     signupData.email.value && signupData.firstName.value && signupData.lastName.value && signupData.password.value
  //   )) {
  //     setLoading(false)
  //     showToast(ERROR, 'Something went wrong')
  //   }
  // }, [signupData]);

  useEffect(() => {
    if (signUpResponse) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      if (signUpResponse.type === REGISTER_FAILED) {
        showToast(ERROR, signUpResponse.message);
      }
      if (signUpResponse.type === REGISTER_SUCCESS) {
        setTimeout(() => navigate(LOGIN), 3000);
      }
    }
  }, [signUpResponse]);

  useEffect(() => {
    return () => {
      setIsFormValidated(false);
      setLoading(false);
      dispatch({ type: REGISTER_SUCCESS, payload: null });
    };
  }, []);

  return (
    <div className="authentication-wrap signup">
      <div className="custom-container">
        <div className="inner-main">
          <div className="top-head">
            <h6 className="caps-on">{LABEL_CREATE_ACCOUNT}</h6>
            <p className="bm">{LABEL_FILL_INFO_BELOW}</p>
          </div>
          <InputGroups
            type="text"
            onChange={(e: any) => setData("firstName", e.target.value)}
            id="firstName"
            label="First Name*"
            error={
              isFormValidated && error["firstName"] ? error["firstName"] : null
            }
          />

          <InputGroups
            type="text"
            id="lastName"
            label="Last Name*"
            onChange={(e: any) => setData("lastName", e.target.value)}
            error={
              isFormValidated && error["lastName"] ? error["lastName"] : null
            }
          />

          <InputGroups
            id="email"
            type="email"
            label="Email Address*"
            onChange={(e: any) => setData("email", e.target.value)}
            error={isFormValidated && error["email"] ? error["email"] : null}
          />

          <PasswordGroups
            label="Password*"
            id="password"
            onChange={(e: any) => setData("password", e)}
            error={
              isFormValidated && error["password"] ? error["password"] : null
            }
          />

          <div className="signup-news-ltr">
            <CheckboxGroups
              defaultChecked={false}
              id="for-nw"
              onChange={(e: any) => setData("isSubcribed", e.target.checked)}
            >
              {LABEL_SIGNUP_NEWSLETTER}
            </CheckboxGroups>
          </div>
          <div className="create-acc">
            <p className="bm">
              {LABEL_BY_CREATE_YOUR}&nbsp;
              <Link to={TERMS} className="bm">
                {LABEL_TERMS_AND_CONDITION}
              </Link>{" "}
              and
              <Link className="bm" to={PRIVACY_POLICY}>
                {LABEL_PRIVACY_POLICY}
              </Link>
            </p>
          </div>
          <CustomButton
            isLoading={loading}
            onClick={() => onSubmit()}
            id="btn-register"
            bg={"fill"}
          >
            {LABEL_CREATE_ACCOUNT}
          </CustomButton>
          <div className="form-bottom">
            <p className="bm">
              {LABEL_ALREADY_HAVE_AN_ACCOUNT}{" "}
              <Link to={LOGIN} className="bm">
                {LABEL_LOGIN}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
