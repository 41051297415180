import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setCheckoutDeliveryMethod } from "../../Redux/Pages/Checkout/CheckoutAction";
import {
  CHECKOUT_ADDRESS_SUCCESS,
  CHECKOUT_DELIVERY_METHOD_SUCCESS,
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
} from "../../Redux/Pages/Checkout/CheckoutTypes";
import CustomButton from "../Common/CustomButton/CustomButton";
import tickImg from "../../Assets/Images/tick.svg";

const CheckoutDeliveryMethod = ({ showPaymentMethod }: any) => {
  const dispatch = useDispatch();

  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);

  const [deliveryMethodData, setDeliveryMethodData] = useState<any>({
    available_shipping_methods: [],
    selected_shipping_method: null,
  });
  const [selectedDeliveryMethod, setDeliveryMethod] = useState(0);

  useEffect(() => {
    if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_GET_DELIVERY_METHOD_SUCCESS
    ) {
      setDeliveryMethodData(
        checkoutResponse?.data?.cart?.shipping_addresses[0]
      );
      const index =
        checkoutResponse?.data?.cart?.shipping_addresses[0]?.available_shipping_methods?.findIndex(
          (a: any) =>
            a.carrier_code ===
            checkoutResponse?.data?.cart?.shipping_addresses[0]
              ?.selected_shipping_method?.carrier_code
        );
      setDeliveryMethod(index > -1 ? index : 0);
    } else if (
      checkoutResponse &&
      (checkoutResponse.type === CHECKOUT_DELIVERY_METHOD_SUCCESS ||
        checkoutResponse.type === CHECKOUT_ADDRESS_SUCCESS)
    ) {
      setDeliveryMethodData(checkoutResponse?.data?.shipping_addresses[0]);
    }
  }, [checkoutResponse]);

  const [appliedMethod, setAppliedMethod] = useState(false);

  useEffect(() => {
    setAppliedMethod(
      deliveryMethodData?.selected_shipping_method !== null &&
        deliveryMethodData?.selected_shipping_method !== undefined
    );
  }, [deliveryMethodData?.selected_shipping_method]);
  return (
    <div className="ckout-inner">
      <div
        className={
          appliedMethod ? "ckout-top-box s-delivmethod" : "ckout-top-box"
        }
      >
        <div className="ckout-head">
          <span className={`tick ${appliedMethod ? "yes" : "no"}`}>
            {appliedMethod ? (
              <img src={tickImg} alt="Completed Step" />
            ) : (
              <p className="bs">3</p>
            )}
          </span>
          <p className="ts caps-on">Delivery Method</p>
        </div>
        {appliedMethod ? (
          <Link
            to={""}
            title="Change Order"
            className="bm with-border"
            onClick={() => setAppliedMethod(false)}
          >
            Change Method
          </Link>
        ) : null}
      </div>
      {showPaymentMethod && (
        <div
          className={appliedMethod ? "delivery-box selected" : "delivery-box"}
        >
          {deliveryMethodData && !appliedMethod ? (
            deliveryMethodData?.available_shipping_methods?.map(
              (data: any, index1: any) => (
                <>
                  <div
                    key={data.carrier_code + index1}
                    className="delivery-method"
                  >
                    <div className="rd" key={index1}>
                      <input
                        type="radio"
                        name="delivery-selected"
                        id={data.carrier_code}
                        onChange={() => setDeliveryMethod(index1)}
                        defaultChecked={
                          index1 === selectedDeliveryMethod ? true : false
                        }
                      />
                      <label className="bm" htmlFor={data.carrier_code}>
                        £{data?.amount?.value}
                      </label>
                    </div>
                    <span className="bm">{data.carrier_title}</span>
                  </div>
                  {selectedDeliveryMethod === index1 ? (
                    <CustomButton
                      key={data.carrier_code + index1 + 1}
                      onClick={() => {
                        dispatch(
                          setCheckoutDeliveryMethod(
                            deliveryMethodData?.available_shipping_methods[
                              index1
                            ]
                          )
                        );
                        setAppliedMethod(true);
                      }}
                      bg={"fill"}
                    >
                      Continue
                    </CustomButton>
                  ) : null}
                </>
              )
            )
          ) : (
            <p className="bm selected-met">
              {deliveryMethodData?.selected_shipping_method?.carrier_title}
              <span className="bm value">
                £{deliveryMethodData?.selected_shipping_method?.amount?.value}
              </span>
            </p>
          )}
        </div>
      )}
    </div>
  );
};
export default CheckoutDeliveryMethod;
