/* eslint-disable react-hooks/exhaustive-deps */
import CustomButton from "../Common/CustomButton/CustomButton";
import { useEffect, useState } from "react";
import checkoutimg from "../../Assets/Images/arrow-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddress, getAddresses } from "../../Redux/Auth/AuthAction";
import {
  CREATE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILED,
  DELETE_ADDRESS_SUCCESS,
  GET_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
} from "../../Redux/Auth/AuthType";
import { getCartAction } from "../../Redux/Pages/CartPage/CartPageActions";
import {
  getBtreeToken,
  getCheckoutDelvMethod,
} from "../../Redux/Pages/Checkout/CheckoutAction";
import { isAuth } from "../../Utility/Utilities";
import DeliveryAddress from "./DeliveryAddress";
import CheckoutOrderSummary from "./CheckoutOrderSummary";
import OrderSummary from "../CartPage/OrderSummary/OrderSummary";
import CheckoutDeliveryMethod from "./CheckoutDeliveryMethod";
import { GET_CART_PAGE_SUCCESS } from "../../Redux/Pages/CartPage/CartPageTypes";
import Checkoutskl from "./Checkoutskl";
import CheckoutPaymentOption from "./CheckoutPaymentOption";
import MobCkDeliveryAddress from "./MobileCheckout/MobCkDeliveryAddress";
import MobCkDeliveryMethod from "./MobileCheckout/MobCkDeliveryMethod";
import MobCkPaymentOption from "./MobileCheckout/MobCkPaymentOption";
import {
  CHECKOUT_ADDRESS_SUCCESS,
  CHECKOUT_DELIVERY_METHOD_SUCCESS,
  GET_BRAINTREETOKEN_SUCCESS,
} from "../../Redux/Pages/Checkout/CheckoutTypes";
import { useNavigate } from "react-router";
import { CART_PAGE } from "../../Constants/Route";
import CheckoutGuestEmailPassword from "./CheckoutGuestEmail";
import { useLocation } from "react-router";
import AddEditAddressPopup from "../MyAccount/MyAddress/AddNewAddressPopup/AddNewAddressPopup";
import RemovePopup from "../Home/MiniCart/RemovePopup/RemovePopup";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const INITIAL_STATE = {
    buttonTitle: "Continue",
    step: 1,
    isClicked: false,
    loading: false,
    headerTitle: "Shopping Cart",
  };

  const [showAddressPopup, setAddressPopup] = useState(false);
  const [loading, setLoading] = useState<any>(true);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [braintreeToken, setBraintreeToken] = useState<any>({});
  const [mobileState, setMobileState] = useState<any>(INITIAL_STATE);
  const [windowSize, setWindowSize] = useState(1366);
  const [isGuestUser, setGuestUser] = useState<string>("");
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState<any>(null);
  const [isEdit, setEdit] = useState(false);

  const addressResponse = useSelector((state: any) => state.addressResponse);
  const cartResponse = useSelector((state: any) => state.getCartResponse);
  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);
  const [isBillingAddress, setBillingAddress] = useState(false);
  /**
   * Mobile responsive state
   */
  const location = useLocation();
  const [radioStateClass, setRadioClass] = useState(0);
  const [addressPopup, setAddress] = useState<any>(null);
  const [opLoading, setOpLoading] = useState(false);
  const [addressId, setAddressId] = useState<number>(-1);
  const [billingAddresses, setBillingAddresses] = useState<any>([]);
  const [addresses, setAddresses] = useState<any>({
    addresses: [],
    country_name: "United Kingdom",
  });

  useEffect(() => {
    setLoading(
      (cartResponse && cartResponse.type === GET_CART_PAGE_SUCCESS) ||
        (addressResponse && addressResponse.type === GET_ADDRESS_SUCCESS)
        ? false
        : true
    );
  }, [addressResponse, cartResponse]);

  useEffect(() => {
    if (
      cartResponse &&
      cartResponse.data &&
      cartResponse.data.shipping_addresses &&
      cartResponse.data.shipping_addresses.length > 0
    ) {
      if (!isAuth()) {
        setRadioClass(0);
        let d = [];
        if (cartResponse.data.billing_address) {
          d.push(cartResponse.data.billing_address);
        }
        setBillingAddresses(d);
        setAddresses({
          addresses: cartResponse.data.shipping_addresses,
          country_name: "United Kingdom",
        });
      }
      cartResponse.data.shipping_addresses[0].region = {
        ...cartResponse.data.shipping_addresses[0].region,
        region: cartResponse.data.shipping_addresses[0]?.region?.label,
      };
    }
    setSelectedShippingAddress(cartResponse?.data?.shipping_addresses[0]);
    const index = getAddressIndex(cartResponse?.data?.shipping_addresses[0]);
    setRadioClass(index > -1 ? index : 0);
  }, [cartResponse]);

  useEffect(() => {
    if (!isAuth() && addresses.addresses) {
      setRadioClass(getAddressIndex(cartResponse?.data?.shipping_addresses[0]));
    }
  }, [addresses]);

  useEffect(() => {
    setMobileState(INITIAL_STATE);
    if (isAuth()) {
      dispatch(getAddresses());
    }
    dispatch(getCartAction());
    dispatch(getCheckoutDelvMethod());
    dispatch(getBtreeToken());
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, [window.innerWidth]);

  useEffect(() => {
    if (
      checkoutResponse &&
      checkoutResponse.type === GET_BRAINTREETOKEN_SUCCESS
    ) {
      setBraintreeToken(checkoutResponse?.data.createBraintreeClientToken);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_ADDRESS_SUCCESS
    ) {
      setMobileState({
        mobileState,
        loading: false,
        isClicked: false,
        buttonTitle: "Continue",
        headerTitle: "Select Delivery Address",
        step: 2,
      });
      setRadioClass(
        getAddressIndex(checkoutResponse?.data?.shipping_addresses[0])
      );
      const region = checkoutResponse.data?.shipping_addresses[0].region.label;
      checkoutResponse.data.shipping_addresses[0].region = { region };
      if (!isAuth()) {
        setAddresses({
          addresses: [checkoutResponse.data?.shipping_addresses[0]],
          country_name: "United Kingdom",
        });
      }
      setSelectedShippingAddress(checkoutResponse.data?.shipping_addresses[0]);
      setAddress(checkoutResponse.data?.shipping_addresses[0]);
      setAddressPopup(false);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_DELIVERY_METHOD_SUCCESS
    ) {
      setMobileState({
        ...mobileState,
        loading: false,
        isClicked: false,
        buttonTitle: "Place Order",
        headerTitle: "Select Delivery Method",
        step: 3,
      });
    }
  }, [checkoutResponse]);

  useEffect(() => {
    if (addressResponse) {
      const currentState = Object.assign(
        {},
        JSON.parse(JSON.stringify(addresses))
      );
      if (addressResponse.type !== GET_ADDRESS_SUCCESS) {
        setAddressPopup(false);
        setOpLoading(false);
      }
      if (addressResponse.type === CREATE_ADDRESS_SUCCESS) {
        addressResponse.data?.addresses?.forEach((res: any) => {
          res.state = res.region.region;
        });
        const newAddress = Object.assign({}, addressResponse.data);
        currentState.addresses.push(newAddress);
        setAddresses(currentState);
        setRadioClass(
          getAddressIndex(cartResponse?.data?.shipping_addresses[0])
        );
      } else if (addressResponse.type === UPDATE_ADDRESS_SUCCESS) {
        const index: number = currentState.addresses.findIndex(
          (s: { id: number }) => s.id === addressResponse.data.id
        );
        currentState.addresses[index] = addressResponse.data;
        setAddresses(currentState);
      } else if (addressResponse.type === DELETE_ADDRESS_SUCCESS) {
        currentState.addresses = currentState.addresses?.filter(
          (address: { id: number }) => address.id !== addressId
        );
        setAddresses(currentState);
        setAddressId(-1);
      } else if (addressResponse.type === DELETE_ADDRESS_FAILED) {
        setAddressId(-1);
        setAddressPopup(false);
      } else if (addressResponse.type === GET_ADDRESS_SUCCESS) {
        addressResponse.data.addresses.forEach((res: any) => {
          res.state = res.region.region;
        });
        if (!isAuth() && cartResponse?.data?.shipping_addresses) {
          setAddresses({
            addresses: cartResponse?.data?.shipping_addresses,
            country_name: "United Kingdom",
          });
        } else {
          setAddresses(addressResponse.data);
        }
        if (
          cartResponse &&
          cartResponse.data &&
          cartResponse.data.shipping_addresses[0]
        ) {
          setRadioClass(
            getAddressIndex(cartResponse?.data?.shipping_addresses[0])
          );
        }
      }
    }
  }, [addressResponse]);

  useEffect(() => {
    if (mobileState.step === 0) {
      navigate(CART_PAGE);
    }
  }, [mobileState]);

  useEffect(() => {
    if (!isAuth()) {
      dispatch({
        type: GET_ADDRESS_SUCCESS,
        payload: {
          type: GET_ADDRESS_SUCCESS,
          data: {
            addresses: [],
          },
        },
      });
    }
  }, [location]);

  function getAddressIndex(shippingAddress: any) {
    if (shippingAddress) {
      return addresses?.addresses?.findIndex(
        (d: any) =>
          d.telephone === shippingAddress.telephone &&
          d.street.join() === shippingAddress.street.join() &&
          d.firstname === shippingAddress.firstname &&
          d.lastname === shippingAddress.lastname &&
          (d.region.region === shippingAddress.region.region ||
            d.region.region === shippingAddress.region.label)
      );
    }
  }

  const onDeleteAddress = () => {
    setOpLoading(true);
    dispatch(deleteAddress(addressId));
  };

  return (
    <>
      {loading ? (
        <Checkoutskl />
      ) : (
        <div className="checkout">
          <div className="custom-container">
            {/* Responsive checkout start */}
            <div className="res-checkout-header">
              <div className="checkout-header">
                <div className="shipping-cart">
                  {mobileState.step >= 1 ? (
                    <div className="arrow-img">
                      <img
                        src={checkoutimg}
                        onClick={() => {
                          let headerTitle = "";
                          if (mobileState.step === 1) {
                            headerTitle = "Shopping Cart";
                          } else if (mobileState.step === 2) {
                            headerTitle = "Shopping Cart";
                          } else if (mobileState.step === 3) {
                            headerTitle = "Select Shipping Address";
                          } else if (mobileState.step === 3) {
                            headerTitle = "Select Shipping Method";
                          } else {
                            headerTitle = "Select Shopping Cart";
                          }

                          setMobileState({
                            ...mobileState,
                            step: mobileState.step - 1,
                            isClicked: false,
                            headerTitle,
                          });
                        }}
                        alt={"arrow"}
                        title={"arrow"}
                      />
                    </div>
                  ) : null}
                  <span className="ll">{mobileState.headerTitle}</span>
                </div>
                <div className="step-txt">
                  <p className="ll">Step {mobileState.step}/3</p>
                </div>
              </div>
              {mobileState.step === 1 && windowSize < 768 ? (
                <div className="email-box">
                  {!isAuth() && (
                    <CheckoutGuestEmailPassword
                      isGuestUser={isGuestUser}
                      setGuestUser={setGuestUser}
                    />
                  )}
                  {(isAuth() || (!isAuth() && !selectedShippingAddress)) && (
                    <div className="address-btn">
                      <CustomButton
                        bg={"outline-btn"}
                        onClick={() => {
                          setAddress(null);
                          setAddressPopup(true);
                        }}
                      >
                        Add Address
                      </CustomButton>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            {/* Response checkout ends */}
            <div className="checkout-main">
              <div className="checkout-left">
                <div className="ck-head">
                  <h6 className="caps-on">CHECKOUT</h6>
                </div>
                {windowSize > 768 ? (
                  <>
                    {!isAuth() && (
                      <CheckoutGuestEmailPassword
                        isGuestUser={isGuestUser}
                        setGuestUser={setGuestUser}
                      />
                    )}
                    <DeliveryAddress
                      isGuestUser={isGuestUser}
                      showAddressPopup={showAddressPopup}
                      setShowAddressPopup={setAddressPopup}
                      addresses={addresses}
                      setRadioClass={setRadioClass}
                      radioStateClass={radioStateClass}
                      setAddress={setAddress}
                      setAddressId={setAddressId}
                      addressPopup={addressPopup}
                      opLoading={opLoading}
                      onDeleteAddress={onDeleteAddress}
                      addressId={addressId}
                      setEdit={setEdit}
                      selectedShippingAddress={selectedShippingAddress}
                      setSelectedShippingAddress={setSelectedShippingAddress}
                    />
                    <CheckoutOrderSummary
                      isGuestUser={isGuestUser}
                      setShowPaymentMethod={setShowPaymentMethod}
                    />
                    <CheckoutDeliveryMethod
                      isGuestUser={isGuestUser}
                      showPaymentMethod={showPaymentMethod}
                    />
                    <CheckoutPaymentOption
                      billingAddresses={billingAddresses}
                      setAddressPopup={setAddressPopup}
                    />{" "}
                  </>
                ) : null}
                {windowSize < 768 ? (
                  <>
                    {mobileState.step === 1 ? (
                      <MobCkDeliveryAddress
                        addresses={addresses}
                        selectedShippingAddress={selectedShippingAddress}
                        setSelectedShippingAddress={setSelectedShippingAddress}
                        showAddressPopup={showAddressPopup}
                        setShowAddressPopup={setAddressPopup}
                        setRadioClass={setRadioClass}
                        mobileState={mobileState}
                        setMobileState={setMobileState}
                        radioStateClass={radioStateClass}
                        isGuestUser={isGuestUser}
                        setAddress={setAddress}
                        setAddressId={setAddressId}
                        addressPopup={addressPopup}
                        opLoading={opLoading}
                        onDeleteAddress={onDeleteAddress}
                        addressId={addressId}
                        setEdit={setEdit}
                      />
                    ) : null}
                    {mobileState.step === 2 ? (
                      <MobCkDeliveryMethod
                        setMobileState={setMobileState}
                        mobileState={mobileState}
                      />
                    ) : null}
                    {mobileState.step === 3 ? (
                      <MobCkPaymentOption
                        isEdit={isEdit}
                        setEdit={setEdit}
                        addresses={addresses}
                        setAddress={setAddress}
                        addressPopup={addressPopup}
                        braintreeToken={braintreeToken}
                        setShowAddressPopup={setAddressPopup}
                        setMobileState={setMobileState}
                        mobileState={mobileState}
                        isBillingAddress={isBillingAddress}
                        setBillingAddress={setBillingAddress}
                        billingAddresses={billingAddresses}
                      />
                    ) : null}{" "}
                  </>
                ) : null}
              </div>
              <div className="checkout-price show-768">
                <span className="tm">Price Details</span>
                <OrderSummary />
              </div>
            </div>
          </div>
          <div className="checkout-sticky-bar hide-767">
            <div className="act-bar">
              <div className="od-total">
                <span className="ls">Order Total</span>
                <p className="tm">
                  £{cartResponse?.data?.prices?.grand_total?.value}
                </p>
              </div>
              <div className="od-action">
                <CustomButton
                  bg={"fill"}
                  isLoading={mobileState.loading}
                  onClick={() => {
                    setMobileState({
                      ...mobileState,
                      isClicked: true,
                    });
                  }}
                >
                  {mobileState.buttonTitle}
                </CustomButton>
              </div>
            </div>
          </div>
          {(showAddressPopup || isEdit) && (
            <AddEditAddressPopup
              isBillingAddress={isBillingAddress}
              setBillingAddress={setBillingAddress}
              address={addressPopup}
              isEdit={isEdit}
              show={showAddressPopup}
              onHide={() => {
                setAddressPopup(false);
              }}
            />
          )}
          <RemovePopup
            loading={opLoading}
            text="Are you sure you would like to remove this address?"
            onCancel={() => setAddressId(-1)}
            onSubmit={onDeleteAddress}
            show={addressId > -1}
          />
        </div>
      )}
    </>
  );
};

export default Checkout;
