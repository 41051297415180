const SidePanelButtons = (props: {
  value_index: number;
  label: string;
  onSelectPanel: any;
}) => {
  return (
    <div className="on-radio">
      <input
        onClick={() => props.onSelectPanel(props.value_index)}
        type={"radio"}
        name="side-panel"
        id={props.label}
        value={props.label}
      />
      <label className="bm" htmlFor={props.label}>
        {props.label}
      </label>
    </div>
  );
};

export default SidePanelButtons;
