/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { searchByCategory } from "../../Redux/General/GeneralAction";
import { SEARCH_FUNCTIONALITY_SUCCESS } from "../../Redux/General/GeneralType";
import ProductListSkl from "../ProductList/ProductListSkl";
import ProductListView from "../ProductList/ProductListView";

const SearchCategory = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchCategory, setSearchCategory] = useState("");

  const response = useSelector((state: any) => state.searchCategoryResponse);

  const [data, setData] = useState({
    items: [],
    page_info: {
      current_page: 1,
      page_size: 20,
      total_pages: 6,
      hasMore: true,
    },
    total_count: 0,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (response && response.type === SEARCH_FUNCTIONALITY_SUCCESS) {
      setData(response.data);
      setLoading(false);
    }
  }, [response]);

  useEffect(() => {
    if (location && location.search) {
      const category = location.search.split("?")[1];
      if (category) {
        setSearchCategory(category);
        dispatch(searchByCategory({ page: 1, size: 20, category: category }));
      }
    }
  }, [location]);

  const fetchProductList = (page = 1) => {
    const currReqData = Object.assign({}, data);
    currReqData.page_info.current_page = page;
    setData(currReqData);
    dispatch(
      searchByCategory({
        page: currReqData.page_info.current_page,
        size: currReqData.page_info.page_size,
        category: searchCategory,
      })
    );
  };

  return (
    <>
      {loading ? (
        <ProductListSkl />
      ) : (
        <div className="categories">
          <div className="custom-container">
            <h6 className="section-title caps-on">
              Search results for: '{searchCategory}'
            </h6>
            {data.items && data.items.length > 0 ? (
              <div className="product-list">
                <InfiniteScroll
                  dataLength={data.items.length}
                  next={() => fetchProductList(data.page_info.current_page + 1)}
                  hasMore={data.page_info.hasMore}
                  loader={
                    <div className="loading-text">
                      Loading <span className="loader-black"></span>
                    </div>
                  }
                  className="product-list"
                >
                  {data.items.map((item: any) => {
                    return <ProductListView key={item.uid} {...item} />;
                  })}
                </InfiniteScroll>
              </div>
            ) : (
              "No Data found"
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchCategory;
