import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton/CustomButton";
import FreeSamplesPopup from "../../FreeSamplesPopup/FreeSamplesPopup";

const CardBanner = (props: { data: any[] }) => {
  const navigate = useNavigate();

  const [freesampleModalShow, setfreesampleModalShow] = useState(false);

  const popupfunctionCalled = (i23: any) => {
    if (i23 == "0") {
      setfreesampleModalShow(true);
    } else {
      setfreesampleModalShow(false);
    }
  };

  return (
    <>
      <div className="custom-container">
        <div className="cardrel-content">
          {props.data.map((data: any, index: number) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              id={"samplepop" + index}
              title={data.title}
              key={data.elementId + index}
              onClick={(index) => popupfunctionCalled(index)}
            >
              <div className="inner-card">
                <div className="cr-image">
                  <img
                    src={data.image}
                    className="show-768"
                    alt={data.title}
                  ></img>
                  <img
                    src={data.image}
                    className="hide-767"
                    alt={data.title}
                  ></img>
                </div>
                <div className="cr-desc">
                  <h6 className="caps-on">{data.title}</h6>
                  <p className="bm">{data.description}</p>
                  {data.action && data.action.title && (
                    <CustomButton
                      onClick={() => navigate("/" + data.redirectTypeId)}
                      bg={"fill"}
                    >
                      {data.action.title}
                    </CustomButton>
                  )}
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
      <FreeSamplesPopup
        show={freesampleModalShow}
        onHide={() => setfreesampleModalShow(false)}
      />
    </>
  );
};

export default CardBanner;
