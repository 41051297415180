/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import CustomButton from "../Common/CustomButton/CustomButton";
import InputGroups from "../Common/InputGroups/InputGroups";
import validate from "../../Utility/Validation";
import { useDispatch, useSelector } from "react-redux";
import TextareaGroups from "../Common/TextareaGroups/TextareaGroups";
import {
  CONTACT_US_FORM_FAILED,
  CONTACT_US_FORM_SUCCESS,
} from "../../Redux/General/GeneralType";
import { SubmitContactUsForm } from "../../Redux/General/GeneralAction";
import { showToast } from "../../Utility/Utilities";
import { ERROR, SUCCESS } from "../../Constants/Labels";
import { getContactUsCMSBlockAction } from "../../Redux/Pages/CMSBlock/CMSBlockAction";
import { GET_CONTACTUS_BLOCK_SUCCESS } from "../../Redux/Pages/CMSBlock/CMSBlockTypes";

const ContactUs = () => {
  const dispatch = useDispatch();

  const contactUsResponse = useSelector((state: any) => state?.contactUsSubmit);

  const [staticdata, setstaticData] = useState<any>({});

  const cmsContactResponse = useSelector(
    (state: any) => state?.cmsBlockResponse
  );

  useEffect(() => {
    dispatch(getContactUsCMSBlockAction());
  }, []);

  useEffect(() => {
    if (
      cmsContactResponse &&
      cmsContactResponse.type === GET_CONTACTUS_BLOCK_SUCCESS &&
      cmsContactResponse.data &&
      cmsContactResponse.data.items &&
      cmsContactResponse.data.items.length > 0
    ) {
      setstaticData(cmsContactResponse?.data?.items[0].content);
    }
  }, [cmsContactResponse]);

  const INITIAL_CONTACT_US_FORM_DATA: any = {
    fullName: {
      value: null,
      validation: ["fullName", "required"],
      errors: [null, "Name is required."],
    },
    email: {
      value: null,
      validation: ["email", "required"],
      errors: [null, "Please enter valid email.", "Email is required."],
    },
    telephone: {
      value: null,
      validation: ["number", "required"],
      errors: [
        "Please enter valid Telephone Number.",
        "Telephone Number is required.",
      ],
    },
    message: {
      value: null,
      validation: ["message", "required"],
      errors: [null, "Message is required."],
    },
  };

  const [contactData, setcontactData] = useState<any>(
    INITIAL_CONTACT_US_FORM_DATA
  );

  const setData = (field: string, value: string) => {
    let details = Object.assign({}, contactData);
    details[field].value = value;
    setcontactData({ ...details });
  };

  const [error, setError] = useState({
    fullName: null,
    email: null,
    telephone: null,
    message: null,
  });

  const [isFormValidated, setIsFormValidated] = useState(false);

  const onSubmit = () => {
    if (validate(contactData).isValidated === true) {
      const payload: any = {};
      Object.keys(contactData).forEach((k) => {
        payload[k] = contactData[k].value;
      });
      dispatch(SubmitContactUsForm(payload));
    } else {
      setError({ ...validate(contactData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (
      contactData &&
      contactData?.email.value &&
      contactData.fullName.value &&
      contactData.telephone.value &&
      contactData.message.value
    ) {
      setError({ ...validate(contactData)?.errors });
    }
  }, [contactData]);

  useEffect(() => {
    if (contactUsResponse) {
      if (contactUsResponse.type === CONTACT_US_FORM_FAILED) {
        showToast(ERROR, contactUsResponse.message);
      }
      if (contactUsResponse.type === CONTACT_US_FORM_SUCCESS) {
        setcontactData(INITIAL_CONTACT_US_FORM_DATA);
        showToast(SUCCESS, contactUsResponse.data.success_message);
      }
    }
  }, [contactUsResponse]);

  return (
    <div className="cms-wrap contact">
        <div className="custom-container">
          <h6 className="caps-on">CONTACT US</h6>
          <div className="contact-cont-wrap">
            <div
              className="contact-cont address"
              dangerouslySetInnerHTML={{ __html: staticdata }}
            ></div>

            <div className="contact-cont contact-form">
              <p className="bm">
                Jot us a note and we’ll get back to you as quickly as possible.
              </p>
              <InputGroups
                type="text*"
                onChange={(e: any) => setData("fullName", e.target.value)}
                id="fullName"
                label="Name*"
                value={contactData?.firstName?.value}
                error={
                  isFormValidated && error["fullName"]
                    ? error["fullName"]
                    : null
                }
              ></InputGroups>
              <InputGroups
                type="email"
                id="email"
                value={contactData?.email?.value}
                label="Email Address*"
                onChange={(e: any) => setData("email", e.target.value)}
                error={
                  isFormValidated && error["email"] ? error["email"] : null
                }
              ></InputGroups>
              <InputGroups
                type="tel"
                id="telephone"
                value={contactData?.telephone?.value}
                onChange={(e: any) => setData("telephone", e.target.value)}
                error={
                  isFormValidated && error["telephone"]
                    ? error["telephone"]
                    : null
                }
                label="Phone Number"
              ></InputGroups>
              <TextareaGroups
                id="message"
                value={contactData?.message?.value}
                label="What’s on your mind?*"
                onChange={(e: any) => setData("message", e.target.value)}
                error={
                  isFormValidated && error["message"] ? error["message"] : null
                }
              ></TextareaGroups>
              <CustomButton onClick={() => onSubmit()} bg={"fill"}>
                Submit
              </CustomButton>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ContactUs;
