/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CMSPages } from "./Components/CMSPages/CMSPages";
import ProductDetail from "./Components/ProductDetails/ProductDetail";
import ProductList from "./Components/ProductList/ProductList";
import { TYPES } from "./Interfaces/Response.interface";
import { findCategoryByUrl } from "./Redux/General/GeneralAction";
import { URL_RESOLVER_SUCCESS } from "./Redux/General/GeneralType";
// import { Buffer } from "buffer";

const Main = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const urlResolverData = useSelector((state: any) => state.urlResolver);

  const [component, setComponent] = useState<any>();

  useEffect(() => {
    // API call
    loadComponent(location.pathname);
  }, []);

  useEffect(() => {
    if (location) {
      loadComponent(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (urlResolverData && urlResolverData.type === URL_RESOLVER_SUCCESS) {
      const splitR = urlResolverData.data.relative_url.split("/");
      let route = splitR.length > 2 ? splitR[1] : splitR[0];
      route = route.split(".html")[0];
      if (urlResolverData.data.type === TYPES.CATEGORY) {
        const isSubCategory = location?.pathname?.split("/").length > 2;
        const routeSplit = location?.pathname?.split("/");
        if (isSubCategory) {
          const category = routeSplit[1];
          const subCategory = routeSplit[2];
          let entity_uid: string = getEntityuid(urlResolverData);
          setComponent(
            <ProductList
              entity_uid={entity_uid}
              category={category}
              subCategory={subCategory}
            />
          );
        } else {
          let entity_uid: string = getEntityuid(urlResolverData);
          setComponent(<ProductList entity_uid={entity_uid} slug={route} />);
        }
      } else if (urlResolverData.data.type === TYPES.CMS_PAGE) {
        setComponent(<CMSPages page={urlResolverData.data.relative_url} />);
      } else if (urlResolverData.data.type === TYPES.PRODUCT) {
        setComponent(<ProductDetail />);
      }
    }
  }, [urlResolverData]);

  const loadComponent = (path: string = location.pathname) => {
    dispatch(findCategoryByUrl(path.slice(1)));
  };

  function getEntityuid(urlResolverData: any): string {
    // let str = urlResolverData?.data?.entity_uid;
    // let entity_uid = "";
    // if (str) {
    // entity_uid = Buffer.from(str, "base64").toString();
    // }
    // return entity_uid;
    return urlResolverData?.data?.entity_uid;
  }

  return <>{component}</>;
};

export default Main;
