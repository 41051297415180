import axios, { AxiosPromise, AxiosResponse } from "axios";
import {
  END_POINTS,
  getCMSPagePayload,
  getGraphQLPayload,
  getUrlResolverPayload,
  getProductListData,
  submitContactUsForm,
  createEmptyCart,
  getCartDetailPayload,
  updateCartItems,
  getCMSBlockPayload,
  deleteCartPayload,
  addProductToWishlistPayload,
  removeFromWishlistPayload,
  applyCoupanPayload,
  removeCoupanPayload,
  getStoreConfig,
  getWishlistPayload,
  getOrderSuccessPayload,
  getCategoryListPayload,
  getAddressesPayload,
  getCountryNamePayload,
  deleteAddressPayload,
  updateAddressPayload,
  createAddressPayload,
  updateGuestUser,
  searchCategoryPayload,
  getProductDetailPayload,
  mergeCartPayload,
  addProductToCartPayload,
  addSimpleProductToCartPayload,
  getBlogpageList,
  setShippingAddressPayload,
  getDeliveryMethodPayload,
  setDeliveryMethodPayload,
  getBrainTreeTokenPayload,
  getCustomFormDetailPayload,
  placeOrderPayload,
  setBillingAddressPayload,
  isEmailAvailablePayload,
  setGuestEmailPayload,
} from "../Constants/Api";
import { IGraphQLResponse } from "../Interfaces/Response.interface";

export const _REQUEST_GRAPHQL = (page: string): any => {
  return {
    url: END_POINTS.graphQL,
    method: "post",
    headers: Headers,
    data: {
      query: page,
      variables: {},
    },
  };
};

export const getGraphQLDataByPage = (
  page: string
): AxiosPromise<IGraphQLResponse> => {
  return axios(_REQUEST_GRAPHQL(getGraphQLPayload(page)));
};

export const getCategoryNameByUrl = (url: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getUrlResolverPayload(url)));
};

export const getCMSPage = (url: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCMSPagePayload(url)));
};

export const getCMSBlock = (id: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCMSBlockPayload(id)));
};

export const getProductList = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getProductListData(payload)));
};

export const submitContactUs = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(submitContactUsForm(payload)));
};

export const storeConfig = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getStoreConfig()));
};

export const createEmptCart = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(createEmptyCart()));
};

export const addToCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(addProductToCartPayload(payload)));
};

export const mergerCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(mergeCartPayload(payload)));
};

export const addSimpleProductToCartItem = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(addSimpleProductToCartPayload(payload)));
};

export const getCartDetail = (cartId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCartDetailPayload(cartId)));
};

export const updateCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(updateCartItems(payload)));
};

export const deleteCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(deleteCartPayload(payload)));
};

export const addProductToWishlist = (
  payload: any
): Promise<[AxiosResponse<any, any>, AxiosResponse<any, any>]> => {
  return Promise.all([
    axios(_REQUEST_GRAPHQL(addProductToWishlistPayload(payload))),
    axios(_REQUEST_GRAPHQL(deleteCartPayload(payload))),
  ]);
};

export const addProductToWishlistItem = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(addProductToWishlistPayload(payload)));
};

export const removeFromWishlist = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(removeFromWishlistPayload(payload)));
};

export const getWishlistItems = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getWishlistPayload(payload)));
};

export const addCoupan = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(applyCoupanPayload(payload)));
};

export const removeCoupan = (cartId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(removeCoupanPayload(cartId)));
};

export const getOrdersuccess = (orderId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getOrderSuccessPayload(orderId)));
};

export const getCategoryList = (uId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCategoryListPayload(uId)));
};

export const getUserAddresses = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getAddressesPayload()));
};

export const getCountryName = (country_code: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCountryNamePayload(country_code)));
};

export const deleteCustomerAddress = (addressId: number): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(deleteAddressPayload(addressId)));
};

export const updateCustomerAddress = (address: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(updateAddressPayload(address)));
};

export const createCustomerAddress = (address: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(createAddressPayload(address)));
};

export const guestNewsletter = (emailId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(updateGuestUser(emailId)));
};

export const searchCategory = ({
  category,
  page,
  size,
}: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(searchCategoryPayload(category, page, size)));
};

export const getProductDetail = (name: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getProductDetailPayload(name)));
};

export const getBlogListItems = (type: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getBlogpageList(type)));
};

export const setCheckoutAddress = (type: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(setShippingAddressPayload(type)));
};

export const getDeliveryMethods = (cid: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getDeliveryMethodPayload(cid)));
};

export const setCheckoutDelMethod = (type: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(setDeliveryMethodPayload(type)));
};

export const getBrainTreeToken = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getBrainTreeTokenPayload()));
};
export const getCustomFormDetail = (form_name: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCustomFormDetailPayload(form_name)));
};

export const placeOrder = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(placeOrderPayload(payload)));
};

export const setBillingAddressOnCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(setBillingAddressPayload(payload)));
};

export const checkCustomerEmail = (email: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(isEmailAvailablePayload(email)));
};

export const setGuestEmailAddress = (
  email: string,
  cartId: string
): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(setGuestEmailPayload(email, cartId)));
};
