import { SUCCESS_RESPONSE_CODE } from "../../../Constants/Status";
import { getGraphQLDataByPage } from "../../../Services/graphQL.service";
import { GRAPHQL_HOMEPAGE_API_FAILED, GRAPHQL_HOMEPAGE_API_REQUEST, GRAPHQL_HOMEPAGE_API_SUCCESS } from "./HomepageTypes";

export function getHomepageData() {
    return (async (dispatch: any) => {
        try {
            dispatch({
                type: GRAPHQL_HOMEPAGE_API_REQUEST,
                payload: { type: GRAPHQL_HOMEPAGE_API_REQUEST },
            });
            const { data: response } = await getGraphQLDataByPage('home');
            const { component: graphQLResponse } = response.data;
            if (graphQLResponse.statusCode === SUCCESS_RESPONSE_CODE) {
                dispatch({
                    type: GRAPHQL_HOMEPAGE_API_SUCCESS,
                    payload: {
                        type: GRAPHQL_HOMEPAGE_API_SUCCESS,
                        data: graphQLResponse
                    },
                })
            } else {
                dispatch({
                    type: GRAPHQL_HOMEPAGE_API_FAILED,
                    payload: { type: GRAPHQL_HOMEPAGE_API_FAILED, message: 'Something went wrong' },
                });
            }
        } catch (error) {
            console.error("SOMETHING WENT WRONG WHILE FETCHING HOMEPAGE DATA ", error);
            dispatch({
                type: GRAPHQL_HOMEPAGE_API_FAILED,
                payload: { type: GRAPHQL_HOMEPAGE_API_FAILED, message: error },
            });
        }
    });
}