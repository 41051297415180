/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import BannerSection from "./BannerSection/BannerSection";
import SellingPoints from "./SellingPoints/SellingPoints";
import CategoryBlock from "./CategoryBlock/CategoryBlock";
import CardBanner from "./CardBanner/CardBanner";
import "react-loading-skeleton/dist/skeleton.css";
import abtusbanner from "../../Assets/Images/abtus-image.jpg";
import abouttrustpilot from "../../Assets/Images/about-trust-pilot.jpg";
import { Fragment, useEffect, useState } from "react";
import HomeSkeleton from "./HomeSkeleton";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getHomepageData } from "../../Redux/Pages/homepage/HomepageAction";
import { GRAPHQL_HOMEPAGE_API_SUCCESS } from "../../Redux/Pages/homepage/HomepageTypes";
import { IComponent } from "../../Interfaces/Components.interface";
import { ProductSpotlight } from "./ProductSpotlight";
import { FromThePoeple } from "./FromThePeople";
import { AboutUs } from "./AboutUs";

const Home = () => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<IComponent[]>([]);

  const dispatch = useDispatch();

  const response = useSelector((state: RootStateOrAny) => state?.homepageData);

  useEffect(() => {
    dispatch(getHomepageData());
    // dispatch(createEmptyCartAction())

    // document.getElementById("a0")?.addEventListener('click' , (e) => {
    //   e.preventDefault()
    //   console.log("REER ", e)
    // })
  }, []);
  

  useEffect(() => {
    if (
      response &&
      response.type === GRAPHQL_HOMEPAGE_API_SUCCESS &&
      response.data &&
      response.data.component
    ) {
      setData([...response.data.component]);
      setLoading(false);
    }
  }, [response]);

  const renderElement = (key: string, payload: any): any => {
    if (key === "banners") {
      return <BannerSection data={payload} />;
    } else if (key === "selling-points") {
      return <SellingPoints data={payload} />;
    } else if (key === "3columns-grid") {
      return <CategoryBlock data={payload} />;
    } else if (key === "2columns-grid") {
      return (
        <div className="cardbanner-wrap first">
          <CardBanner data={payload} />
        </div>
      );
    } else if (key === "products-slider") {
      return <ProductSpotlight data={payload} />;
    } else if (key === "testimonials") {
      return <FromThePoeple data={payload} />;
    } else if (key === "2columns-left") {
      return (
        <AboutUs
          data={payload[0]}
          abouttrustpilot={abouttrustpilot}
          abtusbanner={abtusbanner}
        />
      );
    }
  };

  return loading ? (
    <HomeSkeleton count={5} />
  ) : (
    <div className="homepage-main">
      {data.map((element: any, index: number) => {
        return (
          <Fragment key={element.componentIdentifier + index}>
            {renderElement(element.componentIdentifier, element.data)}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Home;
