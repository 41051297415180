import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";
import {
  CHECKOUT,
  CONTACT_US,
  LOGIN,
  MY_DETAIL,
  ORDER_SUCCESS,
  SIGN_UP,
} from "../../Constants/Route";
import { useSelector } from "react-redux";
import {
  LABEL_CONTACT_US,
  LABEL_CREATE_ACCOUNT,
  LABEL_LOGIN,
} from "../../Constants/Labels";
import { GET_CMS_BLOCK_SUCCESS } from "../../Redux/Pages/CMSBlock/CMSBlockTypes";
import { useLocation } from "react-router";
import { GET_USER_DETAIL_SUCCESS } from "../../Redux/Auth/AuthType";
export interface IHomeTopbar {
  topbar: any;
  status: any;
  compName: any;
}

const BadgeHeader = () => {
  const [freesampleModalShow, setfreesampleModalShow] = useState(false);

  const data = useSelector((state: any) => state.loginResponse?.data);

  const [info, setInfo] = useState<any>({});

  const headerResponse = useSelector((state: any) => state.cmsBlockResponse);

  useEffect(() => {
    if (headerResponse && headerResponse.type === GET_CMS_BLOCK_SUCCESS) {
      const toppanel_left = headerResponse.data?.items?.find(
        (d: { identifier: string }) => d.identifier === "header-top-left-panel"
      );
      const toppanel_center = headerResponse.data?.items?.find(
        (d: { identifier: string }) =>
          d.identifier === "header-top-center-panel"
      );
      setInfo({ toppanel_left, toppanel_center });
    }
  }, [headerResponse]);

  const [currentLocation, setCurrentLocation] = useState(String);
  const navigate: any = useLocation();

  useEffect(() => {
    setCurrentLocation(navigate.pathname);
  }, [navigate]);

  const userData = useSelector((state: any) => state?.userResponse);

  const [userInformation, setUserInformation] = useState<any>({});

  useEffect(() => {
    if (userData && userData.type === GET_USER_DETAIL_SUCCESS) {
      setUserInformation(userData?.data);
    }
  }, [userData]);

  const urls = [CHECKOUT, ORDER_SUCCESS];

  return (
    <>
      <div
        className={
          urls.includes(currentLocation)
            ? "header-panel in-checkout"
            : "header-panel"
        }
      >
        <div className="custom-container">
          <div className="panel-info">
            <span
              className="lm free-sample"
              dangerouslySetInnerHTML={{ __html: info.toppanel_left?.content }}
              onClick={() => setfreesampleModalShow(true)}
            ></span>
            <span
              className="ll center-line"
              dangerouslySetInnerHTML={{
                __html: info.toppanel_center?.content,
              }}
            ></span>
            <div className="authentication-wrap">
              {!data ? (
                <>
                  <Link className="lm login" to={LOGIN} title={LABEL_LOGIN}>
                    {LABEL_LOGIN}
                  </Link>
                  <Link
                    className="lm c-acc"
                    to={SIGN_UP}
                    title={LABEL_CREATE_ACCOUNT}
                  >
                    {LABEL_CREATE_ACCOUNT}
                  </Link>
                </>
              ) : (
                <Link
                  className="lm c-acc"
                  to={MY_DETAIL}
                  title={userInformation?.firstname}
                >
                  Hello,{" "}
                  {userInformation?.firstname + " " + userInformation?.lastname}
                </Link>
              )}
              <Link className="lm contact" to={CONTACT_US} title="Contact Us">
                {LABEL_CONTACT_US}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <FreeSamplesPopup
        show={freesampleModalShow}
        onHide={() => setfreesampleModalShow(false)}
      />
    </>
  );
};

export default BadgeHeader;
