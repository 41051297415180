/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import cateimg1 from "../../Assets/Images/bespoke.svg";
import cateimg2 from "../../Assets/Images/fabric.svg";
import { useEffect, useState } from "react";
import ProductListSkl from "./ProductListSkl";
import { useDispatch, useSelector } from "react-redux";
import { getProductListData } from "../../Redux/Pages/Products/ProductActions";
import { GET_PRODUCT_LIST_SUCCESS } from "../../Redux/Pages/Products/ProductTypes";
import InfiniteScroll from "react-infinite-scroll-component";
import { IProductResponse } from "../../Interfaces/Products.interface";
import { GET_CMS_PAGE_FAILED } from "../../Redux/Pages/CMSPage/CMSPageTypes";
import ProductListView from "./ProductListView";
import { STORE_CONFIG_SUCCESS } from "../../Redux/General/GeneralType";

const ProductList = (props: {
  category?: string;
  subCategory?: string;
  slug?: string;
  entity_uid?: string;
}) => {
  const INITIAL_PATH = {
    path: "",
    label: "Home",
  };
  const [breadScrumbs, setBreadScrumbs] = useState<any>([INITIAL_PATH]);

  const [reqData, setReqData] = useState({
    page: 1,
    size: 20,
    search: props.slug,
    entity_uid: props.entity_uid,
  });

  const INITIAL_STATE = {
    items: [],
    hasMore: true,
    total_count: 0,
    page_info: {
      current_page: 0,
      page_size: 0,
      total_pages: 0,
    },
  };

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [productListData, setProductList] =
    useState<IProductResponse>(INITIAL_STATE);

  const productListResponse = useSelector((state: any) => state.productList);

  useEffect(() => setLoading(false), [productListData]);

  useEffect(() => {
    if (productListResponse) {
      if (productListResponse.type === GET_PRODUCT_LIST_SUCCESS) {
        const currState = Object.assign({}, productListData);
        currState.items.push(...productListResponse.data.products.items);
        currState.hasMore =
          productListResponse.data.products.items.length <= 20;
        setProductList(currState);
      } else if (productListResponse.type === GET_CMS_PAGE_FAILED) {
        setProductList(INITIAL_STATE);
      }
    }
  }, [productListResponse]);

  useEffect(() => {
    let breadcrumbs: any[] = [INITIAL_PATH];
    if (props.category) {
      breadcrumbs.push({
        path: props.category + ".html",
        label: props.category.split(".html")[0],
      });
      if (props.subCategory) {
        breadcrumbs.push({
          path: props.subCategory + ".html",
          label: props.subCategory.split(".html")[0],
        });
      }
    }
    if (props.slug) {
      breadcrumbs.push({
        path: props.slug,
        label: props.slug.split(".html")[0],
      });
    }
    setBreadScrumbs(breadcrumbs);
    setLoading(true);
    const currReqData = Object.assign({}, reqData);
    currReqData.entity_uid = props.entity_uid;
    setReqData(currReqData);
    dispatch(getProductListData(currReqData));
  }, [props.entity_uid]);

  const fetchProductList = (page = 1) => {
    const currReqData = Object.assign({}, reqData);
    currReqData.page = page;
    setReqData(currReqData);
    dispatch(getProductListData(currReqData));
  };

  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);

  const [storeConfigdata, setStoreConfigData] = useState<any>({});

  useEffect(() => {
    if (storeConResponse && storeConResponse.type === STORE_CONFIG_SUCCESS) {
      setStoreConfigData(storeConResponse?.data?.storeConfig);
    }
  }, [storeConResponse]);

  return (
    <>
      {loading ? (
        <ProductListSkl />
      ) : (
        <div className="categories">
          <div className="custom-container">
            {breadScrumbs && breadScrumbs.length > 1 ? (
              <Breadcrumbs value={breadScrumbs}></Breadcrumbs>
            ) : null}
            {productListResponse?.data?.categoryList[0] &&
            productListResponse?.data?.categoryList[0]?.image ? (
              <div className="cat-banner">
                <div className="cat-txt">
                  <h6 className="caps-on">
                    {productListResponse?.data?.categoryList[0]?.name}
                  </h6>
                  <p
                    className="bm"
                    dangerouslySetInnerHTML={{
                      __html:
                        productListResponse?.data?.categoryList[0]?.description,
                    }}
                  ></p>
                  <div className="cat-service">
                    <div className="cat-service-img">
                      <img
                        src={cateimg1}
                        alt={"Bespoke Service"}
                        title={"Bespoke Service"}
                      />
                    </div>
                    <div className="cat-service-txt">
                      <p className="bm">Bespoke Service</p>
                    </div>
                  </div>
                  <div className="cat-service">
                    <div className="cat-service-img">
                      <img
                        src={cateimg2}
                        alt={"Free Fabric Samples"}
                        title={"Free Fabric Samples"}
                      />
                    </div>
                    <div className="cat-service-txt">
                      <p className="bm">Free Fabric Samples</p>
                    </div>
                  </div>
                </div>
                <div className="cat-banner-img">
                  <img
                    className="show-992"
                    src={productListResponse?.data?.categoryList[0]?.image}
                    alt={productListResponse?.data?.categoryList[0]?.name}
                    title={productListResponse?.data?.categoryList[0]?.name}
                  />
                  <img
                    className="hide-991"
                    src={
                      storeConfigdata?.base_url +
                      productListResponse?.data?.categoryList[0]
                        ?.mobile_thumbnail
                    }
                    alt={productListResponse?.data?.categoryList[0]?.name}
                    title={productListResponse?.data?.categoryList[0]?.name}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                loading && productListData.hasMore
                  ? "product-list"
                  : "product-list loading"
              }
            >
              {loading ? (
                <ProductListSkl />
              ) : productListData.items.length > 0 ? (
                <InfiniteScroll
                  dataLength={productListData.items.length}
                  next={() => fetchProductList(reqData.page + 1)}
                  hasMore={productListData.hasMore && loading}
                  loader={<ProductListSkl />}
                  className="product-list"
                >
                  {productListData.items.map((data: any, index: any) => {
                    return <ProductListView key={index} {...data} />;
                  })}
                </InfiniteScroll>
              ) : (
                <p className="tm nodata-found">We can't find products matching the selection.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductList;
