/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createAddress,
  updateAddress,
} from "../../../../Redux/Auth/AuthAction";
import {
  setBillingAddress,
  setShippingAddress,
} from "../../../../Redux/Pages/Checkout/CheckoutAction";
import { isAuth } from "../../../../Utility/Utilities";
import validate from "../../../../Utility/Validation";
import CustomButton from "../../../Common/CustomButton/CustomButton";
import InputGroups from "../../../Common/InputGroups/InputGroups";
import SelectGroups from "../../../Common/SelectGroups/SelectGroups";

const AddEditAddressPopup = (props: {
  show: boolean;
  isEdit: boolean;
  address: any;
  onHide: any;
  isBillingAddress?: boolean;
  setBillingAddress?: any;
}) => {
  const countries = [{ title: "United Kingdom", value: "United Kingdom" }];

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const INITIAL_FORM: any = {
    id: { value: null },
    firstname: {
      value: props.address?.firstname,
      validation: ["firstname", "required"],
      errors: [null, "First name is required."],
    },
    lastname: {
      value: props.address?.lastname,
      validation: ["lastname", "required"],
      errors: [null, "Last name is required."],
    },
    country: {
      value: countries[0],
      validation: ["country", "required"],
      errors: [null, "Country is required."],
    },
    city: {
      value: props.address?.city,
      validation: ["city", "required"],
      errors: [null, "City is required"],
    },
    state: {
      value: props.address?.region?.region,
      validation: ["state"],
      errors: [null],
    },
    postcode: {
      value: props.address?.postcode,
      validation: ["postcode", "required"],
      errors: [null, "Postcode is required"],
    },
    street: {
      value: props.address?.street.join(),
      validation: ["street", "required"],
      errors: [null, "Street is required"],
    },
    company: {
      value: props.address?.company,
      validation: [],
      errors: [],
    },
    telephone: {
      value: props.address?.telephone,
      validation: ["number", "required"],
      errors: [
        null,
        "Please enter valid Telephone Number.",
        "Telephone Number is required.",
      ],
    },
  };
  const [form, setForm] = useState<any>({});
  const [error, setError] = useState({
    firstname: null,
    lastname: null,
    city: null,
    street: null,
    postcode: null,
    telephone: null,
    company: null,
    state: null,
  });

  const [isFormValidated, setIsFormValidated] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    const vForm = validate(form);
    if (vForm.isValidated === true) {
      const payload: any = {
        isBillingAddress: props.isBillingAddress,
      };
      Object.keys(form).forEach((k) => {
        payload[k] = form[k].value;
      });
      setIsFormValidated(false);
      if (props.isEdit) {
        payload.id = props.address?.id;
        if (isAuth()) {
          dispatch(updateAddress(payload));
        } else {
          delete payload.country;
          payload.region = { region: payload.state };
          if (!props.isBillingAddress) {
            dispatch(setShippingAddress(payload));
          } else {
            props.setBillingAddress(false);
            dispatch(setBillingAddress(payload));
          }
        }
      } else {
        if (isAuth()) {
          dispatch(createAddress(payload));
        } else {
          if (props.isBillingAddress) {
            props.setBillingAddress(false);
            dispatch(setBillingAddress({ address: payload }));
          } else {
            dispatch(setShippingAddress(payload));
          }
        }
      }
    } else {
      const mesage = { ...vForm?.errors };
      setTimeout(() => setLoading(false), 3000);
      setError(mesage);
      setLoading(false);
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    setLoading(false);
    if (props && props.address) {
      const frm = Object.assign({}, INITIAL_FORM);
      if (Object.keys(props.address)) {
        Object.keys(props.address).forEach((k) => {
          if (frm[k]) {
            frm[k].value = props.address[k];
          }
        });
      }
      setForm(frm);
    } else if (props && !props.address) {
      setForm(INITIAL_FORM);
    }
  }, [props]);

  const setData = (field: string, value: string) => {
    let details = Object.assign({}, form);
    details[field].value = value;
    setForm({ ...details });
  };

  return (
    <Modal show={props.show} centered className="address-popup">
      <Modal.Header closeButton onClick={() => props.onHide()}>
        <Modal.Title className="tm">
          {props.isEdit ? "Edit" : "Add New"} Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroups
          className="fname"
          type="text"
          value={form?.firstname?.value || ""}
          onChange={(e: any) => setData("firstname", e.target.value)}
          id="firstname"
          error={
            isFormValidated && error["firstname"] ? error["firstname"] : null
          }
          label="First Name*"
        />
        <InputGroups
          className="lname"
          type="text"
          value={form?.lastname?.value || ""}
          onChange={(e: any) => setData("lastname", e.target.value)}
          id="lastname"
          error={
            isFormValidated && error["lastname"] ? error["lastname"] : null
          }
          label="Last Name*"
        />
        <SelectGroups
          values={countries}
          label="Country*"
          selectedValue={countries[0].value}
        />
        <InputGroups
          label="Postcode*"
          type="text"
          value={form?.postcode?.value || ""}
          onChange={(e: any) => setData("postcode", e.target.value)}
          id="postcode"
          error={
            isFormValidated && error["postcode"] ? error["postcode"] : null
          }
        />
        <InputGroups
          className="company"
          label="Company"
          type="text"
          value={form?.company?.value || ""}
          onChange={(e: any) => setData("company", e.target.value)}
          id="company"
          error={isFormValidated && error["company"] ? error["company"] : null}
        />
        <InputGroups
          className="addr-search"
          placeholder="Start with post code or street"
          label="Address Search"
        />
        <InputGroups
          className="full-street"
          label="Street Address*"
          type="text"
          value={form?.street?.value || ""}
          onChange={(e: any) => setData("street", e.target.value)}
          id="street"
          error={isFormValidated && error["street"] ? error["street"] : null}
        />
        <InputGroups
          className="state"
          label="State/Province"
          type="text"
          value={form?.state?.value || ""}
          onChange={(e: any) => setData("state", e.target.value)}
          id="state"
          error={isFormValidated && error["state"] ? error["state"] : null}
        />
        <InputGroups
          className="city"
          label="City*"
          type="text"
          value={form?.city?.value || ""}
          onChange={(e: any) => setData("city", e.target.value)}
          id="city"
          error={isFormValidated && error["city"] ? error["city"] : null}
        />
        <InputGroups
          className="phone"
          type="tel"
          label="Phone Number*"
          value={form?.telephone?.value || ""}
          onChange={(e: any) => setData("telephone", e.target.value)}
          id="telephone"
          error={
            isFormValidated && error["telephone"] ? error["telephone"] : null
          }
        />
        <div className="action-bar">
          <CustomButton isLoading={loading} onClick={onSubmit} bg="fill">
            save
          </CustomButton>
          <CustomButton onClick={() => props.onHide()}>cancel</CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditAddressPopup;
