import { useNavigate } from "react-router-dom";
import CustomButton from "../Common/CustomButton/CustomButton";

export const AboutUs = (props: {
  data: any;
  abouttrustpilot: any;
  abtusbanner: any;
}) => {
  const navigate = useNavigate();
  return (
    <div className="aboutus-wrap">
      <div className="custom-container">
        <h6 className="caps-on hide-991">{props.data.title}</h6>
        <div className="abtus-sections">
          <div className="abt-left">
            <img
              src={props.data.image}
              className="show-768"
              alt={props.data.title}
              title={props.data.title}
            ></img>
            <img
              src={props.data.mimage}
              className="hide-767"
              alt={props.data.title}
              title={props.data.title}
            ></img>
            <div className="trust-review">
              <img src={props.abouttrustpilot} alt="Trust Pilot"></img>
            </div>
          </div>
          <div className="abt-right">
            <div className="right-top">
              <h6 className="caps-on show-992">{props.data.title}</h6>
              <p
                className="bm"
                dangerouslySetInnerHTML={{ __html: props.data.description }}
              ></p>
            </div>
            {/* {
                            props.data.action && props.data.action.title &&
                            <div className="right-action">
                                <CustomButton onClick={() => navigate("/" + props.data.urlKey)}>{props.data.action.title}</CustomButton>
                            </div>
                        } */}
          </div>
        </div>
      </div>
    </div>
  );
};
