import Skeleton from "react-loading-skeleton";

const ProductListSkl = () => {
  return (
    <div className="categories skl">
      <div className="custom-container">
        {/* <div className="bread-c">
          <Skeleton />
        </div> */}
        <div className="product-list">
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
          <div className="product-list-item skl">
            <div className="img-skl"><Skeleton /></div>
            <div className="name-skl"><Skeleton /></div>
            <div className="price-skl"><Skeleton /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductListSkl;