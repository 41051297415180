import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Share from "../../Assets/Images/Share.svg";
import socialShareFB from "../../Assets/Images/FB.svg";
import socialSharePin from "../../Assets/Images/Pin.svg";
import socialShareWP from "../../Assets/Images/WP.svg";
import socialShareTW from "../../Assets/Images/TW.svg";
import socialShareInsta from "../../Assets/Images/Insta.svg";

const ShareDropdown = () => {
  return (
    <Dropdown className="social-share">
      <Dropdown.Toggle>
        <img src={Share} alt="" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Link to={""}>
          <img src={socialShareWP} alt="WP" />
        </Link>
        <Link to={""}>
          <img src={socialShareFB} alt="FB" />
        </Link>
        <Link to={""}>
          <img src={socialSharePin} alt="Pin" />
        </Link>
        <Link to={""}>
          <img src={socialShareTW} alt="TW" />
        </Link>
        <Link to={""}>
          <img src={socialShareInsta} alt="Insta" />
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShareDropdown;
