const TextareaGroups = (props: any) => {
  return (
    <>
      <div className={props.classname ? `input-groups textarea ${props.classname}` : 'input-groups textarea'}>
          <label className="lm">{props.label}</label>
          <textarea 
            id={props.id}
            onChange={props.onChange}
            className={props.error ? 'input-field error' : 'input-field'}>
          </textarea>
          {
            props.error ?
              <span className="error">{props.error}</span> : null
          }
        </div>
    </>
  )
}

export default TextareaGroups;