/* eslint-disable react-hooks/exhaustive-deps */
import InputGroups from "../Common/InputGroups/InputGroups";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import { Link } from "react-router-dom";
import CustomButton from "../Common/CustomButton/CustomButton";
import ArrowRight from "../../Assets/Images/arrow-right.svg";
import bloglistbanner from "../../Assets/Images/blog-banner.png";
import blogimg1 from "../../Assets/Images/bloglist1.png";
import blogimg2 from "../../Assets/Images/bloglist2.png";
import blogimg3 from "../../Assets/Images/bloglist3.png";
import blogimg4 from "../../Assets/Images/bloglist4.png";
import blogimg5 from "../../Assets/Images/bloglist5.png";
import blogimg6 from "../../Assets/Images/bloglist6.png";
import { BLOG_DETAIL } from "../../Constants/Route";
import { useEffect, useState } from "react";
import BlogListingSkl from "./BlogListingSkl";
import PaginatedItems from "../Common/PaginatedItems/PaginatedItems";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogList,
  getBlogListBlockAction,
} from "../../Redux/Pages/Blog/BlogPageAction";
import { GET_CMS_BLOCK_SUCCESS } from "../../Redux/Pages/CMSBlock/CMSBlockTypes";
import { BLOG_PAGE_SUCCESS } from "../../Redux/Pages/Blog/BlogPageTypes";
import moment from "moment";

const BlogListing = () => {
  const breadSc = [
    {
      path: "/",
      label: "Home",
    },
    {
      label: "Blog",
    },
  ];

  const blogList = [
    {
      blogThumb: blogimg1,
      blogCat: "Beds",
      blogTitle: "Upholstered beds in luxury fabrics",
      blogAuthor: "Richard Coles",
      blogPublishDate: "24 Sep 2021",
      blogPath: BLOG_DETAIL,
    },
    {
      blogThumb: blogimg2,
      blogCat: "Bedroom Design Ideas",
      blogTitle: "Luxury upholstered beds: 4 fabric styles, over 50 shades",
      blogAuthor: "livingitup",
      blogPublishDate: "7 Mar 2019",
      blogPath: "",
    },
    {
      blogThumb: blogimg3,
      blogCat: "",
      blogTitle: "Luxury velvet beds and sofas",
      blogAuthor: "James Root",
      blogPublishDate: "27 Sep 2018",
      blogPath: "",
    },
    {
      blogThumb: blogimg4,
      blogCat: "Beds",
      blogTitle: "Beds with big headboards",
      blogAuthor: "James Root",
      blogPublishDate: "9 Jul 2018",
      blogPath: "",
    },
    {
      blogThumb: blogimg5,
      blogCat: "Sofa Bed",
      blogTitle: "Bed in a box by living it up",
      blogAuthor: "James Root",
      blogPublishDate: "26 Jun 2018",
      blogPath: "",
    },
    {
      blogThumb: blogimg6,
      blogCat: "Beds",
      blogTitle: "Our new beds for spring 2017",
      blogAuthor: "James Root",
      blogPublishDate: "11 Apr 2017",
      blogPath: "",
    },
    {
      blogThumb: blogimg1,
      blogCat: "Beds",
      blogTitle: "Upholstered beds in luxury fabrics",
      blogAuthor: "Richard Coles",
      blogPublishDate: "24 Sep 2021",
      blogPath: "",
    },
    {
      blogThumb: blogimg2,
      blogCat: "Bedroom Design Ideas",
      blogTitle: "Luxury upholstered beds: 4 fabric styles, over 50 shades",
      blogAuthor: "livingitup",
      blogPublishDate: "7 Mar 2019",
      blogPath: "",
    },
    {
      blogThumb: blogimg3,
      blogCat: "",
      blogTitle: "Luxury velvet beds and sofas",
      blogAuthor: "James Root",
      blogPublishDate: "27 Sep 2018",
      blogPath: "",
    },
    {
      blogThumb: blogimg4,
      blogCat: "Beds",
      blogTitle: "Beds with big headboards",
      blogAuthor: "James Root",
      blogPublishDate: "9 Jul 2018",
      blogPath: "",
    },
    {
      blogThumb: blogimg5,
      blogCat: "Sofa Bed",
      blogTitle: "Bed in a box by living it up",
      blogAuthor: "James Root",
      blogPublishDate: "26 Jun 2018",
      blogPath: "",
    },
    {
      blogThumb: blogimg6,
      blogCat: "Beds",
      blogTitle: "Our new beds for spring 2017",
      blogAuthor: "James Root",
      blogPublishDate: "11 Apr 2017",
      blogPath: "",
    },
    {
      blogThumb: blogimg1,
      blogCat: "Beds",
      blogTitle: "Upholstered beds in luxury fabrics",
      blogAuthor: "Richard Coles",
      blogPublishDate: "24 Sep 2021",
      blogPath: "",
    },
    {
      blogThumb: blogimg2,
      blogCat: "Bedroom Design Ideas",
      blogTitle: "Luxury upholstered beds: 4 fabric styles, over 50 shades",
      blogAuthor: "livingitup",
      blogPublishDate: "7 Mar 2019",
      blogPath: "",
    },
    {
      blogThumb: blogimg3,
      blogCat: "",
      blogTitle: "Luxury velvet beds and sofas",
      blogAuthor: "James Root",
      blogPublishDate: "27 Sep 2018",
      blogPath: "",
    },
    {
      blogThumb: blogimg4,
      blogCat: "Beds",
      blogTitle: "Beds with big headboards",
      blogAuthor: "James Root",
      blogPublishDate: "9 Jul 2018",
      blogPath: "",
    },
    {
      blogThumb: blogimg5,
      blogCat: "Sofa Bed",
      blogTitle: "Bed in a box by living it up",
      blogAuthor: "James Root",
      blogPublishDate: "26 Jun 2018",
      blogPath: "",
    },
    {
      blogThumb: blogimg6,
      blogCat: "Beds",
      blogTitle: "Our new beds for spring 2017",
      blogAuthor: "James Root",
      blogPublishDate: "11 Apr 2017",
      blogPath: "",
    },
  ];

  const [items, setItems] = useState<any>([]);

  const recentPost = [
    {
      postTitle: "Upholstered beds in luxury fabrics",
    },
    {
      postTitle: "Luxury upholstered beds: 4 fabric styles, over 50 shades",
    },
    {
      postTitle: "Luxury velvet beds and sofas",
    },
    {
      postTitle: "Beds with big headboards",
    },
    {
      postTitle: "Bed in a box by living it up",
    },
  ];

  const blogCategories = [
    {
      catName: "Beds (6)",
    },
    {
      catName: "Bedroom Design Ideas (2)",
    },
    {
      catName: "Contemporary Beds (1)",
    },
    {
      catName: "Sofa Bed (2)",
    },
    {
      catName: "Sofa (3)",
    },
    {
      catName: "Accent Chairs (2)",
    },
    {
      catName: "Armchairs (2)",
    },
    {
      catName: "Corner Sofas (1)",
    },
    {
      catName: "Storage Beds (1)",
    },
    {
      catName: "Occasional Chairs (1)",
    },
  ];

  const [loading, setloading] = useState(false);

  const setCurrentItems = (payload: { start: number; end: number }) => {
    setItems(blogList.slice(payload.start, payload.end));
  };

  useEffect(() => {
    setItems(blogList.slice(0, 9));
    setTimeout(async () => {
      setloading(true);
    }, 1000);
  }, []);

  const dispatch = useDispatch();

  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);

  const blogListResponse = useSelector((state: any) => state?.blogListResponse);

  const [data, setCMSData] = useState<any>({});

  const [blogData, setblogData] = useState<any>({});

  // useEffect(() => {
  //   dispatch(getBlogList("ALL"));
  //   dispatch(getBlogListBlockAction());
  // }, []);

  // const blogCMSResponse = useSelector((state: any) => state.cmsBlockResponse);

  // useEffect(() => {
  //   if (blogCMSResponse && blogCMSResponse.type === GET_CMS_BLOCK_SUCCESS) {
  //     setCMSData(blogCMSResponse?.data?.items[0].content);
  //   }
  // }, [blogCMSResponse]);

  // useEffect(() => {
  //   if(blogListResponse && blogListResponse.type ===  BLOG_PAGE_SUCCESS) {
  //     setblogData(blogListResponse?.data)
  //   }
  // })

  // console.log("blogData", blogData)

  return (
    <>
      {!loading && <BlogListingSkl />}
      {loading && (
        <div className="blog-container">
          <Breadcrumbs value={breadSc}></Breadcrumbs>
          <div className="bl-topinfo">
            <div className="blbanner-info">
              <div className="section-desc">
                <h4 className="caps-on">Blogs</h4>
                <p className="bm">
                  Subscribe to receive communications from us about our
                  products, services and tips to transform your home.
                </p>
                <div className="input-action">
                  <InputGroups
                    type="email"
                    placeholder="Email Address"
                    classname="footer-newsletter"
                  />
                  <button className="submit-action">
                    <img src={ArrowRight} alt="Newsletter Email" />
                  </button>
                </div>
                <p className="bm">Unsubscribe anytime.</p>
              </div>
            </div>
            <div className="blbanner-img">
              <img src={bloglistbanner} alt="Blog Listing Banner" />
            </div>
          </div>
          <div className="bl-contentwrap">
            <ul className="list-items">
              {blogData?.amBlogPosts?.items.map((data: any, index: any) => (
                <li className="item" key={index}>
                  <div className="img-section">
                    <img
                      src={
                        storeConResponse?.data?.storeConfig?.base_url +
                        data?.list_thumbnail
                      }
                      alt={data?.title}
                    />
                  </div>
                  <div className="info-section">
                    <Link
                      to={""}
                      title={data?.categories}
                      className="ll cat-name"
                    >
                      {data?.categories}
                    </Link>
                    <Link
                      to={data.url_key}
                      title={data.title}
                      className="tm blog-name"
                    >
                      {data.title}
                    </Link>
                    <div className="extra-info">
                      <div className="bm author">
                        By{" "}
                        <Link to={""} title={data?.author_id}>
                          {data?.author_id}
                        </Link>
                      </div>
                      <div className="bm pub-date">
                        <p className="bm">
                          {moment(data?.published_at).format("D MMM YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="list-sidebar">
              <div className="sear-blog box">
                <p className="tm box-title">Search the blog</p>
                <div className="sear-input">
                  <InputGroups
                    classname="search"
                    placeholder="Search..."
                  ></InputGroups>
                </div>
                <div className="sear-action">
                  <CustomButton bg="fill">Search</CustomButton>
                </div>
              </div>
              <div className="recent-posts box">
                <p className="tm box-title">Recent posts</p>
                <ul className="recent-list">
                  {recentPost.map((data, index) => (
                    <li className="ritem">
                      <Link to={""} title={data.postTitle} className="ll">
                        {data.postTitle}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="categories box">
                <p className="tm box-title">Categories</p>
                <ul className="recent-list">
                  {blogCategories.map((data, index) => (
                    <li className="citem">
                      <Link to={""} title={data.catName} className="ll">
                        {data.catName}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <PaginatedItems
            itemsPerPage={9}
            totalCount={blogList.length}
            onItemChange={setCurrentItems}
          />
        </div>
      )}
    </>
  );
};

export default BlogListing;
