/* eslint-disable react-hooks/exhaustive-deps */
import DropIn from "braintree-web-drop-in-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ORDER_SUCCESS } from "../../../Constants/Route";
import { GET_CART_PAGE_SUCCESS } from "../../../Redux/Pages/CartPage/CartPageTypes";
import {
  onPlaceOrder,
  setBillingAddress,
} from "../../../Redux/Pages/Checkout/CheckoutAction";
import {
  CHECKOUT_DELIVERY_METHOD_SUCCESS,
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
  ORDER_PLACED_FAILED,
  ORDER_PLACED_SUCCESS,
} from "../../../Redux/Pages/Checkout/CheckoutTypes";
import { isAuth } from "../../../Utility/Utilities";
import CheckboxGroups from "../../Common/CheckboxGroups/CheckboxGroups";

const MobCkPaymentOption = (props: {
  mobileState: any;
  setMobileState: any;
  setShowAddressPopup: any;
  braintreeToken: any;
  addresses: any;
  addressPopup: any;
  setAddress: any;
  isBillingAddress: boolean;
  setBillingAddress: any;
  isEdit: boolean;
  setEdit: any;
  billingAddresses: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);
  const addressResponse = useSelector((state: any) => state.addressResponse);
  const cartResponse = useSelector((state: any) => state.getCartResponse);

  const [deliveryMethodData, setDeliveryMethodData] = useState<any>({
    available_shipping_methods: [],
    selected_shipping_method: null,
  });
  // const [props.addresses, setprops.addresses] = useState<any>({
  //   addresses: [],
  //   country_name: "United Kingdom",
  // });
  const [showBillingAddressDropdown, setBillingAddressDropdown] =
    useState(false);

  const [paymentOptionIndex, setPaymentOptionIndex] = useState(0);
  const [showSelectedAddr, setShowSelectedAddr] = useState<any>(null);
  const [cardInstance, setCardInstance] = useState<any>(null);

  useEffect(() => {
    if (props.mobileState && props.mobileState.step === 3) {
      if (props.mobileState.isClicked) {
        props.setMobileState({
          ...props.mobileState,
          isClicked: false,
          loading: true,
        });
        (async () => {
          try {
            const { nonce } = await cardInstance.requestPaymentMethod();
            console.log("NONCE ", nonce);
            dispatch(
              onPlaceOrder({
                nonce,
                method_code: "braintree",
              })
            );
          } catch (error) {
            props.setMobileState({
              ...props.mobileState,
              loading: false,
              isClicked: false,
            });
          }
        })();
      }
    }
  }, [props.mobileState]);

  useEffect(() => {
    if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_GET_DELIVERY_METHOD_SUCCESS
    ) {
      setDeliveryMethodData(checkoutResponse?.data?.cart);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === ORDER_PLACED_SUCCESS
    ) {
      props.setMobileState({
        ...props.mobileState,
        loading: false,
        isClicked: false,
        buttonTitle: "Place Order",
        step: 1,
      });
      navigate(ORDER_SUCCESS);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === ORDER_PLACED_FAILED
    ) {
      props.setMobileState({
        ...props.mobileState,
        loading: false,
        isClicked: false,
        buttonTitle: "Place Order",
      });
    } else if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_DELIVERY_METHOD_SUCCESS
    ) {
      setDeliveryMethodData(checkoutResponse?.data);
    }
  }, [checkoutResponse]);

  function handleSelectChange(event: any) {
    if (!isAuth() && Number(event.target.value) === 0) {
      props.setAddress(null);
      props.setEdit(false);
      props.setBillingAddress(Number(event.target.value) === 0);
    }
    props.setShowAddressPopup(Number(event.target.value) === 0);
    const address = props.addresses.addresses?.find(
      (add: { id: number }) => add.id === Number(event.target.value)
    );
    if (Number(event.target.value)) {
      dispatch(
        setBillingAddress({ customerAddressId: Number(event.target.value) })
      );
    }
    setShowSelectedAddr(address);
  }

  const setBillingDrop = (e: boolean) => {
    if (e) {
      if (cartResponse.type === GET_CART_PAGE_SUCCESS) {
        const shippingAddress = cartResponse.data.shipping_addresses[0];
        if (shippingAddress) {
          const addressId = addressResponse?.data?.addresses?.find(
            (d: any) =>
              d.telephone === shippingAddress.telephone &&
              d.street.join() === shippingAddress.street.join() &&
              d.firstname === shippingAddress.firstname &&
              d.lastname === shippingAddress.lastname &&
              d.region.region === shippingAddress.region.region
          )?.id;
          if (addressId) {
            dispatch(
              setBillingAddress({
                customerAddressId: Number(addressId),
              })
            );
          }
        }
      }
    }
    setBillingAddressDropdown(!e);
  };

  return (
    <div className="ckout-inner">
      <div className="ckout-top-box">
        <p className="tm">Payment Options</p>
      </div>
      {deliveryMethodData?.shipping_addresses &&
      deliveryMethodData?.shipping_addresses[0] ? (
        <div className="payment-box">
          {deliveryMethodData?.available_payment_methods?.map(
            (data1: any, index: any) => (
              <div key={data1.title + index} className="delivery-method">
                <div className="rd" key={index}>
                  <input
                    type="radio"
                    name="payment-selected"
                    id={data1.code}
                    onChange={() => setPaymentOptionIndex(index)}
                    defaultChecked={index === paymentOptionIndex}
                  />
                  <label className="bm" htmlFor={data1.code}>
                    {data1?.title}
                  </label>
                </div>
                {index === paymentOptionIndex ? (
                  <>
                    <div className="cod payment-action-content">
                      <CheckboxGroups
                        onChange={(e: any) => {
                          setBillingDrop(e.target.checked);
                        }}
                        defaultChecked={!showBillingAddressDropdown}
                        id="unselect-addresses"
                      >
                        Bill to delivery address
                      </CheckboxGroups>
                    </div>
                    {showBillingAddressDropdown ? (
                      <div className="list-addresses">
                        <div className="select-group">
                          <select
                            value={showSelectedAddr?.id}
                            onChange={handleSelectChange}
                          >
                            {isAuth()
                              ? props.addresses.addresses?.map(
                                  (item: any, index2: number) => (
                                    <option
                                      key={index2 + item.firstname}
                                      value={item.id}
                                    >
                                      {item.firstname + " " + item.lastname}
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        props.addresses.country_name +
                                        ", " +
                                        item.postcode}
                                    </option>
                                  )
                                )
                              : props.billingAddresses?.map(
                                  (item: any, index2: number) => (
                                    <option
                                      key={index2 + item.firstname}
                                      value={item.id}
                                    >
                                      {item.firstname + " " + item.lastname}
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        props.addresses.country_name +
                                        ", " +
                                        item.postcode}
                                    </option>
                                  )
                                )}
                            {isAuth() ? (
                              <option value="0">New Address</option>
                            ) : null}
                          </select>
                        </div>
                        {isAuth()
                          ? props.addresses.addresses?.map((item: any) =>
                              item?.id === showSelectedAddr?.id ? (
                                <p className="bm">
                                  <div className="ckout-txt">
                                    <p className="bm">
                                      {item.firstname + " " + item.lastname}
                                    </p>
                                    <p className="bm">
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        props.addresses.country_name +
                                        ", " +
                                        item.postcode}
                                    </p>
                                    <Link to={""} className="bm">
                                      {item.telephone}
                                    </Link>
                                  </div>
                                </p>
                              ) : null
                            )
                          : props.billingAddresses?.map((item: any) =>
                              item?.id === showSelectedAddr?.id ? (
                                <p className="bm">
                                  <div className="ckout-txt">
                                    <p className="bm">
                                      {item.firstname + " " + item.lastname}
                                    </p>
                                    <p className="bm">
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        props.addresses.country_name +
                                        ", " +
                                        item.postcode}
                                    </p>
                                    <Link to={""} className="bm">
                                      {item.telephone}
                                    </Link>
                                  </div>
                                </p>
                              ) : null
                            )}
                      </div>
                    ) : null}
                    <div className="credit-card-content">
                      {data1.code === "braintree" ? (
                        <div>
                          <DropIn
                            options={{
                              cvv: {
                                maskInput: true,
                              },
                              authorization: props.braintreeToken,
                            }}
                            onInstance={(cardInstance: any) =>
                              setCardInstance(cardInstance)
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </div>
            )
          )}
        </div>
      ) : null}
    </div>
  );
};

export default MobCkPaymentOption;
