import { Tabs, Tab } from "react-bootstrap";

const WebProductCMSContent = (props: {
  setFreesampleModalShow: any;
  dimensions: string;
  description: string;
  whyus: string;
  delivery: string;
}) => {
  return (
    <div className="product-detail-tab">
      <div className="custom-container">
        <Tabs defaultActiveKey="Description" className="product-tabs">
          <Tab eventKey="Description" title="Description">
            {/* <p className="bm with-space">
              This modern, stylish and functional sofa will be the perfect
              addition to any living space in your home. Coco is designed suit
              the space you have, with a no fuss simplistic design helping to
              create a wonderful relaxing environment.
            </p>
            <p className="bm with-space">
              This sofa is shown here in our Burnt Orange fabric.
            </p>
            <p className="bm with-space">
              Please note that as our sofas are made to order, there may be
              slight variations in colour from different batches and fabric
              samples.
            </p>
            <p className="bm with-space">
              <Link to={""} onClick={() => props.setFreesampleModalShow(true)}>
                Click here
              </Link>{" "}
              to order your fabric samples.
            </p> */}
            <div dangerouslySetInnerHTML={{ __html: props.description }} />
          </Tab>
          <Tab eventKey="dimensions" title="dimensions">
            {/* <div className="dimensions-box">
              <img src={dimensions} alt={""} />
              <div className="dimensions-txt">
                <p className="bm with-space">
                  All measurements in centimetres.
                </p>
                <p className="bm with-space">
                  2 Seater - 1 Base Cushion - A: 104D x B: 202 x C: 85
                </p>
                <p className="bm with-space">
                  {" "}
                  2.5 Seater - 1 Base Cushion - A: 104D x B: 222 x C: 85
                </p>
                <p className="bm with-space">
                  3 Seater - 1 Base Cushion - A: 104D x B: 242 x C: 85
                </p>
                <p className="bm with-space">
                  <Link to={CONTACTUS}>Click here</Link> to create a bespoke
                  build
                </p>
              </div>
            </div> */}
            <div
              className="dimensions-box"
              dangerouslySetInnerHTML={{ __html: props.dimensions }}
            ></div>
          </Tab>
          <Tab eventKey="delivery" title="delivery">
            <div dangerouslySetInnerHTML={{ __html: props.delivery }} />
          </Tab>
          <Tab eventKey="why us" title="why us">
            <div dangerouslySetInnerHTML={{ __html: props.whyus }} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
export default WebProductCMSContent;
