import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";

const BannerSection = (props: { data: any[] }) => {

  const ItemImgSlider = {
    autoplay: true,
    loop: true,
    dots: true,
    nav: false,
    responsiveClass: true,
    autoplayTimeout: 5000,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
    },
  };

  return (
    <div className="banner-wrap">
      <div className="slider-item">
        <div className="desktop-slider show-768">
          <OwlCarousel className="owl-theme" {...ItemImgSlider}>
            {
              props.data.map((data, index) => (
                <div key={data.elementId} className="banner-item">
                  <Link to={"/" + data.urlKey}><img src={data.image} alt={data.title} title={data.title} /></Link>
                </div>
              ))
            }
          </OwlCarousel>
        </div>
        <div className="mobile-slider hide-767">
          <OwlCarousel className="owl-theme" {...ItemImgSlider}>
            {
              props.data.map((data, index) => (
                <div className="banner-item" key={data.elementId}>
                  <Link to={"/" + data.urlKey} className="banner-item"><img src={data.mimage} alt={data.title} title={data.title} /></Link>
                </div>
              ))
            }
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}

export default BannerSection;