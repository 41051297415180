import { CHECKOUT_ADDRESS_FAILED, CHECKOUT_ADDRESS_REQUEST, CHECKOUT_ADDRESS_SUCCESS, CHECKOUT_DELIVERY_METHOD_FAILED, CHECKOUT_DELIVERY_METHOD_REQUEST, CHECKOUT_DELIVERY_METHOD_SUCCESS, CHECKOUT_GET_DELIVERY_METHOD_FAILED, CHECKOUT_GET_DELIVERY_METHOD_REQUEST, CHECKOUT_GET_DELIVERY_METHOD_SUCCESS, CHECK_EMAIL_AVAILABLE_SUCCESS, GET_BRAINTREETOKEN_FAILED, GET_BRAINTREETOKEN_REQUEST, GET_BRAINTREETOKEN_SUCCESS, ORDER_PLACED_FAILED, ORDER_PLACED_SUCCESS } from "./CheckoutTypes";

export function checkoutResponse(state = {}, action: any) {
    switch (action.type) {
        case CHECKOUT_ADDRESS_REQUEST:
            return { ...action.payload };
        case CHECKOUT_ADDRESS_SUCCESS:
            return { ...action.payload };
        case CHECKOUT_ADDRESS_FAILED:
            return { ...action.payload };
        case CHECKOUT_GET_DELIVERY_METHOD_REQUEST:
            return { ...action.payload };
        case CHECKOUT_GET_DELIVERY_METHOD_SUCCESS:
            return { ...action.payload };
        case CHECKOUT_GET_DELIVERY_METHOD_FAILED:
            return { ...action.payload };
        case CHECKOUT_DELIVERY_METHOD_REQUEST:
            return { ...action.payload };
        case CHECKOUT_DELIVERY_METHOD_SUCCESS:
            return { ...action.payload };
        case CHECKOUT_DELIVERY_METHOD_FAILED:
            return { ...action.payload };
        case GET_BRAINTREETOKEN_REQUEST:
            return { ...action.payload };
        case GET_BRAINTREETOKEN_SUCCESS:
            return { ...action.payload };
        case GET_BRAINTREETOKEN_FAILED:
            return { ...action.payload };
        case ORDER_PLACED_SUCCESS:
            return { ...action.payload }
        case ORDER_PLACED_FAILED:
            return { ...action.payload }
        case CHECK_EMAIL_AVAILABLE_SUCCESS:
            return { ...action.payload }
        default:
            return state;
    }
}