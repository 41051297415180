import {
  CHANGE_ACCOUNT_FAILED,
  CHANGE_ACCOUNT_REQUEST,
  CHANGE_ACCOUNT_SUCCESS,
  CREATE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILED,
  DELETE_ADDRESS_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  GET_ADDRESS_SUCCESS,
  GET_USER_DETAIL_FAILED,
  GET_USER_DETAIL_REQUEST,
  GET_USER_DETAIL_SUCCESS,
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAILED,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  UN_AUTH,
  UPDATE_ADDRESS_FAILED,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_NEWS_LETTER_FAILED,
  UPDATE_NEWS_LETTER_REQUEST,
  UPDATE_NEWS_LETTER_SUCCESS,
} from "./AuthType";

export function registerResponse(state = {}, action: any) {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return { ...action.payload };
    case REGISTER_REQUEST:
      return { ...action.payload };
    case REGISTER_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function loginResponse(state = {}, action: any) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...action.payload };
    case LOGIN_SUCCESS:
      return { ...action.payload };
    case LOGOUT_SUCCESS:
      return { ...action.payload };
    case LOGIN_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function userResponse(state = {}, action: any) {
  switch (action.type) {
    case GET_USER_DETAIL_REQUEST:
      return { ...action.payload };
    case GET_USER_DETAIL_SUCCESS:
      return { ...action.payload };
    case GET_USER_DETAIL_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function changeAccount(state = {}, action: any) {
  switch (action.type) {
    case CHANGE_ACCOUNT_REQUEST:
      return { ...action.payload };
    case CHANGE_ACCOUNT_SUCCESS:
      return { ...action.payload };
    case CHANGE_ACCOUNT_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function updateNewsLetter(state = {}, action: any) {
  switch (action.type) {
    case UPDATE_NEWS_LETTER_REQUEST:
      return { ...action.payload };
    case UPDATE_NEWS_LETTER_SUCCESS:
      return { ...action.payload };
    case UPDATE_NEWS_LETTER_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function resetPassword(state = {}, action: any) {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { ...action.payload };
    case RESET_PASSWORD_SUCCESS:
      return { ...action.payload };
    case RESET_PASSWORD_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function forgotPassword(state = {}, action: any) {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return { ...action.payload };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...action.payload };
    case FORGOT_PASSWORD_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function addressResponse(state = {}, action: any) {
  switch (action.type) {
    case GET_ADDRESS_SUCCESS:
      return { ...action.payload };
    case UPDATE_ADDRESS_SUCCESS:
      return { ...action.payload };
    case UPDATE_ADDRESS_FAILED:
      return { ...action.payload };
    case CREATE_ADDRESS_SUCCESS:
      return { ...action.payload };
    case DELETE_ADDRESS_SUCCESS:
      return { ...action.payload };
    case DELETE_ADDRESS_FAILED:
      return { ...action.payload };
    default:
      return state;
  }
}

export function unAuthResponse(state = {}, action: any) {
  switch (action.type) {
    case UN_AUTH:
      return action.payload;
    default:
      return state;
  }
}
