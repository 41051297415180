/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import CustomButton from "../Common/CustomButton/CustomButton";
import { useEffect, useState } from "react";
import GuardsmanPopup from "../ProductDetails/GuardsmanPopup/GuardsmanPopup";
import info from "../../Assets/Images/info.svg";
import SelectGroups from "../Common/SelectGroups/SelectGroups";
import { Link } from "react-router-dom";
import CheckboxGroups from "../Common/CheckboxGroups/CheckboxGroups";
import FreeSamplesPopup from "../FreeSamplesPopup/FreeSamplesPopup";
import { useLocation } from "react-router";
import {
  GroupedColors,
  IConfigurationOption,
  IConfigurationOptionValues,
  IProductDetail,
  IVariant,
  SelectedProductOption,
} from "../../Interfaces/ProductDetail.interface";
import { useDispatch, useSelector } from "react-redux";
import {
  getCMSContent,
  getProductDetailByName,
  getProductDetailFabricInformation,
} from "../../Redux/Pages/Products/ProductActions";
import {
  GET_PRODUCT_CMS_BLOCK_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_FABRIC_INFORMATION_SUCCESS,
} from "../../Redux/Pages/Products/ProductTypes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FINANCE } from "../../Constants/Route";
import FabricInfoPopup from "./FabricInfoPopup/FabricInfoPopup";
import PanelCMSBlock from "./PanelCMSBlock";
import ProductImageSlider from "./ProductImageSlider";
import ShareDropdown from "./ShareDropdown";
import ProductColorbox from "./ProductColorbox";
import SidePanelButtons from "./SidePanelButtons";
import MobileProductCMSContent from "./MobileProductCMSContent";
import WebProductCMSContent from "./WebProductCMSContent";
import RelatedProducts from "./RelatedProducts";
import {
  ADD_PRODUCT_TO_CART_FAILED,
  ADD_PRODUCT_TO_CART_SUCCESS,
  ADD_TO_WISHLIST_SUCCESS,
  GET_WISHLIST_SUCCESS,
  REMOVE_FROM_WISHLIST_SUCCESS,
} from "../../Redux/Pages/CartPage/CartPageTypes";
import {
  addProductToCartItem,
  addToWishlist,
  getWishlist,
  removeFromWishlistItems,
} from "../../Redux/Pages/CartPage/CartPageActions";
import { isAuth } from "../../Utility/Utilities";
import ProductDetailSkl from "./ProductDetailSkl";

const ProductDetail = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const response: any = useSelector(
    (state: any) => state.productDetailResponse
  );

  const [data, setData] = useState<IProductDetail | null>();

  const wishlistResponse = useSelector((state: any) => state.wishlistResponse);

  const cartResponse = useSelector((state: any) => state.addProductToCart);

  const [loading, setLoading] = useState(true);

  const [cartLoading, setCartLoading] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState<IVariant[]>([]);

  const [filterOptions, setFilterOptions] = useState<any[]>([]);

  const [filteredProduct, setFilteredProduct] = useState<IProductDetail>();

  const [groupedColors, setGroupedColors] = useState<GroupedColors[]>([]);
  const [groupedColorsForMobile, setGroupColorsForMobile] = useState<
    GroupedColors[]
  >([]);
  const [sizes, setSizes] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<number>(-1);
  const [windowSize, setWindowSize] = useState(1366);
  const [isGuardianChecked, setGuardianChecked] = useState(false);
  const [colors, setColors] = useState<any>([
    { title: "Filter Colour", value: "0" },
  ]);
  const [fabrics, setFabrics] = useState([
    { title: "Filter Fabric", value: "0" },
  ]);
  const [wishlistListId, setWishlistId] = useState<string>("");
  const [cmsBlock, setCMSBlock] = useState("");
  const [fabricsInformation, setFabricsInformation] = useState("");
  const [selectByColorShow, setSelectByColorShow] = useState(false);
  const [freeSampleModalShow, setFreeSampleModalShow] = useState(false);
  const [fabricInfoPopupShow, setFabricInfoPopupShow] = useState(false);
  const [isCheckedColoredBox, setCheckedColoredBox] = useState({
    value_index: -1,
    value: false,
    label: "",
  });
  const INITIAL_PATH = [
    {
      path: "",
      label: "Home",
    },
  ];
  const [breadSc, setBreadcrumbs] = useState(INITIAL_PATH);
  const INITIAL_SELCETED_VALUE = { colorKey: "0", fabricKey: "0" };
  const [colorSelectValue, setColorSelectValue] = useState<any>(
    INITIAL_SELCETED_VALUE
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getCMSContent(`"product_cms_default"`));
    dispatch(getProductDetailFabricInformation());
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, [window.innerWidth]);

  useEffect(() => {
    if (location && location.pathname) {
      window.scrollTo(0, 0);
      setLoading(true);
      setBreadcrumbs(INITIAL_PATH);
      const productName = location.pathname.split("/")[1];
      dispatch(getProductDetailByName(productName.split(".html")[0]));
    }
  }, [location]);

  useEffect(() => {
    if (response && response.data) {
      console.log(" RES ", response);
      if (response.type === GET_PRODUCT_DETAIL_SUCCESS) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        const currentBreadScrum: any[] = Object.assign([], breadSc);
        currentBreadScrum.push({
          label: response.data?.name,
          path: "",
        });
        setBreadcrumbs(currentBreadScrum);
        response.data?.configurable_options?.sort(
          (a: { position: number }, b: { position: number }) =>
            a.position - b.position
        );
        if (response.data.cms_block) {
          setCMSBlock(response.data.cms_block);
        }
        setFilteredProduct(response.data);
        if (response.data && response.data.configurable_options) {
          const sizeData = response.data.configurable_options.find(
            (d: { attribute_code: string }) => d.attribute_code === "size"
          );
          if (sizeData) {
            const size = [
              { value: null, title: "Choose an option" },
              ...sizeData.values.map((v: { value_index: any; label: any }) => {
                return {
                  value: v.value_index,
                  title: v.label,
                };
              }),
            ];
            setSizes(size);
          } else {
            setSizes([]);
          }
        } else if (response.data && !response.data.configurable_options) {
          setSelectedProducts(response.data);
        }
        setData(response.data);
        if (isAuth()) {
          dispatch(getWishlist(""));
        }
        const colorAttributeOptions = getAttributeValues(
          response.data?.configurable_options,
          "color"
        );
        if (colorAttributeOptions) {
          const colours = Array.from(
            new Set(
              colorAttributeOptions
                .filter((c) => c?.swatch_data?.color_group)
                .map((c) => {
                  return {
                    title: c.swatch_data.color_group,
                    value: "" + c.swatch_data.group,
                  };
                })
            )
          );
          const arr = Array.from(new Set(colours.map((c) => c.title))).map(
            (olKey) => {
              const value = colours.find((colmns) => colmns.title === olKey);
              return { title: value?.title, value: olKey };
            }
          );
          setColors([...colors, ...arr]);
          const addFabr = Array.from(
            new Set(colorAttributeOptions?.map((c) => c.swatch_data?.group))
          )
            .filter((c) => c)
            .map((c) => {
              return {
                title: c,
                value: c,
              };
            });
          setFabrics([...fabrics, ...addFabr]);
          const colorSections = getGroupedColors(colorAttributeOptions); // MUST KEEP
          setGroupedColors(colorSections);
        }
      } else if (response.type === GET_PRODUCT_CMS_BLOCK_SUCCESS) {
        setCMSBlock(response.data);
      } else if (response.type === GET_PRODUCT_FABRIC_INFORMATION_SUCCESS) {
        setFabricsInformation(response.data);
      } else {
        setData(null);
        setLoading(false);
      }
    }
  }, [response]);

  useEffect(() => {
    if (
      filterOptions.length === filteredProduct?.configurable_options?.length
    ) {
      const media_gallery =
        selectedProducts[0]?.product.media_gallery.length > 0
          ? [
              ...selectedProducts[0]?.product.media_gallery,
              ...filteredProduct?.media_gallery,
            ]
          : filteredProduct?.media_gallery;
      setFilteredProduct({
        ...selectedProducts[0]?.product,
        related_products: filteredProduct.related_products,
        description: filteredProduct.description,
        dimensions: filteredProduct.dimensions,
        whyus: filteredProduct.whyus,
        delivery: filteredProduct.delivery,
        media_gallery,
        configurable_options: filteredProduct?.configurable_options,
      });
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (data) {
      setLatestProduct(0, data.variants);
      setLoading(false);
    }
  }, [filterOptions]);

  useEffect(() => {
    if (filteredProduct) {
      setColorSelectValue(INITIAL_SELCETED_VALUE);
    }
  }, [filteredProduct]);

  useEffect(() => {
    if (wishlistResponse && wishlistResponse.data) {
      setLoading(false);
      if (wishlistResponse.type === GET_WISHLIST_SUCCESS && data) {
        const wishlist = wishlistResponse.data.items_v2?.items.find(
          (item: any) => item.product?.sku === data.sku
        );
        setWishlistId(wishlist?.id);
      } else if (
        wishlistResponse &&
        wishlistResponse.type === ADD_TO_WISHLIST_SUCCESS &&
        data
      ) {
        const wishlist = wishlistResponse.data.items_v2.items.find(
          (item: { product: { sku: string } }) => item.product.sku === data.sku
        );
        setWishlistId(wishlist?.id);
      } else if (
        wishlistResponse &&
        response.type === REMOVE_FROM_WISHLIST_SUCCESS
      ) {
        setWishlistId("");
      }
    }
  }, [wishlistResponse]);

  useEffect(() => {
    if (
      cartResponse &&
      (cartResponse.type === ADD_PRODUCT_TO_CART_SUCCESS ||
        cartResponse.type === ADD_PRODUCT_TO_CART_FAILED)
    ) {
      setCartLoading(false);
    }
  }, [cartResponse]);

  useEffect(() => {
    if (filteredProduct?.configurable_options) {
      let filteredColors: IConfigurationOptionValues[] = [];
      const configurationColors = getAttributeValues(
        filteredProduct?.configurable_options,
        "color"
      );
      if (
        colorSelectValue.fabricKey === "0" &&
        colorSelectValue.colorKey !== "0" &&
        configurationColors
      ) {
        filteredColors = configurationColors.filter(
          (option) =>
            option.swatch_data.color_group === colorSelectValue.colorKey
        );
      } else if (
        colorSelectValue.fabricKey !== "0" &&
        colorSelectValue.colorKey !== "0" &&
        configurationColors
      ) {
        filteredColors = configurationColors.filter(
          (option) =>
            option.swatch_data.color_group === colorSelectValue.colorKey &&
            option.swatch_data.group === colorSelectValue.fabricKey
        );
      } else if (
        colorSelectValue.colorKey === "0" &&
        colorSelectValue.fabricKey !== "0" &&
        configurationColors
      ) {
        filteredColors = configurationColors.filter(
          (option) => option.swatch_data.group === colorSelectValue.fabricKey
        );
      } else if (
        configurationColors &&
        colorSelectValue.fabricKey === "0" &&
        colorSelectValue.colorKey === "0"
      ) {
        filteredColors = configurationColors;
      }
      setGroupColorsForMobile([
        {
          key: "",
          value: filteredColors.map(
            (c) =>
              new SelectedProductOption(
                c.value_index,
                c.swatch_data.thumbnail,
                c.label
              )
          ),
        },
      ]);
    }
  }, [colorSelectValue]);

  const getGroupedColors = (
    colors: IConfigurationOptionValues[]
  ): GroupedColors[] => {
    const groupNames: string[] = Array.from(
      new Set(colors.map((c) => c.swatch_data?.group))
    ).filter((c) => c);
    return groupNames.map((c: string) => {
      return new GroupedColors(
        c,
        colors
          .filter((cc) => cc.swatch_data?.group === c)
          .map(
            (cc) =>
              new SelectedProductOption(
                cc.value_index,
                cc.swatch_data?.thumbnail,
                cc.label
              )
          )
      );
    });
  };

  const onUserSelectFromColorOptions = (selectedKey: string, e: any) => {
    setColorSelectValue({ ...colorSelectValue, [selectedKey]: e.target.value });
  };

  const onUserSelect = ({ isChecked, label, value_index, type }: any) => {
    if (type === "color") {
      setCheckedColoredBox({
        value_index: value_index,
        value: isChecked,
        label,
      });
    } else if (type === "size") {
      setSelectedValue(value_index);
    }
    let currentFilterOptions: any[] = Object.assign([], filterOptions);
    const filterOptionIndex = filterOptions.findIndex((f) => f.type === type);
    if (filterOptionIndex > -1) {
      currentFilterOptions[filterOptionIndex].value_index = value_index;
    } else {
      currentFilterOptions.push({ value_index, type });
    }
    setFilterOptions(currentFilterOptions);
  };

  const getAttributeValues = (
    configurable_options: IConfigurationOption[],
    attribute_code: string
  ): IConfigurationOptionValues[] | undefined => {
    return configurable_options?.find(
      (o) => o.attribute_code === attribute_code
    )?.values;
  };

  const setLatestProduct = async (i: number, variants: IVariant[]) => {
    let result: any[] = [];
    if (i === filterOptions.length) {
      setSelectedProducts(variants);
      return;
    }
    if (variants && variants.length > 0) {
      const option = filterOptions[i];
      for (let index = 0; index < variants.length; index++) {
        result =
          variants[index].attributes.filter(
            (at) =>
              at.code === option.type && at.value_index === option.value_index
          ).length > 0
            ? [...result, variants[index]]
            : [...result];
      }
      setLatestProduct(i + 1, result);
    }
  };

  const onAddToWishlist = (e: any) => {
    if (e.target.checked) {
      dispatch(
        addToWishlist({
          sku: data?.sku,
          quantity: 1,
        })
      );
    } else {
      dispatch(removeFromWishlistItems({ wishlistItemId: wishlistListId }));
    }
  };

  const addProductToCart = () => {
    setCartLoading(true);
    const parent_sku = data?.sku;
    const child_sku =
      selectedProducts && selectedProducts.length > 0
        ? selectedProducts[0]?.product.sku
        : null;
    const sku = isGuardianChecked
      ? filteredProduct?.related_products[0]?.sku
      : null;
    if (parent_sku && child_sku) {
      // configuration options
      dispatch(
        addProductToCartItem({
          parent_sku,
          child_sku,
          sku,
        })
      );
    } else if (!parent_sku || !child_sku) {
      // showToast(WARNING, "No data found");
      setLoading(false);
    }
    if (data?.__typename === "SimpleProduct") {
      // only simple products
      dispatch(
        addProductToCartItem({
          simple_sku: data.sku,
          sku,
        })
      );
    }
  };

  return !loading ? (
    <div className="product-detail-wrap">
      <div className="custom-container top">
        <Breadcrumbs value={breadSc} />
        <div className="product-detail-main">
          <div className="left-media">
            {cmsBlock ? <PanelCMSBlock cms_block={cmsBlock} /> : null}
            <div className="sample-productdetails">
              {filteredProduct && filteredProduct.media_gallery ? (
                <ProductImageSlider
                  media_gallery={filteredProduct?.media_gallery}
                />
              ) : null}
            </div>
          </div>
          <div className="product-right-txt">
            <h6 className="caps-on">{filteredProduct?.name}</h6>
            <div className="product-social">
              {filteredProduct && filteredProduct.short_description ? (
                <p
                  className="bs"
                  dangerouslySetInnerHTML={{
                    __html: filteredProduct.short_description.html,
                  }}
                />
              ) : null}
              <div className="extra-action">
                <div className="heart-wishlist">
                  <input
                    type="checkbox"
                    checked={
                      wishlistListId !== undefined &&
                      wishlistListId !== null &&
                      wishlistListId !== ""
                    }
                    onChange={onAddToWishlist}
                  />
                </div>
                <ShareDropdown />
              </div>
            </div>
            <div className="mob-filter">
              <SelectGroups
                values={colors}
                onSelect={(e: any) =>
                  onUserSelectFromColorOptions("colorKey", e)
                }
                label=""
              ></SelectGroups>
              <SelectGroups
                onSelect={(e: any) =>
                  onUserSelectFromColorOptions("fabricKey", e)
                }
                values={fabrics}
                label=""
              ></SelectGroups>
            </div>
            <div className="product-txt-box">
              {filteredProduct?.configurable_options?.map((d) => {
                return d.attribute_code === "color" ? (
                  <>
                    {windowSize < 768 ? (
                      <div className="product-swatches hide-767">
                        <div className="product-swatches">
                          <div className="sample-main">
                            <div className="sample-checkbox-mob">
                              {groupedColorsForMobile[0].value.length !== 0 ? (
                                <>
                                  {groupedColorsForMobile &&
                                  groupedColorsForMobile[0].value
                                    ? groupedColorsForMobile[0]?.value?.map(
                                        (gCV: SelectedProductOption) => (
                                          <ProductColorbox
                                            key={gCV.label}
                                            {...gCV}
                                            isChecked={
                                              gCV.value_index ===
                                              isCheckedColoredBox.value_index
                                                ? isCheckedColoredBox.value
                                                : false
                                            }
                                            onClicked={onUserSelect}
                                          />
                                        )
                                      )
                                    : null}
                                </>
                              ) : (
                                <p className="no-combi-available tm">
                                  The selected product combination is currently
                                  unavailable.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="product-swatches">
                        {groupedColors?.map((gC) => {
                          return (
                            <div key={gC.key} className="sample-wrap">
                              <p className="ll box-title">{gC.key}</p>
                              <div className="sample-main">
                                <div className="sample-checkbox">
                                  {gC.value?.map(
                                    (gCV: SelectedProductOption) => (
                                      <ProductColorbox
                                        key={gCV.label}
                                        {...gCV}
                                        isChecked={
                                          gCV.value_index ===
                                          isCheckedColoredBox.value_index
                                            ? isCheckedColoredBox.value
                                            : false
                                        }
                                        onClicked={onUserSelect}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div className="select-box">
                      {filteredProduct &&
                      getAttributeValues(
                        filteredProduct.configurable_options,
                        "color"
                      ) ? (
                        <div className="apply-color">
                          <p className="bm color-text">
                            {isCheckedColoredBox &&
                            isCheckedColoredBox.label !== ""
                              ? isCheckedColoredBox.label
                              : "Select by colour or fabric above"}
                          </p>
                          <button onClick={() => setFabricInfoPopupShow(true)}>
                            <img src={info} alt={""} />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : d.attribute_code === "size" && sizes && sizes.length > 0 ? (
                  <div className="select-size">
                    <SelectGroups
                      values={sizes}
                      label=""
                      onSelect={(e: any) => {
                        onUserSelect({
                          isChecked: false,
                          label: "",
                          value_index: Number(e.target.value),
                          type: "size",
                        });
                      }}
                    />
                  </div>
                ) : d.attribute_code === "option" &&
                  filteredProduct &&
                  filteredProduct.configurable_options ? (
                  <div className="select-box">
                    <div className="sidepanel-tabs">
                      {getAttributeValues(
                        filteredProduct?.configurable_options,
                        "option"
                      )?.map((c) => {
                        return (
                          <SidePanelButtons
                            key={c.label}
                            label={c.label}
                            value_index={c.value_index}
                            onSelectPanel={(e: number) => {
                              onUserSelect({
                                isChecked: false,
                                label: "",
                                value_index: Number(e),
                                type: "option",
                              });
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : null;
              })}
              <div className="availibility">
                {filteredProduct && filteredProduct.availability_message ? (
                  <>
                    <p className="ll">Availability</p>
                    <p className="bm">{filteredProduct.availability_message}</p>
                  </>
                ) : data?.variants?.length === 0 ? (
                  <>
                    <p className="ll">Availability</p>
                    <p className="text-danger">{"OUT OF STOCK"}</p>
                  </>
                ) : null}
              </div>
              <div className="product-price">
                {filteredProduct && filteredProduct.price_range ? (
                  <div className="price">
                    <p className="tm">
                      £
                      {
                        filteredProduct.price_range.minimum_price.final_price
                          .value
                      }
                    </p>
                    {filteredProduct.price_range.minimum_price.final_price
                      .value !==
                    filteredProduct.price_range.minimum_price.regular_price
                      .value ? (
                      <p className="tm specialprice">
                        £
                        {
                          filteredProduct.price_range.minimum_price
                            .regular_price.value
                        }
                      </p>
                    ) : null}
                  </div>
                ) : null}
                <Link to={FINANCE} className="bm finance" target={"_blank"}>
                  0% Finance Available
                </Link>
              </div>
              {filteredProduct?.related_products?.map((rP) => {
                return (
                  <div key={rP.name} className="protection-box">
                    <div className="protect-txt">
                      <CheckboxGroups
                        onChange={(e: any) =>
                          setGuardianChecked(e.target.checked)
                        }
                        id="guardsman"
                      >
                        {rP.name}
                      </CheckboxGroups>
                      <img
                        src={info}
                        alt={""}
                        onClick={() => setSelectByColorShow(true)}
                      />
                    </div>
                    <p className="bm">
                      £
                      {rP.price_range?.minimum_price?.final_price?.value || 0.0}
                    </p>
                  </div>
                );
              })}
              <div className="action-btn">
                <CustomButton
                  bg={"fill"}
                  isLoading={cartLoading}
                  disabled={
                    filterOptions.length !==
                      (filteredProduct?.configurable_options?.length || 0) ||
                    isNaN(selectedValue) ||
                    data?.variants?.length === 0 ||
                    data?.stock_status !== "IN_STOCK"
                  }
                  onClick={addProductToCart}
                >
                  Add to Cart
                </CustomButton>
                <CustomButton
                  bg={"outline-btn"}
                  onClick={() => setFreeSampleModalShow(true)}
                >
                  Order a Free Fabric Sample
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      {filteredProduct &&
      filteredProduct?.description &&
      filteredProduct.description.html ? (
        windowSize < 768 ? (
          <MobileProductCMSContent
            description={filteredProduct?.description.html}
            dimensions={filteredProduct.dimensions?.html}
            delivery={filteredProduct.delivery?.html}
            whyus={filteredProduct.whyus?.html}
            setFreesampleModalShow={setFreeSampleModalShow}
          />
        ) : (
          <WebProductCMSContent
            description={filteredProduct?.description.html}
            dimensions={filteredProduct.dimensions?.html}
            delivery={filteredProduct.delivery?.html}
            whyus={filteredProduct.whyus?.html}
            setFreesampleModalShow={setFreeSampleModalShow}
          />
        )
      ) : null}
      {filteredProduct &&
      filteredProduct.upsell_products &&
      filteredProduct.upsell_products.length > 0 ? (
        <RelatedProducts data={filteredProduct?.upsell_products} />
      ) : null}
      <GuardsmanPopup
        show={selectByColorShow}
        onHide={() => setSelectByColorShow(false)}
      />
      <FabricInfoPopup
        show={fabricInfoPopupShow}
        content={fabricsInformation}
        onHide={() => setFabricInfoPopupShow(false)}
      />
      <FreeSamplesPopup
        show={freeSampleModalShow}
        onHide={() => setFreeSampleModalShow(false)}
      />
    </div>
  ) : (
    <ProductDetailSkl />
  );
};

export default ProductDetail;
