/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import MainNavigation from "../NavigationMenu/MainNavigation";
import heart from "../../Assets/Images/heart.svg";
import cart from "../../Assets/Images/cart.svg";
import search from "../../Assets/Images/search.svg";
import MiniCart from "../Home/MiniCart/MiniCart";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CHECKOUT,
  MY_WISHLIST,
  ORDER_SUCCESS,
  SEARCH_CATEGORY,
} from "../../Constants/Route";
import {
  DELETE_CART_SUCCESS,
  GET_CART_PAGE_SUCCESS,
} from "../../Redux/Pages/CartPage/CartPageTypes";
import { LOGOUT_SUCCESS } from "../../Redux/Auth/AuthType";
import { GET_CMS_BLOCK_SUCCESS } from "../../Redux/Pages/CMSBlock/CMSBlockTypes";
import {
  SEARCH_FUNCTIONALITY_SUCCESS,
  STORE_CONFIG_SUCCESS,
} from "../../Redux/General/GeneralType";
import { Dropdown } from "react-bootstrap";
import { useLocation } from "react-router";

const WebHeader = () => {
  const [isActive, setActive] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isCartActive, setCartActive] = useState(false);

  const [data, setData] = useState<any>({});

  const loginResponse = useSelector((state: any) => state.loginResponse);

  const cartData = useSelector((state: any) => state.getCartResponse);

  const deleteCartResponse = useSelector(
    (state: any) => state.deleteCartItemResponse
  );

  const headerResponse = useSelector((state: any) => state.cmsBlockResponse);

  const storeConResponse = useSelector((state: any) => state.storeConfigInfo);

  const [storeConfigdata, setStoreConfigData] = useState<any>({});

  const [searchCategory, setSearchCategory] = useState("");

  const searchResponse = useSelector(
    (state: any) => state.searchCategoryResponse
  );

  useEffect(() => {
    if (storeConResponse && storeConResponse.type === STORE_CONFIG_SUCCESS) {
      setStoreConfigData(storeConResponse?.data?.storeConfig);
    }
  }, [storeConResponse]);

  useEffect(() => {
    if (headerResponse && headerResponse.type === GET_CMS_BLOCK_SUCCESS) {
      setData(
        headerResponse.data?.items?.find(
          (d: { identifier: string }) => d.identifier === "header-contact-info"
        )
      );
    }
  }, [headerResponse]);

  useEffect(() => {
    if (loginResponse && loginResponse.type === LOGOUT_SUCCESS) {
      setCartActive(false);
      dispatch({ type: GET_CART_PAGE_SUCCESS, payload: null });
    }
  }, [loginResponse]);

  useEffect(() => {
    if (deleteCartResponse) {
      if (
        deleteCartResponse.type === DELETE_CART_SUCCESS &&
        cartData.data.items
      ) {
        const currentState = Object.assign({}, cartData.data);
        currentState.items.length = cartData.data.items.length - 1;
        setData(currentState);
        setCartActive(false);
      }
    }
  }, [deleteCartResponse]);

  useEffect(() => {
    if (
      searchResponse &&
      searchResponse.type === SEARCH_FUNCTIONALITY_SUCCESS
    ) {
      setSearchCategory("");
      setActive(false);
    }
  }, [searchResponse]);

  const searchCat = (e: any) => {
    if (e.key === "Enter") {
      navigate({
        pathname: SEARCH_CATEGORY,
        search: searchCategory,
      });
    }
  };

  const [backStatus2, setBackStatus2] = useState(false);

  const toggleBack2 = () => {
    setBackStatus2(!backStatus2);
  };

  const [currentLocation, setCurrentLocation] = useState(String);
  const navigate1: any = useLocation();

  useEffect(() => {
    setCurrentLocation(navigate1.pathname);
  }, [navigate]);

  const urls = [CHECKOUT, ORDER_SUCCESS];

  return (
    <>
      <div
        className={
          urls.includes(currentLocation)
            ? "header-content show-768 in-checkout"
            : "header-content show-768"
        }
      >
        <div className="custom-container">
          <div className="header-web">
            <header className="header-content">
              {data && (
                <div
                  className="left-info"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                ></div>
              )}
              <div className="logo">
                {storeConfigdata && storeConfigdata?.header_logo_src ? (
                  <Link to={""} title="Living It Up">
                    <img
                      src={
                        storeConfigdata.base_media_url +
                        "logo/" +
                        storeConfigdata?.header_logo_src
                      }
                      alt="Web Logo"
                    ></img>
                  </Link>
                ) : (
                  <div className="static-height" />
                )}
              </div>
              <div className="right-info">
                <span
                  title="Search"
                  className="right-link search"
                  onClick={() => setActive(!isActive)}
                >
                  <img src={search} alt="Search Bar"></img>
                </span>
                <Link
                  to={MY_WISHLIST}
                  title="Wishlist"
                  className="right-link heart"
                >
                  <img src={heart} alt="Wishlist"></img>
                </Link>
                <div className="minicart lm">
                  <Dropdown onToggle={() => toggleBack2()} show={backStatus2}>
                    <Dropdown.Toggle onClick={() => toggleBack2()}>
                      <img src={cart} alt="Cart" className="cart" />
                      <span className="lm">
                        {cartData && cartData?.data && cartData?.data?.items
                          ? cartData?.data?.items.length
                            ? cartData?.data?.items.length
                            : null
                          : null}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        <div
                          className={
                            isCartActive
                              ? "minicart-wrapper open"
                              : "minicart-wrapper open"
                          }
                        >
                          <MiniCart toggleBack2={toggleBack2} />
                        </div>
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
      <MainNavigation />
      <div className={isActive ? "search-box open" : "search-box close"}>
        <div className="custom-container">
          <div className="search-grp">
            <input
              type="search"
              value={searchCategory}
              onChange={(e: any) => setSearchCategory(e.target.value)}
              onKeyDown={searchCat}
              autoFocus={true}
            />
            <button
              className="close-search"
              onClick={() => setActive(!isActive)}
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebHeader;
