import { getOrdersuccess } from "../../../Services/graphQL.service";
import { ORDERS_SUCCESS_FAILED, ORDERS_SUCCESS_REQUEST, ORDERS_SUCCESS_SUCCESS } from "./OrdersTypes"

export const getOrdersSuccessAction = (oid: string) => {
  return (async (dispatch: any) => {
    dispatch({ type: ORDERS_SUCCESS_REQUEST })
    try {
      const { data: response } = await getOrdersuccess(oid);
      const data = response?.data;
      if (data) {
        dispatch({
          type: ORDERS_SUCCESS_SUCCESS,
          payload: {
            type: ORDERS_SUCCESS_SUCCESS,
            data
          },
        })
      }
      else {
        dispatch({
          type: ORDERS_SUCCESS_FAILED,
          payload: {
            type: ORDERS_SUCCESS_FAILED,
            message: response.errors[0]?.message
          },
        })
      }
    } catch (error) {
      dispatch({
        type: ORDERS_SUCCESS_FAILED,
        payload: { type: ORDERS_SUCCESS_FAILED, message: error },
      })
    }
  })
}