const SelectGroups = (props: {
  values: { title: string; value: string }[];
  label: string;
  selectedValue?: string;
  onSelect?: any;
}) => {
  return (
    <div className="select-group">
      <label className="lm">{props.label}</label>
      <select defaultValue={props.values[0]?.value} onChange={props.onSelect}>
        {props.values.map(
          (item: { title: string; value: string }, index: number) => (
            <option
              key={index}
              defaultValue={
                props.selectedValue ? props.selectedValue : item.value
              }
              value={item.value}
            >
              {item.title}
            </option>
          )
        )}
      </select>
    </div>
  );
};

export default SelectGroups;
