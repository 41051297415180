/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  changeEmailAction,
  getUserDetail,
} from "../../../Redux/Auth/AuthAction";
import {
  CHANGE_ACCOUNT_FAILED,
  CHANGE_ACCOUNT_SUCCESS,
} from "../../../Redux/Auth/AuthType";
import validate from "../../../Utility/Validation";
import CheckboxGroups from "../../Common/CheckboxGroups/CheckboxGroups";
import CustomButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import PasswordGroups from "../../Common/PasswordGroups/PasswordGroups";
import Sidebar from "../Sidebar/Sidebar";

const MyDetails = () => {
  const data = useSelector((state: any) => state?.userResponse);

  const changeAccount = useSelector((state: any) => state?.changeAccount);

  const [error, setError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);

  const [userData, setUserData] = useState<any>({
    firstname: { value: null, validation: [], errors: [] },
    lastname: { value: null, validation: [], errors: [] },
    email: {
      value: null,
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    password: {
      value: "",
      validation: [
        "password",
        "required",
        "min:8",
        "lower",
        "upper",
        "digit",
        "special",
      ],
      errors: [
        null,
        "Password is required.",
        "Should have minimum 8 character",
        "Should have lowercase",
        "Should have uppercase",
        "Should have numbers",
        "Should have special characters",
      ],
    },
    cpassword: {
      value: "",
      validation: [
        "password",
        "required",
        "min:8",
        "lower",
        "upper",
        "digit",
        "special",
      ],
      errors: [
        null,
        "Password is required.",
        "Should have minimum 8 character",
        "Should have lowercase",
        "Should have uppercase",
        "Should have numbers",
        "Should have special characters",
      ],
    },
  });

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [isEmailChecked, setIsEmailChecked] = useState(false);

  const handleOnEmailChange = () => {
    setIsEmailChecked(!isEmailChecked);
  };

  const [isPwdChecked, setIsPwdChecked] = useState(false);

  const handleOnPasswordChange = () => {
    setIsPwdChecked(!isPwdChecked);
  };

  const [centerText, setcenterText] = useState(false);
  const onLeftMenuClick = (leftck: any) => {
    setcenterText(true);
  };

  useEffect(() => {
    if (data && data.data) {
      const obj: any = Object.assign({}, userData);
      Object.keys(data.data).forEach((d) => {
        obj[d] = { ...obj[d], value: data.data[d] };
      });
      setUserData(obj);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  useEffect(() => {
    if (changeAccount) {
      setLoading(false);
      if (changeAccount.type === CHANGE_ACCOUNT_SUCCESS) {
        setData("password", "");
        setData("cpassword", "");
        setIsFormValidated(false);
      } else if (changeAccount.type === CHANGE_ACCOUNT_FAILED) {
        // error
        // showToast(ERROR, changeAccount.message);
      }
    }
  }, [changeAccount]);

  const setData = (field: string, value: string) => {
    let loginDetails = Object.assign({}, userData);
    loginDetails[field].value = value;
    setUserData({ ...loginDetails });
  };

  const onSubmit = () => {
    setLoading(true);
    const dat = Object.assign({}, userData);
    if (isEmailChecked && !isPwdChecked) {
      delete dat.cpassword;
    }
    if (!isEmailChecked && !isPwdChecked) {
      delete dat.password;
      delete dat.cpassword;
    }
    if (validate(dat).isValidated === true) {
      const obj: any = {};
      Object.keys(dat).forEach((d) => {
        obj[d] = dat[d].value;
      });
      dispatch(changeEmailAction(obj));
    } else {
      setTimeout(() => setLoading(false), 3000);
      setError({ ...validate(userData)?.errors });
      setIsFormValidated(true);
    }
  };

  return (
    <div className="account-container">
      <div className="account-wrap sidebar-open">
        <Sidebar
          onLeftMenuClick={(leftck: any) => onLeftMenuClick(leftck)}
        ></Sidebar>
        <div
          className={
            centerText
              ? "acc-rightwrap details-open"
              : "acc-rightwrap details-close"
          }
        >
          <div className="mydetail-wrap">
            <div className="ll hide-991">
              <Link
                to=""
                onClick={() => setcenterText(false)}
                className="acc-backbtn ll"
              >
                My Account
              </Link>
            </div>
            <h6 className="caps-on">My Details</h6>
            <div className="detail-section">
              <div className="acc-info">
                <label className="tm step-title">Account Information</label>
                <div className="fields-set">
                  <InputGroups
                    label={"First Name*"}
                    value={userData?.firstname?.value}
                    error={
                      isFormValidated && error["firstname"]
                        ? error["firstname"]
                        : null
                    }
                    onChange={(e: any) => setData("firstname", e.target.value)}
                  />
                  <InputGroups
                    label={"Last Name*"}
                    value={userData?.lastname?.value}
                    error={
                      isFormValidated && error["lastname"]
                        ? error["lastname"]
                        : null
                    }
                    onChange={(e: any) => setData("lastname", e.target.value)}
                  />
                  <CheckboxGroups
                    id={"tg-email"}
                    defaultChecked={isEmailChecked}
                    onChange={handleOnEmailChange}
                  >
                    Change Email
                  </CheckboxGroups>
                  <CheckboxGroups
                    id={"tg-pwd"}
                    defaultChecked={isPwdChecked}
                    onChange={handleOnPasswordChange}
                  >
                    Change Password
                  </CheckboxGroups>
                </div>
              </div>
              <div className="toggle-info">
                <label
                  className={
                    isEmailChecked || isPwdChecked
                      ? "tm step-title"
                      : "tm step-title hide"
                  }
                >
                  {isEmailChecked && !isPwdChecked
                    ? "Change Email"
                    : isEmailChecked && isPwdChecked
                    ? "Change Email And Password"
                    : "Change Password"}
                </label>
                <div className="fields-set">
                  <div className={isEmailChecked ? "field" : "field hide"}>
                    <InputGroups
                      error={
                        isFormValidated && error["email"]
                          ? error["email"]
                          : null
                      }
                      label={"Email Address*"}
                      onChange={(e: any) => setData("email", e.target.value)}
                      value={userData?.email?.value}
                    />
                  </div>
                  <div
                    className={
                      isEmailChecked || isPwdChecked ? "field" : "field hide"
                    }
                  >
                    <PasswordGroups
                      value={userData?.password?.value}
                      label={"Current Password*"}
                      error={
                        isFormValidated && error["password"]
                          ? error["password"]
                          : null
                      }
                      onChange={(e: any) => setData("password", e)}
                    />
                  </div>
                  <div className={isPwdChecked ? "field" : "field hide"}>
                    <PasswordGroups
                      value={userData?.cpassword?.value}
                      error={
                        isFormValidated && error["cpassword"]
                          ? error["cpassword"]
                          : null
                      }
                      label={"New Password*"}
                      onChange={(e: any) => setData("cpassword", e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="action-bar">
              <CustomButton
                isLoading={loading}
                onClick={() => onSubmit()}
                bg={"fill"}
              >
                Save
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDetails;
