import Skeleton from "react-loading-skeleton";

const BlogListingSkl = () => {

  const blogList = [
    {
      blogTitle: "Upholstered beds in luxury fabrics",
    },
    {
      blogTitle: "Luxury upholstered beds: 4 fabric styles, over 50 shades",
    },
    {
      blogTitle: "Luxury velvet beds and sofas",
    },
    {
      blogTitle: "Beds with big headboards",
    },
    {
      blogTitle: "Bed in a box by living it up",
    },
    {
      blogTitle: "Our new beds for spring 2017",
    },
    {
      blogTitle: "Upholstered beds in luxury fabrics",
    },
    {
      blogTitle: "Luxury upholstered beds: 4 fabric styles, over 50 shades",
    },
    {
      blogTitle: "Luxury velvet beds and sofas",
    },
    {
      blogTitle: "Beds with big headboards",
    },
    {
      blogTitle: "Bed in a box by living it up",
    },
    {
      blogTitle: "Our new beds for spring 2017",
    },
    {
      blogTitle: "Upholstered beds in luxury fabrics",
    },
    {
      blogTitle: "Luxury upholstered beds: 4 fabric styles, over 50 shades",
    },
    {
      blogTitle: "Luxury velvet beds and sofas",
    },
    {
      blogTitle: "Beds with big headboards",
    },
    {
      blogTitle: "Bed in a box by living it up",
    },
    {
      blogTitle: "Our new beds for spring 2017",
    },
  ]

  return (
    <div className="blog-container skl">
      <div className="bread-c">
        <Skeleton />
      </div>
      <div className="bl-topinfo skl">
        <Skeleton />
      </div>
      <div className="bl-contentwrap skl">
        <ul className="list-items">
        {
          blogList.map((data, index) => (
            <li className="item" key={index}>
              <Skeleton />
              <div className="additional">
                <Skeleton />
              </div>
            </li>
          ))
        }
        </ul>
        <div className="list-sidebar skl">
          <div className="sear-blog box">
            <div className="box-title"><Skeleton /></div>
            <div className="sear-input">
              <Skeleton />
            </div>
            <div className="sear-action">
              <Skeleton />
            </div>
          </div>
          <div className="recent-posts box">
            <div className="box-title"><Skeleton /></div>
            <ul className="recent-list">
              <Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton />
            </ul>
          </div>
          <div className="categories box">
            <div className="box-title"><Skeleton /></div>
            <ul className="recent-list">
              <Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton />
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogListingSkl;