import { SUCCESS } from './../../../Constants/Labels';
import { getSessionItem, isAuth, setSessionItem, showToast } from "../../../Utility/Utilities"
import { CART_ID, GUEST_EMAIL } from "../../../Constants/LocalStorage"
import { CHECKOUT_ADDRESS_SUCCESS, CHECKOUT_DELIVERY_METHOD_REQUEST, CHECKOUT_DELIVERY_METHOD_SUCCESS, CHECKOUT_GET_DELIVERY_METHOD_FAILED, CHECKOUT_GET_DELIVERY_METHOD_REQUEST, CHECKOUT_GET_DELIVERY_METHOD_SUCCESS, CHECK_EMAIL_AVAILABLE_SUCCESS, GET_BRAINTREETOKEN_FAILED, GET_BRAINTREETOKEN_SUCCESS, ORDER_PLACED_FAILED, ORDER_PLACED_SUCCESS } from "./CheckoutTypes"
import { checkCustomerEmail, getBrainTreeToken, getDeliveryMethods, placeOrder, setBillingAddressOnCart, setCheckoutAddress as setShippingAddresss, setCheckoutDelMethod, setGuestEmailAddress } from "../../../Services/graphQL.service"
import { ERROR } from "../../../Constants/Labels"
import { GET_CART_PAGE_SUCCESS } from "../CartPage/CartPageTypes"

export const setShippingAddress = (payload: any) => {
  return (async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID)
      if (payload.cartId) {
        if (payload.country) {
          payload.region = { region: payload.country.value }
        }
        const { data: response } = await setShippingAddresss(payload)
        if (!isAuth()) {
          payload.address = payload
          dispatch(
            setBillingAddress(payload)
          );
        }
        if (response.errors) {
          showToast(ERROR, response.errors[0].message)
        }
        else {
          showToast(SUCCESS, "The shipping address has set successfully")
          dispatch({
            type: CHECKOUT_ADDRESS_SUCCESS, payload: {
              type: CHECKOUT_ADDRESS_SUCCESS,
              data: response.data?.setShippingAddressesOnCart?.cart,
            }
          })
        }
      }
    }
    catch (error) {
      showToast(ERROR, error)
    }
  })
}

export const getCheckoutDelvMethod = () => {
  return (async (dispatch: any) => {
    dispatch({ type: CHECKOUT_GET_DELIVERY_METHOD_REQUEST })
    try {
      const cartId = getSessionItem(CART_ID)
      const { data: response } = await getDeliveryMethods(cartId);
      const data = response?.data;
      if (data) {
        dispatch({
          type: CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
          payload: {
            type: CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
            data
          }
        })
      }
      else {
        dispatch({
          type: CHECKOUT_GET_DELIVERY_METHOD_FAILED,
          payload: {
            type: CHECKOUT_GET_DELIVERY_METHOD_FAILED,
            message: response.errors[0]?.message
          }
        })
      }
    } catch (error) {
      dispatch({
        type: CHECKOUT_GET_DELIVERY_METHOD_FAILED,
        payload: { type: CHECKOUT_GET_DELIVERY_METHOD_FAILED, message: error },
      })
    }
  })
}

export const setCheckoutDeliveryMethod = (payload: any) => {
  return (async (dispatch: any) => {
    dispatch({ type: CHECKOUT_DELIVERY_METHOD_REQUEST })
    try {
      payload.cartId = getSessionItem(CART_ID)
      if (payload.cartId) {
        const { data: response } = await setCheckoutDelMethod(payload)
        if (response.errors) {
          showToast(ERROR, response.errors[0].message)
        }
        else {
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.setShippingMethodsOnCart?.cart
            }
          })
          dispatch({
            type: CHECKOUT_DELIVERY_METHOD_SUCCESS, payload: {
              type: CHECKOUT_DELIVERY_METHOD_SUCCESS,
              data: response?.data?.setShippingMethodsOnCart?.cart,
            }
          })
        }
      }
    }
    catch (error) {
      showToast(ERROR, error)
    }
  })
}

export const getBtreeToken = () => {
  return (async (dispatch: any) => {
    try {
      const { data: response } = await getBrainTreeToken();
      const data = response?.data;
      if (data) {
        dispatch({
          type: GET_BRAINTREETOKEN_SUCCESS,
          payload: {
            type: GET_BRAINTREETOKEN_SUCCESS,
            data
          }
        })
      }
      else {
        dispatch({
          type: GET_BRAINTREETOKEN_FAILED,
          payload: {
            type: GET_BRAINTREETOKEN_FAILED,
            message: response.errors[0]?.message
          }
        })
      }
    } catch (error) {
      dispatch({
        type: GET_BRAINTREETOKEN_FAILED,
        payload: { type: GET_BRAINTREETOKEN_FAILED, message: error },
      })
    }
  })
}

export const onPlaceOrder = (payload: any) => {
  return (async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID)
      if (!isAuth()) {
        await setGuestEmailAddress(getSessionItem(GUEST_EMAIL), payload.cartId)
      }
      const { data: response } = await placeOrder(payload)
      if (response.errors) {
        showToast(ERROR, response.errors[0].message)
        dispatch({
          type: ORDER_PLACED_FAILED,
          payload: {
            type: ORDER_PLACED_FAILED
          }
        })
      } else {
        showToast(SUCCESS, "Your order has placed successfully")
        dispatch({
          type: ORDER_PLACED_SUCCESS,
          payload: {
            type: ORDER_PLACED_SUCCESS,
            data: response.data?.placeOrder?.order?.order_number
          }
        })
        // dispatch({
        //   type: GET_CART_PAGE_SUCCESS,
        //   payload: {
        //     type: GET_CART_PAGE_SUCCESS,
        //     data: response?.data ?
        //   }
        // })
      }
    } catch (error) {
      showToast(ERROR, error)
      dispatch({
        type: ORDER_PLACED_FAILED,
        payload: {
          type: ORDER_PLACED_FAILED
        }
      })
    }
  })
}

export const setBillingAddress = (payload: any) => {
  return (async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID)
      if (payload.country) {
        payload.region = { region: payload.country.value }
      }
      const { data: response } = await setBillingAddressOnCart(payload)
      if (response.errors) {
        showToast(ERROR, response.errors[0].message)
      } else {
        dispatch({
          type: CHECKOUT_ADDRESS_SUCCESS, payload: {
            type: CHECKOUT_ADDRESS_SUCCESS,
            data: response.data?.setBillingAddressOnCart?.cart,
          }
        })
      }
    } catch (error) {
      showToast(ERROR, error)
    }
  })
}

export const checkCustomerEmailAvailable = (email: string) => {
  return (async (dispatch: any) => {
    try {
      const { data: response } = await checkCustomerEmail(email)
      if (response.errors) {
        showToast(ERROR, response.errors[0].message)
      } else {
        if (response.data?.isEmailAvailable?.is_email_available) {
          const cartId = getSessionItem(CART_ID)
          await setGuestEmailAddress(email, cartId)
        }
        setSessionItem(GUEST_EMAIL, email)
        dispatch({
          type: CHECK_EMAIL_AVAILABLE_SUCCESS,
          payload: {
            type: CHECK_EMAIL_AVAILABLE_SUCCESS,
            data: response.data?.isEmailAvailable?.is_email_available
          }
        })
      }
    } catch (error) {
      showToast(ERROR, error)
    }
  })
}