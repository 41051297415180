/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CART_ID, GUEST_EMAIL } from "../../../Constants/LocalStorage";
import { HOME } from "../../../Constants/Route";
import { createEmptyCartAction } from "../../../Redux/Pages/CartPage/CartPageActions";
import {
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
  ORDER_PLACED_SUCCESS,
} from "../../../Redux/Pages/Checkout/CheckoutTypes";
import { getOrdersSuccessAction } from "../../../Redux/Pages/Orders/OrdersAction";
import { ORDERS_SUCCESS_SUCCESS } from "../../../Redux/Pages/Orders/OrdersTypes";
import { removeSessionItem } from "../../../Utility/Utilities";
import CustomButton from "../../Common/CustomButton/CustomButton";

const OrderSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderSuccessResponse = useSelector(
    (state: any) => state?.ordersSuccessResponse
  );

  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);

  const [staticData, setStaticData] = useState<any>({});

  useEffect(() => {
    if (checkoutResponse && checkoutResponse.type === ORDER_PLACED_SUCCESS) {
      removeSessionItem(GUEST_EMAIL);
      dispatch(getOrdersSuccessAction(checkoutResponse.data));
      removeSessionItem(CART_ID);
      dispatch(createEmptyCartAction());
    }
  }, [checkoutResponse]);

  useEffect(() => {
    if (
      orderSuccessResponse &&
      orderSuccessResponse.type === ORDERS_SUCCESS_SUCCESS
    ) {
      setStaticData(orderSuccessResponse?.data?.OrderSuccess);
      dispatch({
        type: CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
        payload: {
          type: CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
          data: null,
        },
      });
    }
  }, [orderSuccessResponse, staticData]);

  return (
    <div className="ordrsucs-wrap">
      <div className="custom-container">
        <div className="ordrsucs-content">
          <img
            src={staticData.image}
            alt={"order success"}
            title={"order success"}
          />
          <span className="tm">Thank you for your purchase!</span>
          <p className="lm">
            Order #{orderSuccessResponse?.data?.OrderSuccess?.number}
          </p>
          <div
            dangerouslySetInnerHTML={{ __html: staticData.description }}
          ></div>
          <CustomButton
            onClick={() => {
              navigate(HOME);
            }}
            bg={"fill"}
          >
            {staticData.title}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
