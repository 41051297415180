import { isAuth } from "../Utility/Utilities";

export const MAX_CALLS = 3;
export const END_POINTS = {
  login: process.env.REACT_APP_API_URL + "/login",
  graphQL: process.env.REACT_APP_API_URL + "/graphql",
};

export const getGraphQLPayload = (page: string): string => {
  return `{
  component(path: "${page}") {
    message
    status
    statusCode
    component {
      componentIdentifier
      data{
        title
        elementId
        description
        image
        mimage
        redirectType
        redirectTypeId
        urlKey
        action{
          title
          type
        }
        details{
          price
          customerName
          specialPrice
          component_url
        }
      }
    }
  }
}`;
};

export const getUrlResolverPayload = (url: string): string => {
  return `{
  urlResolver(url: "${url}") {
    entity_uid
    relative_url
    redirectCode
    type
  }
}`;
};

export const registerPayload = (payload: any): string => {
  return `mutation {
  createCustomerV2(
    input: {
      firstname: "${payload.firstName}",
      lastname: "${payload.lastName}",
      email: "${payload.email}",
      password: "${payload.password}",
      is_subscribed: ${
        payload.isSubscribed !== undefined ? payload.isSubscribed : false
      }
    }
  ) {
    customer {
      firstname
      lastname
      email
      is_subscribed
    }
  }
}`;
};

export const loginPayload = (payload: any): string => {
  return `mutation {
  generateCustomerToken(
    email: "${payload.email}",
    password: "${payload.password}"
  ) {
    token
  }
}`;
};

export const getUserPayload = (): string => {
  return `{
  customer {
    firstname
    lastname
    is_subscribed
    email
  }
}`;
};

export const getLogoutPayload = (): string => {
  return `mutation {
  revokeCustomerToken {
    result
  }
}`;
};

export const getAddressesPayload = (): string => {
  return `{
  customer { email addresses { ${addressType}} } }`;
};

export const mergeCartPayload = ({ sourceCartId }: any): string => {
  return `mutation {
    mergeCarts(
        source_cart_id: "${sourceCartId}"
    ) {
        id
    }
  }`;
};

export const getCountryNamePayload = (countryId: string): string => {
  return `{
  country(id: "${countryId}") {
    full_name_locale
  }
}`;
};

export const getChangePasswordPayload = (payload: any): string => {
  return `mutation {
  changeCustomerPassword(
    currentPassword: "${payload.password}",
    newPassword: "${payload.cpassword}"
  ) {
    id
    email
  }
}`;
};

export const getChangeEmailPayload = (payload: any): string => {
  return `mutation {
  updateCustomer(input : ${getUserRequest(payload)}) {
    customer {
      firstname
      lastname
      email
    }
  }
}`;
};

export const updateLoggedInNewsLetter = (isSubscribed: boolean): string => {
  return `mutation {
  updateCustomerV2(
    input: {
      is_subscribed: ${isSubscribed}
    }
  ) {
    customer {
      is_subscribed
    }
  }
}
`;
};

export const updateGuestUser = (email: string): string => {
  return `mutation {
  subscribeEmailToNewsletter(
    email: "${email}"
  ) {
    status
  }
}`;
};

export const forgotPassword = (payload: { email: string }): string => {
  return `mutation {
  requestPasswordResetEmail(
    email: "${payload.email}"
  )
}`;
};

export const resetPassword = (payload: any): string => {
  return `mutation {
  resetPassword(
    email: "${payload.email}",
    resetPasswordToken: "${payload.token}",
    newPassword: "${payload.password}"
  )
}`;
};

export const getCMSPagePayload = (payload: string): string => {
  return `{
  cmsPage(identifier: "${payload}") {
    identifier
    url_key
    title
    content
    content_heading
    page_layout
    meta_title
    meta_description
    meta_keywords
  }
}`;
};

export const getProductListData = ({ search, page, size, entity_uid }: any) => {
  return `{
  products(filter: {
    category_uid: {
      eq: "${entity_uid}"
    }
  }, pageSize: ${size}, currentPage: ${page}, sort: {
    position: ASC
  }) {
    total_count
    items {
      name
      url_key
      url_suffix
      sku
       image{
        label
        url
      }
      small_image {
        label
				url
      }
      price_range {
        minimum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
        maximum_price {
          final_price {
            value
            currency
          }
        }
      }
    }
    page_info {
      current_page
      page_size
      total_pages
    }
    total_count
  }
  categoryList(filters:{category_uid:{
      eq: "${entity_uid}"
    }}) {
    description
    image
    name
    mobile_thumbnail
  }
}`;
};

export const submitContactUsForm = (payload: any): string => {
  return `mutation {
    contactusFormSubmit(input: {
      email:"${payload.email}",
      fullname: "${payload.fullName}",
      telephone: "${payload.telephone}",
      message: "${payload.message}"
    }) {
      success_message
    }
  }`;
};

export const getStoreConfig = (): string => {
  return ` 
  {
    storeConfig {
      header_logo_src
      store_code
      store_name
      is_default_store
      store_group_code
      base_currency_code
      base_media_url
      base_url
      root_category_uid
    }
    currency {
        base_currency_symbol
    }
  }
  `;
};

export const createEmptyCart = (): string => {
  if (isAuth()) {
    return `{
  customerCart{
    id
  }
}`;
  }
  return `mutation {
  createEmptyCart
}`;
};

export const getCartDetailPayload = (cartId: string): string => {
  return `{
   cart(cart_id: "${cartId}") {
    id
    ${cartType}
  }
}`;
};

export const addProductToCartPayload = (payload: any): string => {
  return `
  mutation {
    addConfigurableProductsToCart(
      input: { cart_id: "${payload.cartId}", cart_items: [{
        parent_sku:"${payload.parent_sku}",
        data: {
          quantity: 1
          sku: "${payload.child_sku}"
        }
      }] }
    ) {
      cart {
        id
        ${cartType}
      }
    }
  }
  `;
};

export const addSimpleProductToCartPayload = (payload: any): string => {
  return `mutation {
    addSimpleProductsToCart (input: {
      cart_id: "${payload.cartId}",
      cart_items: {
        data: {
          sku: "${payload.sku}",
          quantity: 1
        }
      }
    }) {
      cart {
       id
       ${cartType}
      }
    }
  }`;
};

export const updateCartItems = ({ cartId, uid, quantity }: any) => {
  return `
mutation {
  updateCartItems(input: { cart_id: "${cartId}", cart_items: [
    {
      cart_item_uid: "${uid}",
      quantity: ${quantity}
    }
  ] }) {
    cart {
      id
      ${cartType}
    }
  }
}
`;
};

export const getCMSBlockPayload = (payload: string): string => {
  return `{
  cmsBlocks(identifiers: [${payload}]) {
    items {
      identifier
      title
      content
    }
  }
}`;
};

export const deleteCartPayload = ({ cartId, cartItemId }: any): string => {
  return `
mutation {
  removeItemFromCart(input: { cart_id: "${cartId}" , cart_item_uid: "${cartItemId}" }) {
    cart {
      id
      ${cartType}
    }
  }
}
`;
};

export const addProductToWishlistPayload = ({ sku, quantity }: any): string => {
  return `mutation {
  addProductsToWishlist(
    wishlistId: "0"
    wishlistItems: [{ sku: "${sku}"  quantity: ${quantity} }]
  ) {
    user_errors {
      code
      message
    }
    wishlist {
      id
      items_v2{
        items{
          id
          product{
            name
            sku
          }
        }
      }
    }
  }
}
`;
};

export const removeFromWishlistPayload = ({ wishlistItemId }: any): string => {
  return `
mutation {
  removeProductsFromWishlist(wishlistId: "0", wishlistItemsIds: ["${wishlistItemId}"]) {
    user_errors {
      code
      message
    }
    ${wishlistType}
  }
}
`;
};

export const removeCoupanPayload = (cartId: string): string => {
  return `
  mutation {
  removeCouponFromCart(input: 
    { cart_id: "${cartId}" }) {
    cart {
      id
      ${cartType}
    }
  }
}`;
};

export const applyCoupanPayload = (payload: any): string => {
  return `mutation {
  applyCouponToCart(
    input: {
      cart_id: "${payload.cartId}"
      coupon_code: "${payload.coupan_code}"
    }
  ) {
    cart {
      id
      ${cartType}
    }
  }
}`;
};

export const getOrderSuccessPayload = (orderId: string): string => {
  return `{
    OrderSuccess(id: "${orderId}") {
      description
      image
      number
      title
    }
  }`;
};

export const getCategoryListPayload = (uid: string): string => {
  return `
    {
      categories(
        filters: {
          parent_id: {in: ["${uid}"]}
        }
      ) {
        total_count
        items {
          uid
          level
          name
          path
          include_in_menu
          children_count
          url_key
          url_path
          url_suffix
          cms_block {
            content
            title
            identifier
          }
          children {
            uid
            level
            name
            path
            children_count
            url_key
            url_path
            children {
              uid
              level
              name
              path
            }
          }
        }
        page_info {
          current_page
          page_size
          total_pages
        }
      }
  }`;
};

export const getWishlistPayload = ({ size, page }: any): string => {
  return `{
    customer {
      wishlists {
        id
        items_count
        ${
          size && page
            ? `items_v2(pageSize: ${size}, currentPage: ${page})`
            : "items_v2"
        } {
          items {
            id
            product {
              sku
              name
              url_key
              url_suffix
              image {
                label
                url
              }
              price_range {
                maximum_price {
                  regular_price {
                    value
                  }
                  final_price {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
}`;
};

export const deleteAddressPayload = (addressId: number): string => {
  return `
  mutation {
  deleteCustomerAddress(id: ${addressId}) 
}
  `;
};

export const updateAddressPayload = (address: any): string => {
  return `mutation {
  updateCustomerAddress(id: ${address.id}, ${addressFormType(address)}) {
    id
    ${addressType}
  }
}`;
};

export const createAddressPayload = (address: any): string => {
  return `mutation {
  createCustomerAddress(input: {
    ${getField("city", address.city ? '"' + address.city + '"' : null)},
    ${getField(
      "region",
      address.state ? `{ region: "${address.state}"}` : undefined
    )},
    ${getField(
      "firstname",
      address.firstname ? '"' + address.firstname + '"' : null
    )},
    ${getField(
      "lastname",
      address.lastname ? '"' + address.lastname + '"' : null
    )},
    ${getField(
      "telephone",
      address.telephone ? '"' + address.telephone + '"' : null
    )},
    ${getField("street", address.street ? '"' + address.street + '"' : null)},
    ${getField(
      "postcode",
      address.postcode ? '"' + address.postcode + '"' : null
    )},
    country_id: GB,
  }) {
    id
    ${addressType}
  }}`;
};

export const searchCategoryPayload = (
  searchCat: string,
  page: number,
  size: number
): string => {
  return `{
  products(search: "${searchCat}", pageSize: ${size}, currentPage: ${page}) {
    total_count
    items {
      uid
      name
      image {
        url
        label
      }
      url_key
      url_suffix
    }
    page_info {
      current_page
      page_size
      total_pages
    }
  }
}`;
};

const cartType = `
 available_payment_methods {
  code
  title
}
billing_address {
  street
  firstname
  lastname
  city
  postcode
  region {
    code
    label
  }
  telephone
}
shipping_addresses {
  street,
      firstname,
      lastname,
      city,
      postcode,
      region{
        code
        label
      },
      telephone
      available_shipping_methods {
        carrier_code
          method_code
        carrier_title
        amount {value}
      }
      selected_shipping_method {
        carrier_code
          method_code
        carrier_title
        amount {value}
      }
    }
items{
      uid
      quantity
       __typename
      ... on ConfigurableCartItem{
        configurable_options{
          configurable_product_option_uid
          configurable_product_option_value_uid
          option_label
          value_label
        }
      }
      prices{
        discounts{
          amount{
            currency
            value
          }
          label
        }
        fixed_product_taxes{
          amount{
            currency
            value
          }
          label
        }
        price{
          currency
          value
        }
        row_total{
          currency
          value
        }
        row_total_including_tax{
          currency
          value
        }
        total_item_discount{
          currency
          value
        }
      }
      product{
        name
        sku
        url_key
        color
        url_suffix
        availability_message
        image {
            label
            url
        }
      }
    }
    applied_coupons {
      code
    }
    prices {
      discounts {
        label
        amount {
          currency
          value
        }
      }
      applied_taxes {
        label
        amount {
          value
        }
      }
      subtotal_excluding_tax {
        currency
        value
      }
      grand_total {
        value
        currency
      }
    }`;

const wishlistType = `wishlist {
    items_count
    items {
      id
      product {
        sku
        name
        url_key
        url_suffix
        image {
          label
          url
        }
        price_range {
          maximum_price {
            regular_price {
              value
            }
            final_price {
              value
            }
          }
        }
      }
    }
  }`;

const getField = (key: string, value: any) => {
  return value ? `${key}: ${value}` : "";
};

const addressFormType = (address: any) => {
  return `input: {
    firstname: "${address.firstname}",
    lastname: "${address.lastname}",
    company: "${address.company}",
    street: "${address.street}",
    city: "${address.city}",
    region: {
      region: "${address.state}"
    },
    telephone: "${address.telephone}",
    postcode: "${address.postcode}"
  }`;
};

const getUserRequest = (payload: any): string => {
  return payload.password
    ? `{
    firstname: "${payload.firstname}"
    lastname: "${payload.lastname}",
    email: "${payload.email}"
    password: "${payload.password}"
  }`
    : `{
    firstname: "${payload.firstname}"
    lastname: "${payload.lastname}",
    email: "${payload.email}"
  }`;
};

const addressType = `
      id
      firstname
      lastname
      street
      city
      region {
        region_code
        region
      }
      postcode
      country_code
      telephone`;

export const getProductDetailPayload = (url: string): string => {
  return `{
  products(filter: { 
  		url_key: {
        eq: "${url}"
      }
  }) {
    items {
      stock_status
      __typename
      upsell_products {
         name
         image {
          label
          url
        }
        url_key
        url_suffix
        price_range {
          minimum_price {
            regular_price {
              value
            }
            final_price {
              value
            }
          }
        }
      }
       related_products {
        name
        image {
          label
          url
        }
        url_key
        url_suffix
        sku
        price_range {
          minimum_price {
            regular_price {
              value
            }
            final_price {
              value
            }
          }
        }
      }
      uid
      name
      sku
      description {
        html
      }
      short_description {
        html
      }
      media_gallery {
        label
        url
      }
      image {
        url
        label
      }
      cms_block
      dimensions {
        html
      }
      whyus {
        html
      }
      delivery {
        html
      }
      name
      sku
      availability_message
      price_range{
        minimum_price{
          regular_price{
            value
          }
          final_price {
            value
          }
        }
      }
      ... on ConfigurableProduct {
        configurable_options {
          id
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
              swatch_data{
              value
              __typename
              ... on ImageSwatchData{
                thumbnail
                value
                group
                color_group
              }
              }
          }
          product_id
        }
        variants {
          product {
              uid
              price_range {
                minimum_price {
                  regular_price {
                    value
                  }
                  final_price {
                    value
                  }
                }
              }
            description {
              html
            }
            cms_block
            dimensions{
        html
      }
            whyus{
        html
      }
            delivery {
        html
      }
            name
            sku
            cms_block
            image {
              label
              url
            }
            media_gallery {
              url
              label
            }
            related_products {
              name
              url_key
              url_suffix
              image {
                label
                url
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                  }
                  final_price {
                    value
                  }
                }
              }
            }
            short_description {
              html
            }
            upsell_products {
              name
              url_key
              url_suffix
              image {
                label
                url
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                  }
                  final_price {
                    value
                  }
                }
              }
            }
            ... on PhysicalProductInterface {
              weight
            }
            media_gallery {
              url
              label
            }
          }
          attributes {
            label
            code
            value_index
          }
        }
      }
    }
  }
}`;
};

export const getMyOrderPayload = ({ size, page }: any): string => {
  return `{
    customer {
      orders(pageSize: ${size}, currentPage: ${page}) {
        total_count
        items {
          
          shipping_address{
            street
            firstname
            lastname
            city
            company
            country_code
            fax
            region
            telephone
          }
          items{
            quantity_ordered
            id
            product_name
            product_url_key
            product_sku
            selected_options{
              label
              value
            }
            product_sale_price{
              currency
              value
            }
            status
          }
          id
          increment_id
          order_date
          status
          total {
            taxes{
              amount{
                currency
                value
              }
              rate
              title
            }
            grand_total {
              value
              currency
            }
          }
        }
      }
    }
  }`;
};

export const getMyOrderDetailsPayload = (orderId: string): string => {
  return `{
    customer {
      orders(filter:{number: {eq:"${orderId}"}}) {
        total_count
        items {
          shipping_address{
            street
            firstname
            lastname
            city
            company
            country_code
            fax
            region
            telephone
          }
          items{
            quantity_ordered
            id
            product_url_key
            product_name
            product_sku
            selected_options{
              label
              value
            }
            product_sale_price{
              currency
              value
            }
            status
          }
          id
          increment_id
          order_date
          status
          shipping_method
          payment_methods {
            name
          }
          total {
            subtotal {
              value
            }
            taxes{
              amount{
                currency
                value
              }
              rate
              title
            }
            discounts{
              amount{
                currency
                value
              }
              label
            }
            shipping_handling{
              total_amount{
                currency
                value
              }
              amount_excluding_tax{
                currency
                value
              }
              amount_including_tax{
                currency
                value
              }
              discounts{
                amount{
                  currency
                  value
                }
              }
              taxes{
                amount{
                  currency
                  value
                }
                rate
                title
              }
              
            }
            grand_total {
              value
              currency
            }
          }
        }
      }
    }
  }`;
};

export const getProductThumbImagePayload = (url: string): string => {
  return `{
    products(filter: { 
        url_key: {
          in: [${url}]
        }
    }) {
      items {
        url_key
        thumbnail {
          label
          url
        }
      }
    }
  }`;
};

export const getBlogpageList = (type: string): string => {
  return `{
    amBlogPosts(type: ${type}) {
      all_post_size
      items {
        author_id
        canonical_url
        categories
        comment_count
        comments_enabled
        created_at
        display_short_content
        grid_class
        is_featured
        full_content
        list_thumbnail
        list_thumbnail_alt
        meta_description
        meta_robots
        meta_tags
        meta_title
        notify_on_enable
        post_id
        post_thumbnail
        post_thumbnail_alt
        published_at
        related_post_ids
        short_content
        status
        tag_ids
        title
        updated_at
        url_key
        user_define_publish
        views
      }
    }
  }`;
};

export const setShippingAddressPayload = (payload: any): string => {
  return `mutation {
  setShippingAddressesOnCart(
    input: {
      cart_id: "${payload.cartId}"
      shipping_addresses: [
        {
          address: {
            firstname: "${payload.firstname}"
            lastname: "${payload.lastname}"
            street: "${payload.street}"
            city: "${payload.city}"
            region: "${payload.region.region}"
            postcode: "${payload.postcode}"
            country_code: "GB"
            telephone: "${payload.telephone}"
            save_in_address_book: true
          }
        }
      ]
    }
  )
  {
    cart {
      billing_address {
        street
        firstname
        lastname
        city
        postcode
        region {
          code
          label
        }
        telephone
      }
      shipping_addresses {
         street,
      firstname,
      lastname,
      city,
      postcode,
      region{
        code
        label
      },
      telephone
        available_shipping_methods{
          carrier_code
          carrier_title
          method_code
          method_title
          amount {value}
        }
      }
    }
  }
  }`;
};

export const setBillingAddressPayload = (payload: any): string => {
  const billing_address = payload.address
    ? `billing_address: {
          address: {
                firstname: "${payload.address.firstname}"
                lastname: "${payload.address.lastname}"
                ${
                  payload.address.company
                    ? 'company: "' + payload.address.company + '"'
                    : ""
                }
                street: ["${payload.address.street}"]
                city: "${payload.address.city}"
                region: "${payload.address.state}"
                postcode: "${payload.address.postcode}"
                country_code: "GB"
                telephone: "${payload.address.telephone}"
                save_in_address_book: true
              }
          }`
    : `billing_address: {
    customer_address_id: ${payload.customerAddressId}  }`;
  return `
mutation {
  setBillingAddressOnCart(input: { 
    ${billing_address}
    cart_id: "${payload.cartId}" }) {
    cart {
      id
      ${cartType}
    }
  }
}`;
};

export const getDeliveryMethodPayload = (cart_id: any): string => {
  return `{
    cart(cart_id: "${cart_id}") {
      billing_address {
        street
        firstname
        lastname
        city
        postcode
        region {
          code
          label
        }
        telephone
      }
      shipping_addresses {
        selected_shipping_method {
        carrier_title
        carrier_code
        method_code
        amount {
           value
        }
      }
      street,
      firstname,
      lastname,
      city,
      postcode,
      region{
        code
        label
      },
      telephone
        available_shipping_methods {
          carrier_code
          method_code
          carrier_title
          amount {
            currency
            value
          }
        }
      }
      available_payment_methods {
        code
        title
      }
    }
  }`;
};

export const setDeliveryMethodPayload = (payload: any): string => {
  return `mutation {
    setShippingMethodsOnCart(input: {
      cart_id: "${payload.cartId}"
      shipping_methods: [
        {
          carrier_code: "${payload.carrier_code}",
          method_code: "${payload.method_code}"
        }
      ]
    }) {
      cart {
        id
        ${cartType}
      }
    }
  }`;
};

export const getCustomFormDetailPayload = (form_name: string): string => {
  return `{
    CustomForm(code: "${form_name}"){
      code
      form_json
      form_title
      title
    }
  }`;
};

export const getBrainTreeTokenPayload = (): string => {
  return `mutation {
    createBraintreeClientToken
  }`;
};

export const placeOrderPayload = (payload: any): string => {
  return `mutation {
  setPaymentMethodOnCart(input: {
      cart_id: "${payload.cartId}"
      payment_method: {
          code: "${payload.method_code}"
        	braintree: {
            payment_method_nonce: "${payload.nonce}"
            is_active_payment_token_enabler: false
          }
      }
  }) {
    cart {
      id
    }
  }
  placeOrder(input: {cart_id: "${payload.cartId}"}) {
    order {
      order_number
    }
  }
}`;
};

export const isEmailAvailablePayload = (email: string): string => {
  return `{
  isEmailAvailable(email: "${email}") {
    is_email_available
  }
}`;
};

export const setGuestEmailPayload = (email: string, cartId: string): string => {
  return `mutation {
  setGuestEmailOnCart(input: {
    cart_id: "${cartId}"
    email: "${email}"
  }) {
    cart {
      email
    }
  }
}`;
};
