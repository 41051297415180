/* eslint-disable react-hooks/exhaustive-deps */
import DropIn from "braintree-web-drop-in-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import InputMask from "react-input-mask";
import { Link, useNavigate } from "react-router-dom";
import { ORDER_SUCCESS } from "../../Constants/Route";
import {
  CREATE_ADDRESS_SUCCESS,
  GET_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
} from "../../Redux/Auth/AuthType";
import { GET_CART_PAGE_SUCCESS } from "../../Redux/Pages/CartPage/CartPageTypes";
import {
  onPlaceOrder,
  setBillingAddress,
} from "../../Redux/Pages/Checkout/CheckoutAction";
import {
  CHECKOUT_DELIVERY_METHOD_SUCCESS,
  CHECKOUT_GET_DELIVERY_METHOD_SUCCESS,
  GET_BRAINTREETOKEN_SUCCESS,
  ORDER_PLACED_FAILED,
  ORDER_PLACED_SUCCESS,
} from "../../Redux/Pages/Checkout/CheckoutTypes";
import { isAuth } from "../../Utility/Utilities";
import CheckboxGroups from "../Common/CheckboxGroups/CheckboxGroups";
import CustomButton from "../Common/CustomButton/CustomButton";

const CheckoutPaymentOption = (props: {
  setAddressPopup: any;
  billingAddresses: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkoutResponse = useSelector((state: any) => state.checkoutResponse);
  const cartResponse = useSelector((state: any) => state.getCartResponse);
  const addressResponse = useSelector((state: any) => state.addressResponse);

  const [deliveryMethodData, setDeliveryMethodData] = useState<any>({
    available_shipping_methods: [],
    selected_shipping_method: null,
  });
  const [addressData, setAddressData] = useState<any>({
    addresses: [],
    country_name: "United Kingdom",
  });
  const [showBillingAddressDropdown, setBillingAddressDropdown] =
    useState(false);

  const [paymentOptionIndex, setPaymentOptionIndex] = useState(0);
  const [showSelectedAddr, setShowSelectedAddr] = useState<any>(null);
  const [cardInstance, setCardInstance] = useState<any>(null);
  const [braintreeToken, setBraintreeToken] = useState<any>({});
  const [onPlaceOrderLoading, setPlaceOrderLoading] = useState(false);

  useEffect(() => {
    if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_GET_DELIVERY_METHOD_SUCCESS
    ) {
      setDeliveryMethodData(checkoutResponse?.data?.cart);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === GET_BRAINTREETOKEN_SUCCESS
    ) {
      setBraintreeToken(checkoutResponse?.data.createBraintreeClientToken);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === ORDER_PLACED_SUCCESS
    ) {
      setPlaceOrderLoading(false);
      navigate(ORDER_SUCCESS);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === ORDER_PLACED_FAILED
    ) {
      setPlaceOrderLoading(false);
    } else if (
      checkoutResponse &&
      checkoutResponse.type === CHECKOUT_DELIVERY_METHOD_SUCCESS
    ) {
      setDeliveryMethodData(checkoutResponse?.data);
    }
  }, [checkoutResponse]);

  useEffect(() => {
    if (addressResponse && addressResponse.type === GET_ADDRESS_SUCCESS) {
      addressResponse.data.addresses.forEach((res: any) => {
        res.state = res.region.region;
      });
      setAddressData(addressResponse.data);
    } else if (
      addressResponse &&
      addressResponse.type === UPDATE_ADDRESS_SUCCESS
    ) {
      const currentState = Object.assign({}, addressData);
      const index: number = currentState.addresses.findIndex(
        (s: { id: number }) => s.id === addressResponse.data.id
      );
      currentState.addresses[index] = addressResponse.data;
      setAddressData(currentState);
      props.setAddressPopup(false);
    } else if (
      addressResponse &&
      addressResponse.type === CREATE_ADDRESS_SUCCESS
    ) {
      // const currentState = Object.assign({}, addressData);
      // currentState.addresses.push(addressResponse.data);
      // setAddressData(currentState);
      // props.setAddressPopup(false);
    }
  }, [addressResponse]);

  function getAddressId(addressResponse: any, shippingAddress: any) {
    return addressResponse?.data?.addresses?.find(
      (d: any) =>
        d.telephone === shippingAddress.telephone &&
        d.street.join() === shippingAddress.street.join() &&
        d.firstname === shippingAddress.firstname &&
        d.lastname === shippingAddress.lastname &&
        d.region.region === shippingAddress.region.region
    )?.id;
  }

  const setBillingDrop = (e: boolean) => {
    if (e) {
      if (cartResponse.type === GET_CART_PAGE_SUCCESS) {
        const shippingAddress = cartResponse.data.shipping_addresses[0];
        if (shippingAddress) {
          const addressId = getAddressId(addressResponse, shippingAddress);
          if (addressId) {
            dispatch(
              setBillingAddress({
                customerAddressId: Number(addressId),
              })
            );
          }
        }
      }
    }
    setBillingAddressDropdown(!e);
  };

  function handleSelectChange(event: any) {
    props.setAddressPopup(Number(event.target.value) === 0);
    const address = addressData.addresses?.find(
      (add: { id: number }) => add.id === Number(event.target.value)
    );
    if (Number(event.target.value)) {
      dispatch(
        setBillingAddress({ customerAddressId: Number(event.target.value) })
      );
    }
    setShowSelectedAddr(address);
  }

  async function buy() {
    setPlaceOrderLoading(true);
    try {
      const { nonce } = await cardInstance.requestPaymentMethod();
      dispatch(
        onPlaceOrder({
          nonce,
          method_code: "braintree",
        })
      );
    } catch (error) {
      console.error("ERROR");
      setPlaceOrderLoading(false);
    }
  }
  return (
    <div className="ckout-inner">
      <div className="ckout-top-box">
        <div className="ckout-head">
          <span>
            <p className="bs">4</p>
          </span>
          <p className="ts caps-on">Payment Options</p>
        </div>
      </div>
      {deliveryMethodData?.shipping_addresses &&
      deliveryMethodData?.shipping_addresses[0] ? (
        <div className="payment-box">
          {deliveryMethodData?.available_payment_methods?.map(
            (data1: any, index: any) => (
              <div key={data1.title + index} className="delivery-method">
                <div className="rd" key={index}>
                  <input
                    type="radio"
                    name="payment-selected"
                    id={data1.code}
                    onChange={() => setPaymentOptionIndex(index)}
                    defaultChecked={index === paymentOptionIndex}
                  />
                  <label className="bm" htmlFor={data1.code}>
                    {data1?.title}
                  </label>
                </div>
                {index === paymentOptionIndex ? (
                  <>
                    <div className="cod payment-action-content">
                      <CheckboxGroups
                        onChange={(e: any) => {
                          setBillingDrop(e.target.checked);
                        }}
                        defaultChecked={!showBillingAddressDropdown}
                        id="unselect-addresses"
                      >
                        Bill to delivery address
                      </CheckboxGroups>
                    </div>
                    {showBillingAddressDropdown ? (
                      <div className="list-addresses">
                        <div className="select-group">
                          <select
                            value={showSelectedAddr?.id}
                            onChange={handleSelectChange}
                          >
                            {isAuth()
                              ? addressData.addresses?.map(
                                  (item: any, index2: number) => (
                                    <option
                                      key={index2 + item.firstname}
                                      value={item.id}
                                    >
                                      {item.firstname + " " + item.lastname}
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        addressData.country_name +
                                        ", " +
                                        item.postcode}
                                    </option>
                                  )
                                )
                              : props.billingAddresses?.map(
                                  (item: any, index2: number) => (
                                    <option
                                      key={index2 + item.firstname}
                                      value={item.id}
                                    >
                                      {item.firstname + " " + item.lastname}
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        props.billingAddresses.country_name +
                                        ", " +
                                        item.postcode}
                                    </option>
                                  )
                                )}
                            {isAuth() ? (
                              <option value="0">New Address</option>
                            ) : null}
                          </select>
                        </div>
                        {isAuth()
                          ? addressData.addresses?.map((item: any) =>
                              item?.id === showSelectedAddr?.id ? (
                                <p className="bm">
                                  <div className="ckout-txt">
                                    <p className="bm">
                                      {item.firstname + " " + item.lastname}
                                    </p>
                                    <p className="bm">
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        addressData.country_name +
                                        ", " +
                                        item.postcode}
                                    </p>
                                    <Link to={""} className="bm">
                                      {item.telephone}
                                    </Link>
                                  </div>
                                </p>
                              ) : null
                            )
                          : props.billingAddresses?.map((item: any) =>
                              item?.id === showSelectedAddr?.id ? (
                                <p className="bm">
                                  <div className="ckout-txt">
                                    <p className="bm">
                                      {item.firstname + " " + item.lastname}
                                    </p>
                                    <p className="bm">
                                      {item.street?.join(", ") +
                                        ", " +
                                        (item.region?.region
                                          ? item.region?.region + ","
                                          : "") +
                                        addressData.country_name +
                                        ", " +
                                        item.postcode}
                                    </p>
                                    <Link to={""} className="bm">
                                      {item.telephone}
                                    </Link>
                                  </div>
                                </p>
                              ) : null
                            )}
                      </div>
                    ) : null}
                    <div className="credit-card-content">
                      {data1.code === "braintree" ? (
                        <div>
                          <DropIn
                            options={{
                              authorization: braintreeToken,
                            }}
                            onInstance={(cardInstance: any) =>
                              setCardInstance(cardInstance)
                            }
                          />
                          <CustomButton
                            isLoading={onPlaceOrderLoading}
                            onClick={() => buy()}
                          >
                            {" "}
                            Place Order
                          </CustomButton>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </div>
            )
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CheckoutPaymentOption;
