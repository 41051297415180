/* eslint-disable react-hooks/exhaustive-deps */
import CustomButton from "../Common/CustomButton/CustomButton";
import InputGroups from "../Common/InputGroups/InputGroups";
import { useEffect, useState } from "react";
import validate from "../../Utility/Validation";
import { forgotPasswordAction } from "../../Redux/Auth/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import {
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
} from "../../Redux/Auth/AuthType";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { useNavigate } from "react-router";
import { LOGIN } from "../../Constants/Route";
import { showToast } from "../../Utility/Utilities";
import { SUCCESS } from "../../Constants/Labels";

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const response = useSelector((state: any) => state.forgotPassword);
  const [error, setError] = useState({
    email: null,
    captcha_text: null,
  });

  const [isFormValidated, setIsFormValidated] = useState(false);
  const INITIAL_FORM = {
    email: {
      value: null,
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    captcha_text: {
      value: null,
      validation: ["captcha_text", "required"],
      errors: ["Please enter valid captch", "Captcha is required"],
    },
  };
  const [form, setForm] = useState<any>(INITIAL_FORM);

  useEffect(() => {
    loadCaptchaEnginge(6);
    return () => {};
  }, []);

  useEffect(() => {
    if (response) {
      if (response.type === FORGOT_PASSWORD_SUCCESS) {
        showToast(SUCCESS, "Please check your mail");
        setLoading(false);
        navigate(LOGIN);
      } else if (response.type === FORGOT_PASSWORD_FAILED) {
        // showToast(ERROR, response.message);
        setTimeout(() => setLoading(false), 3000);
      }
    }
  }, [response]);

  const onSubmit = () => {
    setLoading(true);
    const isValidCaptcha = validateCaptcha(form.captcha_text.value);
    const validForm = validate(form);
    if (validForm.isValidated && isValidCaptcha) {
      const payload: any = {};
      Object.keys(form).forEach((k) => {
        payload[k] = form[k].value;
      });
      dispatch(forgotPasswordAction(payload));
    } else if (!isValidCaptcha) {
      setForm({ captcha_text: { ...form.captcha_text, value: "" } });
      // showToast(ERROR, "Invalid Captcha")
      setTimeout(() => setLoading(false), 3000);
      setIsFormValidated(true);
    } else {
      const message = { ...validForm?.errors };
      // showToast(ERROR, message)
      setError(message);
      setForm(INITIAL_FORM);
      setTimeout(() => setLoading(false), 3000);
      setIsFormValidated(true);
    }
  };

  return (
    <div className="authentication-wrap forgotpwd">
      <div className="custom-container">
        <div className="inner-main">
          <div className="top-head">
            <h6 className="caps-on">Forgot Your Password ?</h6>
            <p className="bm">
              Please enter your email address below to receive a password reset
              link.
            </p>
          </div>
          <InputGroups
            id="email"
            type="email"
            label="Email Address*"
            onChange={(e: any) =>
              setForm({
                ...form,
                email: { ...form.email, value: e.target.value },
              })
            }
            error={isFormValidated && error["email"] ? error["email"] : null}
          />
          <InputGroups
            label="Please type the letter and numbers below"
            id="user_captcha_input"
            onChange={(e: any) =>
              setForm({
                ...form,
                captcha_text: { ...form.captcha_text, value: e.target.value },
              })
            }
            error={
              isFormValidated && error["captcha_text"]
                ? error["captcha_text"]
                : null
            }
          />
          <div className="captcha-section">
            <LoadCanvasTemplate />
          </div>
          <CustomButton isLoading={loading} onClick={onSubmit} bg={"fill"}>
            Reset My Password
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
