import { Link } from "react-router-dom";
import menu from "../../Assets/Images/menu.svg";
import search from "../../Assets/Images/search.svg";
import cart from "../../Assets/Images/cart.svg";
import { useEffect, useState } from "react";
import MobileMainNavigation from "../NavigationMenu/MobileMainNavigation";
import Minicart from "../Home/MiniCart/MiniCart";
import { useSelector } from "react-redux";
import { CHECKOUT, HOME, ORDER_SUCCESS } from "../../Constants/Route";
import { STORE_CONFIG_SUCCESS } from "../../Redux/General/GeneralType";
import { useLocation } from "react-router";

const MobileHeader = () => {

	const [isActive, setActive] = useState(false);

	const handleToggle = () => {
		setActive(!isActive);
	};

	const [isCartActive, setCartActive] = useState(false);

	const handleCartToggle = () => {
		setCartActive(!isCartActive);
	};
	
	const [toggleSide, setToggleSide] = useState(false);

  const toggleMenu = () => {
    setToggleSide(!toggleSide);
  };

	const storeConResponse = useSelector((state: any) => state.storeConfigInfo)

	const [storeConfigdata, setStoreConfigData] = useState<any>({})

	useEffect(() => {
		if (storeConResponse && storeConResponse.type === STORE_CONFIG_SUCCESS) {
			setStoreConfigData(storeConResponse?.data?.storeConfig)
		}
	}, [storeConResponse])

	const [currentLocation1, setCurrentLocation1] = useState(String);
  const navigate1: any = useLocation();

  useEffect(() => {
    setCurrentLocation1(navigate1.pathname);
  }, [navigate1]);

	const urls = [CHECKOUT, ORDER_SUCCESS];

	return (<>
		<MobileMainNavigation toggleSide={toggleSide} toggleMenu={toggleMenu} />
		<div className={urls.includes(currentLocation1) ? currentLocation1 === ORDER_SUCCESS ? "mobile-header-wrap hide-767 in-checkout with-osuccess" : "mobile-header-wrap hide-767 in-checkout" : "mobile-header-wrap hide-767" }>
			<div className="custom-container">
				<div className="header-mob">
					<div className="mobile-left">
						<Link className="sprites-hamburger" to={"#"} onClick={toggleMenu}>
							<img src={menu} alt="Menu Icon"></img>
						</Link>
					</div>
					<div className="mobile-center">
						<Link to={HOME} className="mob-logo">
							<img src={storeConfigdata.base_media_url + "logo/" + storeConfigdata?.header_logo_src} width="34" height="34" alt="Livingitup" />
						</Link>
					</div>
					<div className="mobile-right">
						<Link to={"#"} className="search" onClick={() => handleToggle()}>
							<img src={search} alt="Search Bar" />
						</Link>
						<div className="minicart">
							<Link to={"#"} className="cart" onClick={() => handleCartToggle()}>
								<img src={cart} alt="Cart" />
							</Link>
							<div className={isCartActive ? "minicart-wrapper open" : "minicart-wrapper close"}>
								<Minicart />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className={isActive ? "search-box open" : "search-box close"}>
			<div className="custom-container">
				<div className="search-grp">
					<input type="search" autoFocus={true}></input>
					<button className="close-search" onClick={() => handleToggle()}></button>
				</div>
			</div>
		</div>
	</>)
}

export default MobileHeader;
