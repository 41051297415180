import axios, { AxiosPromise } from "axios";
import {
    getAddressesPayload, getChangeEmailPayload, getChangePasswordPayload, getLogoutPayload,
    getUserPayload, loginPayload, registerPayload, updateGuestUser, updateLoggedInNewsLetter,
    forgotPassword,
    resetPassword,
    getMyOrderPayload,
    getMyOrderDetailsPayload,
    getProductThumbImagePayload
} from "../Constants/Api";
import { _REQUEST_GRAPHQL } from "./graphQL.service";

export const register = (payload: any): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(registerPayload(payload)));
}

export const login = (payload: any): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(loginPayload(payload)));
}

export const getUserDetail = (): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(getUserPayload()));
}

export const logout = (): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(getLogoutPayload()));
}

export const getAddresses = (): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(getAddressesPayload()));
}

export const changePassword = (payload: any): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(getChangePasswordPayload(payload)));
}

export const changeEmail = (payload: any): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(getChangeEmailPayload(payload)));
}

export const updateLoggedInNewsLet = (payload: boolean): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(updateLoggedInNewsLetter(payload)));
}

export const updateGuestUserNewsLetter = (payload: string): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(updateGuestUser(payload)));
}

export const forgotPassrd = (payload: { email: string }): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(forgotPassword(payload)));
}

export const resetPasswor = (payload: any): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(resetPassword(payload)));
}

export const myOrdersItems = (payload: any): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(getMyOrderPayload(payload)));
}

export const myOrdersDetails = (orderId: any): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(getMyOrderDetailsPayload(orderId)));
}

export const myOrdersThumbImg = (url: string): AxiosPromise<any> => {
    return axios(_REQUEST_GRAPHQL(getProductThumbImagePayload(url)));
}