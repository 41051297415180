/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setShippingAddress } from "../../Redux/Pages/Checkout/CheckoutAction";
import CustomButton from "../Common/CustomButton/CustomButton";
import tickImg from "../../Assets/Images/tick.svg";
import { isAuth } from "../../Utility/Utilities";

const DeliveryAddress = (props: {
  showAddressPopup: any;
  setShowAddressPopup: any;
  isGuestUser: string;
  addresses: any;
  radioStateClass: number;
  setRadioClass: any;
  setAddress: any;
  setAddressId: any;
  addressPopup: any;
  opLoading: boolean;
  onDeleteAddress: any;
  addressId: number;
  setEdit: any;
  selectedShippingAddress: any;
  setSelectedShippingAddress: any;
}) => {
  const dispatch = useDispatch();

  const cartResponse = useSelector((state: any) => state.getCartResponse);

  return (
    <div className="ckout-inner">
      <div className="ckout-top-box">
        <div className="ckout-head">
          <span
            className={`tick ${props.selectedShippingAddress ? "yes" : "no"}`}
          >
            {props.selectedShippingAddress ? (
              <img src={tickImg} alt="Completed Step" />
            ) : (
              <p className="bs">1</p>
            )}
          </span>
          <p className="ts caps-on">Select Delivery Address</p>
        </div>
        {!props.selectedShippingAddress &&
        ((!isAuth() && !cartResponse?.data?.shipping_addresses[0]) ||
          isAuth()) ? (
          <Link
            to={""}
            title="Add New Address"
            className="bm with-border"
            onClick={() => {
              props.setEdit(false);
              props.setShowAddressPopup(true);
              props.setAddress(null);
            }}
          >
            {" "}
            Add New Address{" "}
          </Link>
        ) : (
          props.selectedShippingAddress && (
            <Link
              to={""}
              title="Change Address"
              className="bm with-border"
              onClick={() => {
                if (!isAuth()) {
                  props.setEdit(true);
                  props.setAddress(props.selectedShippingAddress);
                  props.setShowAddressPopup(true);
                } else {
                  props.setSelectedShippingAddress(null);
                }
              }}
            >
              {" "}
              Change Address{" "}
            </Link>
          )
        )}
        {props.selectedShippingAddress ? (
          <p className="selected-address bm">
            {props.selectedShippingAddress.street?.join(", ") +
              ", " +
              (props.selectedShippingAddress.region?.region
                ? props.selectedShippingAddress.region?.region + ","
                : "") +
              props.addresses.country_name +
              ", " +
              props.selectedShippingAddress.postcode}
          </p>
        ) : null}
      </div>
      {!props.selectedShippingAddress ? (
        <div className="ckout-content">
          {props.addresses.addresses && props.addresses.addresses.length > 0 ? (
            props.addresses.addresses?.map((item: any, index: number) => (
              <div
                key={item.firstname + index}
                className={
                  props.radioStateClass === index
                    ? "ckout-mid-box bg-surface"
                    : "ckout-mid-box"
                }
              >
                <div className="ckout-address">
                  <div className="rd">
                    <input
                      type="radio"
                      name="addr-selected"
                      checked={props.radioStateClass === index}
                      onChange={() => props.setRadioClass(index)}
                      defaultChecked={index === 0 ? true : false}
                    />
                  </div>
                  <div className="ckout-txt">
                    <p className="bm">{item.firstname + " " + item.lastname}</p>
                    <p className="bm">
                      {item.street?.join(", ") +
                        ", " +
                        (item.region?.region ? item.region?.region + "," : "") +
                        props.addresses.country_name +
                        ", " +
                        item.postcode}
                    </p>
                    <Link to={""} className="bm">
                      {item.telephone}
                    </Link>
                    {props.radioStateClass === index ? (
                      <CustomButton
                        onClick={() =>
                          dispatch(
                            setShippingAddress(props.addresses.addresses[index])
                          )
                        }
                        bg={"fill"}
                      >
                        Deliver here
                      </CustomButton>
                    ) : null}
                  </div>
                </div>
                <div className="ckout-edit">
                  <Link
                    to={""}
                    title="Edit"
                    className="bm with-border"
                    onClick={() => {
                      props.setAddress(item);
                      props.setEdit(true);
                      props.setShowAddressPopup(true);
                    }}
                  >
                    Edit
                  </Link>
                  {isAuth() && (
                    <Link
                      to={""}
                      title="Remove"
                      onClick={() => props.setAddressId(item.id)}
                      className="bm with-border"
                    >
                      Remove
                    </Link>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="ll alignboth-center">
              You don't have any saved address.
            </p>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DeliveryAddress;
