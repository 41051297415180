/* eslint-disable array-callback-return */
import { Link } from "react-router-dom";

const CategoryBlock = (props: { data: any[] }) => {
  const arr = [props.data.slice(0, 3), props.data.slice(3, 6)];
  return (
    <>
      {arr.map((d, index) => {
        return (
          <div key={index} className="categoryblock-wrap">
            <div className="custom-container">
              <div className="category-list">
                {d.map((data: any, index: any) => (
                  <div className="item" key={data.elementId + index}>
                    <Link to={"/" + data.urlKey} title={data.title}>
                      <img src={data.image} alt={data.title}></img>
                      <p className="ts cat-name caps-on">{data.title}</p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CategoryBlock;
