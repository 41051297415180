import { Modal } from "react-bootstrap";

const FabricInfoPopup = (props: any) => {
  return (
    <Modal {...props} centered className="fabricinfo-popup">
      <Modal.Header closeButton>
        <Modal.Title className="tm">Fabric Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
        {/* <div className="sub-point">
          <p className="block-title ll">Velvet</p>
          <p className="block-content bm">Vivamus tristique vitae lectus id feugiat. Phasellus luctus est at leo consequat, nec suscipit orci efficitur. Sed interdum dictum molestie. Praesent congue pharetra porta. Pellentesque habitant morbi tristique senectus et netus et malesuada ac turpis egestas.</p>
        </div>
        <div className="sub-point">
          <p className="block-title ll">Velvet</p>
          <p className="block-content bm">Vivamus tristique vitae lectus id feugiat. Phasellus luctus est at leo consequat, nec suscipit orci efficitur. Sed interdum dictum molestie. Praesent congue pharetra porta. Pellentesque habitant morbi tristique senectus et netus et malesuada ac turpis egestas.</p>
        </div>
        <div className="sub-point">
          <p className="block-title ll">Velvet</p>
          <p className="block-content bm">Vivamus tristique vitae lectus id feugiat. Phasellus luctus est at leo consequat, nec suscipit orci efficitur. Sed interdum dictum molestie. Praesent congue pharetra porta. Pellentesque habitant morbi tristique senectus et netus et malesuada ac turpis egestas.</p>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default FabricInfoPopup;
