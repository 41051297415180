export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST"
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS"
export const GET_PRODUCT_LIST_FAILED = "GET_PRODUCT_LIST_FAILED"

export const GET_PRODUCT_DETAIL_REQUEST = "GET_PRODUCT_DETAIL_REQUEST"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAILED = "GET_PRODUCT_DETAIL_FAILED"

export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST"
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS"
export const ADD_TO_WISHLIST_FAILED = "ADD_TO_WISHLIST_FAILED"

export const GET_PRODUCT_CMS_BLOCK_SUCCESS = "GET_PRODUCT_CMS_BLOCK_SUCCESS"
export const GET_PRODUCT_FABRIC_INFORMATION_SUCCESS = "GET_PRODUCT_FABRIC_INFORMATION_SUCCESS"

