export const LABEL_CREATE_ACCOUNT = "Create Account";
export const LABEL_FILL_INFO_BELOW = "Please fill in the information below.";
export const LABEL_SIGNUP_NEWSLETTER = "Sign up for newsletter";
export const LABEL_BY_CREATE_YOUR = "By creating an account, you agree to our";
export const LABEL_TERMS_AND_CONDITION = "Terms & Conditions";
export const LABEL_PRIVACY_POLICY = " Privacy Policy.";
export const LABEL_ALREADY_HAVE_AN_ACCOUNT = "Already have an account?";
export const LABEL_LOGIN = "Login";
export const LABEL_ENTER_EMAIL_PASS = "Please enter your email and password.";
export const LABEL_FORGOT_PASSWORD = "Forgot password?";
export const LABEL_CREATE_ONE = "Create one";
export const LABEL_DONT_HAVE_ACCOUNT = "Don't have an account?";
export const LABEL_MY_ACCOUNT = "My Account";
export const LABEL_LOGOUT = "Logout";
export const LABEL_CANCEL = "Cancel";
export const LABEL_LOGOUT_TEXT = "Are you sure you want to log out now..?";
export const LABEL_CONTACT_US = "Contact Us";

export const KEY_USER_TOKEN = "token";
export const KEY_USER = "user";

export const UN_AUTHORIZED_TEXT = "graphql-authorization";
export const USER_REGISTER_SUCCESS = "User registered successfully";
export const USER_REGISTER_FAIL = "User registration fails";

export const SUCCESS = "success";
export const ERROR = "error";
export const WARNING = "warning";
export const INFO = "info";
export const DEFAULT = "default";
