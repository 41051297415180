import { useState } from "react";
import Slider from "react-slick";
import { IImage } from "../../Interfaces/Common.interface";

const ProductImageSlider = (props: { media_gallery: IImage[] }) => {
  const [nav1, setNav1] = useState<any>();
  const [nav2, setNav2] = useState<any>();

  const mainImgSlider = {
    swipeToSlide: false,
    asNavFor: nav2,
    arrows: false,
    slidesToScroll: 1,
    ref: (slider1: any) => setNav1(slider1),
  };

  const thumbImgSlider = {
    asNavFor: nav1,
    ref: (slider2: any) => setNav2(slider2),
    slidesToShow: 5,
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3.3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4.3,
        },
      },
    ],
  };

  return (
    <>
      <Slider className="preview-slider" {...mainImgSlider}>
        {props.media_gallery
          ? props.media_gallery.map((media_g: IImage) => {
              return (
                <img
                  key={media_g.label}
                  src={media_g.url}
                  alt={media_g.label}
                />
              );
            })
          : null}
      </Slider>
      <Slider className="thumbnail-slider" {...thumbImgSlider}>
        {props.media_gallery
          ? props.media_gallery.map((media_g: IImage) => {
              return (
                <div key={media_g.label} className="point">
                  <img
                    key={media_g.label}
                    src={media_g.url}
                    alt={media_g.label}
                  />
                </div>
              );
            })
          : null}
      </Slider>
    </>
  );
};

export default ProductImageSlider;
