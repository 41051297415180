/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MY_DETAIL } from "../../../Constants/Route";
import {
  getUserDetail,
  updateNewsLetter,
} from "../../../Redux/Auth/AuthAction";
import CheckboxGroups from "../../Common/CheckboxGroups/CheckboxGroups";
import CustomButton from "../../Common/CustomButton/CustomButton";
import Sidebar from "../Sidebar/Sidebar";

const Newsletter = () => {
  const dispatch = useDispatch();

  const [isSubcribed, setSubcribed] = useState(false);

  const userData = useSelector((state: any) => state?.userResponse);

  const onSubmit = () => {
    dispatch(updateNewsLetter(isSubcribed, false));
  };

  useEffect(() => {
    if (userData && userData.data) {
      setSubcribed(userData.data.is_subscribed);
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  return (
    <div className="account-container">
      <div className="ll hide-991">
        <Link to={MY_DETAIL} className="acc-backbtn ll">
          My Account
        </Link>
      </div>
      <div className="account-wrap">
        <Sidebar />
        <div className="acc-rightwrap">
          <div className="newsletter-wrap">
            <h6 className="caps-on">Newsletter Subscription</h6>
            <div className="news-sub">
              <p className="box-title tm">Subscription option</p>
              <CheckboxGroups
                defaultChecked={isSubcribed}
                value={isSubcribed}
                onChange={(e: any) => setSubcribed(e.target.checked)}
                id="news-box"
              >
                General Subscription
              </CheckboxGroups>
            </div>
            <div className="news-action">
              <CustomButton onClick={onSubmit} bg={"fill"}>
                Save
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
