import { GET_PRODUCT_CMS_BLOCK_SUCCESS, GET_PRODUCT_DETAIL_FAILED, GET_PRODUCT_DETAIL_REQUEST, GET_PRODUCT_DETAIL_SUCCESS, GET_PRODUCT_FABRIC_INFORMATION_SUCCESS, GET_PRODUCT_LIST_FAILED, GET_PRODUCT_LIST_REQUEST, GET_PRODUCT_LIST_SUCCESS } from "./ProductTypes";

export function productList(state = {}, action: any) {
    switch (action.type) {
        case GET_PRODUCT_LIST_REQUEST:
            return { ...action.payload };
        case GET_PRODUCT_LIST_SUCCESS:
            return { ...action.payload };
        case GET_PRODUCT_LIST_FAILED:
            return { ...action.payload };
        default:
            return state;
    }
}

export function productDetailResponse(state = {}, action: any) {
    switch (action.type) {
        case GET_PRODUCT_DETAIL_REQUEST:
            return { ...action.payload };
        case GET_PRODUCT_DETAIL_SUCCESS:
            return { ...action.payload };
        case GET_PRODUCT_DETAIL_FAILED:
            return { ...action.payload };
        case GET_PRODUCT_CMS_BLOCK_SUCCESS:
            return { ...action.payload }
        case GET_PRODUCT_FABRIC_INFORMATION_SUCCESS:
            return { ...action.payload }
        default:
            return state;
    }
}