import { Modal } from "react-bootstrap";
import Guardsman from '../../../Assets/Images/Guardsman.jpg';

const GuardsmanPopup = (props: any) => {

  return (
    <Modal {...props} centered className="guardsam-popup">
      <Modal.Header closeButton>
        <Modal.Title className="tm">Guardsman Bed Protection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={Guardsman} alt=""/>
        <p className="bm">Protect your furniture with or Guardsman UK fabric Protection.</p>
        <ul>
          <li className="bm">Shields fabric and upholstery against soils, spills and stains</li>
          <li className="bm"> Won't change the fabric's look or feel</li>
          <li className="bm">Completely Odourless</li>
          <li className="bm">Maintains the fabrics breathable properties</li>
          <li className="bm">Formulated to meet the demands of furniture professionals</li>
        </ul>
        <p className="bm">£60.00</p>
      </Modal.Body>
    </Modal>
  )
}

export default GuardsmanPopup;