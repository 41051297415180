const PanelCMSBlock = (props: { cms_block: string }) => {
  return (
    <div
      className="product-left-txt show-992"
      dangerouslySetInnerHTML={{ __html: props.cms_block }}
    >
      {/* <ul>
        <li className="ll">Handmade for you</li>
        <li className="ll">Bespoke Build Available</li>
        <li className="ll">No Fuss Returns</li>
        <li className="ll">Free Setup</li>
        <li className="ll">Specially Selected Materials</li>
      </ul> */}
    </div>
  );
};

export default PanelCMSBlock;
